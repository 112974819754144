import React, {Component, Dropdown, DropdownButton, Fragment} from 'react';

import sectiondata from '../../data/sections.json';
import AgentFriend from "../agent-frient-list/Agent_Firend";
import {Link} from "react-router-dom"
import AgentProfileDropdownMenu from "../global-components/Agent-Profile/agent-profile-dropdown-menu";
import connect from "react-redux/es/connect/connect";
import {actionGetAgents, actionGetAgentFriendList} from "../../actions/agentAction";
import AgentCard from "../agent-list/AgentCard";
import DataNotFound from "./DataNotFound";
import Spinner from "./Spinner";
import {AGENT_FRIEND_LIST} from "../../actions/types";
import isEmpty from "../../utils/is-empty";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import AgentListLoaderCard from "../agent-list/agent-list-loader-card";


class AgentFriendList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            q: '',
            offset:0,
            errors :{}

        };
    }

    componentDidMount() {
        // if (this.props.user.user_id)
        //     this.setState({offset:0});
        //     this.props.actionGetAgentFriendList(this.props.match.params.userId,`offset=${this.state.offset}`);


        window.addEventListener('scroll', () => {
            let scrollHeight = document.documentElement.scrollHeight;
            let scrollTop = document.documentElement.scrollTop;
            let clientHeight = document.documentElement.clientHeight;

            if ((scrollTop + clientHeight) > (scrollHeight - 300) && !this.props.agent.agentLoader && this.props.agent.agents.length !== parseInt(this.props.agent.agentsCount?this.props.agent.agentsCount:0)) {
                this.setState({offset: this.state.offset + 1}, () => this.props.actionGetAgentFriendList(this.props.match.params.userId, `offset=${this.state.offset}`));
            }
        });
        this.setState({offset: 0});
        this.props.actionGetAgentFriendList(this.props.match.params.userId, `offset=${this.state.offset}`);
    }

    componentDidUpdate(prevProps, prevState) {

        prevProps.user.user_id !== this.props.user.user_id && this.props.actionGetAgentFriendList(this.props.match.params.userId,`offset=${0}`);
    }
    onChange = e =>this.setState({[e.target.name] : e.target.value},()=>{
        if (!this.state.q.trim()){
            this.setState({offset:0});
            this.props.actionGetAgentFriendList(this.props.match.params.userId,`offset=${this.state.offset}`);
        }
    });

    fetchMoreData = () => this.setState({offset: this.state.offset+1}, () => this.props.actionGetAgentFriendList(this.props.match.params.userId, `offset=${this.state.offset}`));


    render() {
        const {agents} = this.props.agent;
        const {agentLoader,agentsCount} = this.props.agent;
        const  {errors} =this.state;

        let filteredAgents = !this.state.q ? agents : agents.filter(a => {
            return a.first_name.toLowerCase().indexOf(this.state.q.toLowerCase()) > -1 || a.last_name.toLowerCase().indexOf(this.state.q.toLowerCase()) > -1 || a.email.toLowerCase().indexOf(this.state.q.toLowerCase()) > -1;
        });

console.log(filteredAgents)

        return (
            <Fragment>

                <div className="user-list-area pd-bottom-70 pd-top-60">
                    <div className="container">

                        {/*<Link to="/agent-profile">Profile</Link>*/}


                                <form autoComplete="off" onSubmit={this.handleSearchSubmit}>
                                    <div className="form-row pd-bottom-70">
                                        <div className="col-8 com-sm-8">
                                            <div className="widget-sidebar-item-wrap rld-single-input left-icon ">
                                                <input type="text" className="form-control rld-single-input left-icon" id="q" name={'q'}
                                                       value={this.state.q} onChange={this.onChange}
                                                       placeholder="Search By  Agent Name or Agent Email"/>
                                            </div>
                                        </div>
                                        <div className="col-4 responsive-btn1">
                                            <button type="submit" className="btn btn-yellow"> Search</button>
                                        </div>
                                        <div className="col-4 responsive-btn" style={{display:'none'}}>
                                            <button type="submit" className="btn btn-yellow"><i className="fa fa-search"
                                                                                                aria-hidden="true"/></button>
                                        </div>

                                    </div>
                                </form>


                            <div className="text-right mb-2">
                                <h6 className="filter-title mt-3 mb-lg-0">{parseInt(agentsCount?agentsCount:0)} Agents</h6>
                            </div>



                        <div className="row">

                            {(filteredAgents && filteredAgents.length > 0) && filteredAgents.map((item, i) => <AgentCard
                                key={i}
                                index={i}
                                page={"AGENT_FRIEND"}
                                userId={item.user_id}
                                address={item.street_address}
                                imagealt={`${item.first_name} ${item.last_name}`}
                                mobile={item.mobile}
                                email={item.email}
                                name={`${item.first_name} ${item.last_name}`}
                                profilePic={item.profile_pic}
                                user_relation={item.user_relation}
                                user_relation_object={item.user_relation_object}
                                url={`/agent-profile/${item.user_id}`}/>)}

                            {(!agentLoader && filteredAgents.length < 1) &&
                            <div className="text-center col-md-12"><DataNotFound
                                message={`Sorry! No any friend found.`}/></div>}
                            {/*{agentLoader && <div className="text-center col-md-12"><Spinner width={150}/></div>}*/}

                            {(agentLoader) && <div className="row">
                                <AgentListLoaderCard/>
                                <AgentListLoaderCard/>
                                <AgentListLoaderCard/>
                                <AgentListLoaderCard/>
                                <AgentListLoaderCard/>
                                <AgentListLoaderCard/>
                            </div>}

                        </div>

                    </div>

                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        agent: state.agent,
    };
};
export default connect(mapStateToProps, {actionGetAgents, actionGetAgentFriendList})(AgentFriendList);