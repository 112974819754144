import React, {Component, Fragment} from 'react';
import Navbar from "../global-components/navbar";
import {BLOG} from "../../actions/types";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import {Link} from "react-router-dom";
import logo from "../../assets/img/company-logo-placeholder.png"
import connect from "react-redux/es/connect/connect";
import {actionGetBlog} from "../../actions/agentAction";
import ReactGA from "react-ga";

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogData: [],
        }

    }

    componentDidMount() {
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Blog | Global Agent Network";


        this.props.actionGetBlog();

    }

    componentDidUpdate(prevProps, prevState) {
        prevProps.blogData !== this.props.blogData && this.setState({blogData: this.props.blogData});
    }

    render() {
        console.log(this.props.blogData, '..');
        window.scrollTo(0, 0);
        return (
            <Fragment>
                <Navbar scenario={BLOG}/>
                <PageHeader headertitle="Blog"/>
                <div className="container">
                    <div className="row">
                        {
                            this.props.blogData&&this.props.blogData.map((item, i) => (
                                <div className="col-sm-12 col-md-4" style={{marginBottom: "5%", marginTop: "5%"}}>
                                    <div className="iq-blog-box">
                                        <div className="iq-blog-image clearfix">
                                            <img className="img-responsive center-block" src={item.image} alt="#"/>
                                        </div>
                                        <div className="iq-blog-detail white-bg">
                                            <div className="blog-title">
                                                    <h5 className="iq-tw-6">{item.title}</h5>
                                            </div>
                                            <div className="iq-blog-meta mt-2">
                                                <ul className="list-inline">
                                                    <li>
                                                            <i className="fa fa-calendar mr-2" aria-hidden="true"/>
                                                            {item.date}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="blog-content mt-2">
                                                <div
                                                    className="post__content"
                                                    dangerouslySetInnerHTML={{__html: item.description_short}}
                                                />

                                            </div>
                                            <hr/>
                                            <div className="blog-button mt-2">
                                                    <i className="fa fa-user-circle mr-2" aria-hidden="true"/>
                                                    {item.writer_name}
                                                <Link to={'blog/'+item.blog_id} className="pull-right iq-tw-6">
                                                    Read More <i className="fa fa-angle-right" aria-hidden="true"/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Footer/>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        blogData: state.agent.blogData,
        blogLoader: state.agent.blogLoader,
    };
};
export default connect(mapStateToProps, {actionGetBlog})(Blog);