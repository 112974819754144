import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Home from './components/home/Home';

// import About from './components/section-components/about-us';
import Routes from './components/routing/Routes';
import { AUTH_SUCCESS, LOGOUT } from './actions/types';

// Redux
import { Provider } from 'react-redux';
import store from './store';
//import {loadUser} from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';
import AgentList from "./components/agent-list/agent-list";
import Auth from "./components/auth/Auth";
import AgentProfile from "./components/agent-profile/AgentProfile";
import About from './components/about-us/about';
import Contact from './components/contact-us/contact';
import Privacy from './components/privacy/privacy';
import Terms from './components/terms/terms';
import PropertyDetail from './components/property-details/property-detail';
import PropertyListing from "./components/property-listing/property-list";
import AgentFriendList from "./components/agent-frient-list/agent-friend";
import JawaharDodani from "./components/section-components/Jawahar-Dodani";
import jwt_decode from 'jwt-decode';
import { BASE_URL } from "./web-config";
import axios from 'axios';
import PrivateChat from "./components/private-chat/private_chat";
import Messages from "./components/private-chat/Messages";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AgentProperty from "./components/agent-property-list/agent-property";
import UserProfile from "./components/user-profile/UserProfile";
import ResidentialProperty from "./components/add-property/Add-Property";
import MyProperty from "./components/my-property-list/my-property";
import ForgotPassword from "./components/auth/ForgotPassword";
import PropertyImages from "./components/property-details/property-images";
import ChangePassword from './components/auth/change-password';
import PrivateRoute from "./components/common/PrivateRoute";
import * as actionTypes from "./actions/types";
import PageNotFound404 from "./components/404/PageNotFound404";
import MobileVerification from "./components/auth/mobile-verification";
import FavoriteProperty from "./components/favorite-property/favorite-property";
import Testimonials from "./components/Testimonials/testimonials";
import Blog from "./components/Blog/Blog";
import BlogDetail from "./components/Blog/blog-detail";
import ReactGA from "react-ga";
import AppBanner from "smart-app-banner-react";
import logo from './assets/img/logo11.png'
import classnames from "classnames";
import swal from 'sweetalert';
import contactCopy from './components/contact-us/contactCopy';
import Popup1 from './assets/img/popup1.jpg';



if (localStorage.jwtToken) {
    // Set auth token header auth
    setAuthToken(localStorage.jwtToken);




    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);

    // Load User data
    try {
        store.dispatch({ type: actionTypes.GET_USER_DATA_LOADER, payload: true });
        axios.post(`${BASE_URL}/get-user-data`, JSON.stringify({ user_id: parseInt(decoded.uid) })).then((result) => {
            store.dispatch({ type: actionTypes.GET_USER_DATA_LOADER, payload: false });
            if (parseInt(result.data.status) === 200) {
                store.dispatch({ type: AUTH_SUCCESS, payload: result.data.data });
            } else {

                //store.dispatch(setAlert(result.data.message, 'error'));
            }

        });
    } catch (error) {
        store.dispatch({ type: actionTypes.GET_USER_DATA_LOADER, payload: false });
        console.error({ "error getting user-profile data": error });
        // store.dispatch(setAlert(error.message, 'error'));
    }

    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        // Logout user
        // store.dispatch(logoutUser());
        // Redirect to login
        //window.location.href = '/auth';
    }
}


const App = () => {
    const [modal, setModal] = useState(false);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Home | Global Agent Network";
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview('/');

        
        


        // navigator.geolocation.getCurrentPosition(function(position) {
        //     console.log("Latitude is :", position.coords.latitude);
        //     console.log("Longitude is :", position.coords.longitude);
        // });
        // getMyLocation();

        if(window.location.pathname == '/'){
            setTimeout(setModal(true), 1000)
            
        }
        

        // check for token in LS
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }
        //store.dispatch(loadUser());

        // log user out from all tabs if they log out in one tab
        window.addEventListener('storage', () => {
            if (!localStorage.token) store.dispatch({ type: LOGOUT });
        });
    }, []);


    // const getMyLocation=()=> {
    //     const location = window.navigator && window.navigator.geolocation
    //     if (location) {
    //         location.getCurrentPosition((position) => {
    //
    //             this.setState({
    //                 latitude: position.coords.latitude,
    //                 longitude: position.coords.longitude,
    //             })
    //         }, (error) => {
    //
    //             this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
    //         })
    //     }
    //
    // }


    const appInfo = {
        imageUrl: logo,
        name: "Global India Real Estate",
        publisher: "Global India Real Estate - Apps on Google Play",
        infoText: "Network with Indian Agents & Share Properties Across the Globe",
        linkButtonText: "Get",

        linkUrl:
            "https://play.google.com/store/apps/details?id=com.agentnet"
    };
    const appInfo1 = {
        imageUrl: logo,
        name: "Global India Real Estate",
        publisher: "Global India Real Estate - Apps on App Store",
        infoText: "Network with Indian Agents & Share Properties Across the Globe",
        linkButtonText: "Get",

        linkUrl:
            "https://apps.apple.com/us/app/global-india-real-estate/id1587126493"
    };

    function getPlatform() {
        var platform = ["Win32", "Android", "iOS"];

        for (var i = 0; i < platform.length; i++) {

            if (navigator.platform.indexOf(platform[i]) > - 1) {

                return platform[i];

            }
        }
    }


    // if(latitude && longitude){
    //
    // }
    // else {
    //     swal({
    //         title: 'Location access allowed',
    //         text: "allowing this site to access your location!",
    //         icon: "warning",
    //         buttons: [false,'OK'],
    //         dangerMode: true,
    //     })
    //         .then((willDelete) => {
    //             if (willDelete) {
    //                 swal("Poof! Your property  has been deleted!", {
    //                     icon: "success",
    //
    //                 });
    //             } else {
    //                 // swal("Your property is safe!");
    //             }
    //         });
    // }


    return (
        <Provider store={store}>

            <div id="popup1" className={classnames('overlay', {
                'd-none': !modal
            })}    >
                <div className="popup">
                    {/* <h4>Now we are also available At</h4>
                    <button className="close" onClick={()=>setModal(!modal)}>&times;</button>
                    <div className="content pt-2">
                        <div className="text-center1">
                        <div className="pb-2">
                        <a href="https://apps.apple.com/us/app/global-india-real-estate/id1587126493" target="_blank"
                        className="market-btn apple-btn r-1" role="button">
                        <span className="market-button-subtitle">Download on the</span>
                        <span className="market-button-title">App Store</span>
                        </a>


                        <a href="https://play.google.com/store/apps/details?id=com.agentnet" target="_blank"
                        className="market-btn google-btn ml-2" role="button">
                        <span className="market-button-subtitle">Download on the</span>
                        <span className="market-button-title">Google Play</span>
                        </a>

                        </div>
                        </div>
                    </div> */}
                    {/* <button className="close" onClick={()=>setModal(!modal)}>&times;</button> */}
                
                    <img src={Popup1} alt='popup' />
                    <div className='d-flex justify-content-center align-items-center'>
                    <button onClick={() => window.location = '/event-registration'} className='p-2 px-3 mr-3'style={{marginTop:'15px', border:'1px solid #fd7e14' ,borderRadius: '25px' ,color:'#ffffff',backgroundColor:'#fd7e14' ,}} >Register Now</button>                    
                    <button onClick={()=>setModal(!modal)} className='p-2 px-3'style={{marginTop:'15px', border:'1px solid #fd7e14' ,borderRadius: '25px' ,color:'#fd7e14',backgroundColor:'white', cursor:'pointer'}} >Close</button>                                        
                    </div>

                </div>
            </div>


            {/*<div  className={classnames('custom-banner',{*/}
            {/*'d-none': !modal*/}
            {/*})} >*/}
            {/*<div className="container">*/}
            {/*<div className="container-dd">*/}
            {/*<div className="banner  ">*/}
            {/*<span className="float-right  border-0" onClick={()=>setModal(!modal)} ><i className="fa fa-close"/></span>*/}
            {/*<div className="text-center ">*/}
            {/*<h4 className="py-2">Now we are also available  At</h4>*/}
            {/*<div className="pb-2">*/}
            {/*<a href="https://apps.apple.com/us/app/global-india-real-estate/id1587126493" target="_blank"*/}
            {/*className="market-btn apple-btn r-1" role="button">*/}
            {/*<span className="market-button-subtitle">Download on the</span>*/}
            {/*<span className="market-button-title">App Store</span>*/}
            {/*</a>*/}


            {/*<a href="https://play.google.com/store/apps/details?id=com.agentnet" target="_blank"*/}
            {/*className="market-btn google-btn ml-2" role="button">*/}
            {/*<span className="market-button-subtitle">Download on the</span>*/}
            {/*<span className="market-button-title">Google Play</span>*/}
            {/*</a>*/}

            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}



            <ToastContainer
                position="bottom-right"
            />



            <Router>
                <Fragment>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/blog" component={Blog} />
                        <Route exact path="/blog/:blogId" component={BlogDetail} />
                        <Route exact path="/agent-list" component={AgentList} />
                        <Route exact path="/register" component={Auth} />
                        <Route exact path="/change-mobile-number" component={Auth} />
                        <Route exact path="/mobile-verification" component={MobileVerification} />
                        <Route exact path="/change-mobile-verification" component={MobileVerification} />
                        <Route exact path="/forgot-password" component={ForgotPassword} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/event-registration" component={contactCopy} />
                        <Route exact path="/property-listing" component={PropertyListing} />
                        <Route path="/property-listing/:location/:query" component={PropertyListing} />
                        <Route exact path="/property-detail/:property_id/:query" component={PropertyDetail} />
                        <PrivateRoute path="/agent-profile/:userId" component={AgentProfile} />
                        <Route exact path="/agent-friend-list/:userId" component={AgentFriendList} />
                        <Route exact path="/agent-property-list/:userId" component={AgentProperty} />

                        <Route exact path="/jawahar-dodani" component={JawaharDodani} />
                        <Route exact path="/privacy-policy" component={Privacy} />
                        <Route exact path="/terms-of-service" component={Terms} />
                        <PrivateRoute exact path="/change-password/:user_id" component={ChangePassword} />
                        <PrivateRoute exact path="/property-images/:propertyId" component={PropertyImages} />


                        {/*PRIVATE ROUTES*/}
                        <PrivateRoute exact path="/add-property" component={ResidentialProperty} />
                        <PrivateRoute exact path="/update-property/:propertyId" component={ResidentialProperty} />
                        <PrivateRoute exact path="/my-properties" component={MyProperty} />
                        <PrivateRoute exact path="/favourite-properties" component={FavoriteProperty} />
                        <PrivateRoute exact path="/testimonials" component={Testimonials} />
                        <PrivateRoute exact path="/private-chat/:userId" component={PrivateChat} />
                        <PrivateRoute exact path="/messages" component={Messages} />
                        <PrivateRoute path="/profile/:userId" component={UserProfile} />
                        <Route component={PageNotFound404} />

                    </Switch>
                </Fragment>
            </Router>
        </Provider>
    );
};

export default App;