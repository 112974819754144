import React, {Component, Fragment} from 'react';
import connect from "react-redux/es/connect/connect";
import {actionChangePassword, actionLogin} from "../../actions/authAction";
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import RegistrationSection from "../section-components/registration";
import Footer from "../global-components/footer";
import btnLoader from "../../assets/img/button-loader.svg";
import {CHANGE_PASSWORD} from "../../actions/types";
import ReactGA from "react-ga";

class ChangePasswordCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            new_password: '',
            errors: ''
        };
    }
    componentDidMount() {
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Change Password | Global Agent Network";
        // ReactGA.initialize('G-2XQB9WGPED');
        // ReactGA.pageview(`/change-password/${this.props.match.params.user_id&&this.props.match.params.user_id}`);

    }

    onChange = e => this.setState({[e.target.name]: e.target.value});
    handleChangePasswordValidation = () => {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;

        if (!fields.password) {
            formIsValid = false;
            errors.password = " Old Password must be Required!";
        }
        if (!fields.new_password) {
            formIsValid = false;
            errors.new_password = "New Password must be Required!";
        } else if (fields.new_password.length < 8) {
            formIsValid = false;
            errors.new_password = "New Password must be 8 character Long!";
        }

        this.setState({errors: errors});
        return formIsValid;
    };

    onSubmitChangePassword = (e) => {
        e.preventDefault();
        if (this.handleChangePasswordValidation()) {
            const {password, new_password} = this.state;
            const user_id = this.props.match.params.user_id;
            const userData = {
                user_id,
                password,
                new_password
            };
            this.props.actionChangePassword(userData);
            this.setState({ password: '',
                new_password: '',});
        }
    };

    render() {
        console.log(this.props.match.params.user_id);
        const {changePasswordLoader} = this.props.auth;
        const {errors} = this.state;
        return (
            <Fragment>
                <Navbar scenario={CHANGE_PASSWORD}/>
                <PageHeader headertitle={'Change Password'}/>
                <div className="register-page-area pd-bottom-50">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-5 col-md-6 register-container">
                                <form className="contact-form-wrap contact-form-bg" autoComplete="off" noValidate={true}
                                      onSubmit={this.onSubmitChangePassword}>
                                    {/*<h4>Change Password</h4>*/}
                                    <div id="user">
                                        <div className="rld-single-input">
                                            <input
                                                type="password"
                                                name="password"
                                                id="password"
                                                className="rdl-mob"
                                                value={this.state.password}
                                                onChange={this.onChange}
                                                placeholder="Enter Your Old Password"/>
                                            {errors.password && <span className='text-danger'>{errors.password}</span>}
                                        </div>
                                        <div className="rld-single-input">
                                            <input
                                                type="password"
                                                name="new_password"
                                                id="new_password"
                                                className="rdl-mob"
                                                value={this.state.new_password}
                                                onChange={this.onChange}
                                                placeholder="Enter Your New Password"/>
                                            {errors.new_password &&
                                            <span className='text-danger'>{errors.new_password}</span>}
                                        </div>
                                    </div>
                                    <div className="btn-wrap text-center">
                                        {changePasswordLoader ?
                                            <button
                                                className="btn btn-yellow"
                                                type="button">
                                                <img src={btnLoader} alt="shapemore-login-loader"
                                                     width={15}/>
                                            </button> :
                                            <button
                                                className="btn btn-yellow"
                                                type="submit">Change Password
                                            </button>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps, {actionChangePassword})(ChangePasswordCard);


