import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class TermsConditions extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'
        let data = sectiondata.aboutus


        return <div className="about-area pd-top-60 pd-bottom-60">
            <div className="container">
                <div className="row">

                    <div className="col-lg-12 align-self-center">
                        <div className="section-title pd-left mb-0">
                            <h2>Terms of Service</h2>
                            <p>By clicking "Sign Up", you agree to Global India Real Estate Portal “Terms of Use” and
                                “Privacy Policy”. You consent to receive phone calls and SMS messages from Global India
                                Real Estate Portal to provide updates on Property Listing and/or for marketing purposes.
                                Message frequency depends on your activity. You may opt-out by texting "STOP" to 86753.
                                Message and data rates may apply.
                                Security</p>
                            <p>We are committed to ensuring that your information is secure. In order to prevent
                                unauthorized access or disclosure, we have put in place suitable physical, electronic
                                and managerial procedures to safeguard and secure the information we collect online.
                                Cookies
                                We use cookies on this website.</p>

                            <p>A cookie is a small file which is placed on your computer’s hard drive with your implied
                                consent. Cookies allow web applications to remember you and personalize your experience.
                                The website can tailor its operations to your needs, likes and dislikes by gathering and
                                remembering information about your preferences.
                                Google Analytics & Conversion Tracking
                                We use traffic log cookies from Google Analytics to identify which pages are being used
                                and are an essential part of monitoring and improving our electronic communications.
                                This helps us analyze data about web page traffic and improve our website in order to
                                tailor it to customer needs. We use this non-identifiable aggregated traffic information
                                for statistical analysis purposes.
                                We also use conversion tracking to understand customer behavior.
                                Session Cookies</p>
                            <p>This site uses essential session cookies for the operation of the website.
                                Google Adwords Cookies</p>
                            <p>We may use cookies in Google Adwords for remarketing purposes. This means we remember
                                your visit to our website to provide you with advertising elsewhere on the Google
                                Display Network.</p>
                            <div className="font-weight-bold">
                                Other Applications
                            </div>
                            <p>If we embed YouTube videos in the site, watching the video will set a cookie on your
                                computer.</p>
                            <p>We may also use embedded applications such as Twitter, Facebook. These third parties may
                                set cookies to remember your preferences.
                                How to Disable Cookies</p>
                            <p>
                                You can choose to accept or decline cookies. Most web browsers automatically accept
                                cookies, but you can usually modify your browser setting to decline cookies if you
                                prefer. This may prevent you from taking full advantage of the website.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            }
            }

            export default TermsConditions;