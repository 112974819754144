import React, {Component} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import ReactGA from "react-ga";

class JawaharDodani extends Component {
    constructor(props){
        super(props)
        window.scrollTo(0, 0);

    }
    componentDidMount() {
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Jawahar Dodani | Global Agent Network";


    }
    render() {
        return (
            <div>
                <Navbar/>
                <PageHeader headertitle="Jawahar Dodani"/>
                <div className="jawahar-dodani-area pd-top-100 pd-bottom-65">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12- col-lg-12">
                                <div className="vedio-card">
                                    <iframe width="650" height="415" src="https://www.youtube.com/embed/qrbT14sHFL4"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen/>
                                </div>
                            </div>
                            <div className="col-md-12- col-lg-12">
                                <div className="content">
                                    <p>
                                        Hand selected, local experienced agents, and neighborhood specialists, work with you to achieve your goals. The highest level of market knowledge, transparency, technology utilization, and transaction experience.
                                        Hand selected, local experienced agents, and neighborhood specialists, work with you to achieve your goals. The highest level of market knowledge, transparency, technology utilization, and transaction experience.
                                        Hand selected, local experienced agents, and neighborhood specialists, work with you to achieve your goals. The highest level of market knowledge, transparency, technology utilization, and transaction experience.
                                        Hand selected, local experienced agents, and neighborhood specialists, work with you to achieve your goals. The highest level of market knowledge, transparency, technology utilization, and transaction experience.
                                        Hand selected, local experienced agents, and neighborhood specialists, work with you to achieve your goals. The highest level of market knowledge, transparency, technology utilization, and transaction experience.
                                        Hand selected, local experienced agents, and neighborhood specialists, work with you to achieve your goals. The highest level of market knowledge, transparency, technology utilization, and transaction experience.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default JawaharDodani;