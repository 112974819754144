import * as actionTypes from '../actions/types';
import isEmpty from "../utils/is-empty";

const initialState = {
    isAuthenticated: false,
    registerLoading: false,
    loginLoading: false,
    otpLoading: false,
    forgotPasswordLoader: false,
    changePasswordLoader: false,
    user: '',
    isUserLoaded: false,
    loginErrors: {},
    countries: [],
    country_loader: false,
    state_loader: false,
    city_loader: false,
    states: [],
    cities: [],
    errors: {},
    updateProfileLoader: false,
    getUserDataLoader: false,
    partners_loader: false,
    partners: [],
    teams_loader: false,
    teams: [],
    get_suggested_location_loader: false,
    suggestedLocations: [],
    contactFromLoading: false,
    subscribeFromLoading: false,
    temp_mobile: '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        //Otp verification
        case actionTypes.OTP_LOADING:
            return {
                ...state,
                otpLoading: action.payload
            };

        case actionTypes.REGISTER_LOADING:
            return {
                ...state,
                registerLoading: action.payload
            };
        case actionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: !isEmpty(action.payload)
            };
        case actionTypes.TEMP_MOBILE_REDUX:
            return {
                ...state,
                temp_mobile: action.payload,

            };
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: !isEmpty(action.payload)
            };
        case actionTypes.REGISTER_FAILED:
            return {
                ...state,
                errors: action.payload
            };
        case actionTypes.LOGIN_FAILED:
            return {
                ...state,
                loginErrors: action.payload
            };
        case actionTypes.REMOVE_NOTIFICATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    notifications: state.user.notifications.filter(n => parseInt(n.notification_id) !== parseInt(action.payload))
                }
            };
        case actionTypes.MAKE_REQUESTED:
            return {
                ...state,
                user: {
                    ...state.user,
                    notifications: (state.user.notifications && state.user.notifications.length > 0) && state.user.notifications.map(n => parseInt(n.is_read) === 0 ? {
                        ...n,
                        is_read: 1
                    } : n)
                }
            };

        case actionTypes.CHANGE_PROFILE_PICTURE:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile_pic: action.payload
                }
            };
        case actionTypes.CHANGE_COMPANY_PROFILE_PICTURE:
            return {
                ...state,
                user: {
                    ...state.user,
                    company_logo: action.payload
                }
            };
        case actionTypes.GET_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            };
        case actionTypes.GET_COUNTRY_LOADER:
            return {
                ...state,
                country_loader: action.payload
            };


        case actionTypes.GET_STATES:
            return {
                ...state,
                states: action.payload
            };

        case actionTypes.GET_STATE_LOADER:
            return {
                ...state,
                state_loader: action.payload
            };


        case actionTypes.GET_CITIES:
            return {
                ...state,
                cities: action.payload
            };

        case actionTypes.GET_CITY_LOADER:
            return {
                ...state,
                city_loader: action.payload
            };
        case actionTypes.UPDATE_PROFILE_LOADING:
            return {
                ...state,
                updateProfileLoader: action.payload
            };
        case actionTypes.GET_USER_DATA_LOADER:
            return {
                ...state,
                getUserDataLoader: action.payload
            };

        case actionTypes.GET_PARTNERS:
            return {
                ...state,
                partners: action.payload
            };
        case actionTypes.GET_PARTNERS_LOADER:
            return {
                ...state,
                partners_loader: action.payload
            };
        case actionTypes.GET_SUGGESTED_LOCATION_LOADER:
            return {
                ...state,
                get_suggested_location_loader: action.payload
            };
        case actionTypes.GET_SUGGESTED_LOCATIONS:
            return {
                ...state,
                suggestedLocations: action.payload
            };


        // Login
        case actionTypes.LOGIN_LOADING:
            return {
                ...state,
                loginLoading: action.payload
            };

        //Forgot Password
        case actionTypes.FORGOT_PASSWORD_LOADING:
            return {
                ...state,
                forgotPasswordLoader: action.payload
            };

        //Change password
        case actionTypes.CHANGE_PASSWORD_LOADING:
            return {
                ...state,
                changePasswordLoader: action.payload
            };


        //ContactForm
        case actionTypes.CONTACT_FORM_LOADING:
            return {
                ...state,
                contactFromLoading: action.payload
            };
        case actionTypes.CONTACT_FORM_SUCCESS:
            return {
                ...state,
                contactFromLoading: action.payload
            };
        case actionTypes.CONTACT_FORM_FAILED:
            return {
                ...state,
                errors: action.payload
            };

        //dummy form contact 

        case actionTypes.SUBMIT_FORM_LOADING:
            return {
                ...state,
                submitFormLoading: action.payload
            };
        case actionTypes.SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                submitFormLoading: action.payload
            };
        case actionTypes.SUBMIT_FORM_FAILED:
            return {
                ...state,
                errors: action.payload
            };
        //get dynamic team
        case actionTypes.GET_TEAM_MEMBERS:
            return {
                ...state,
                teams: action.payload
            };
        case actionTypes.GET_TEAM_MEMBERS_LOADER:
            return {
                ...state,
                teams_loader: action.payload
            };


        //Subscribe Form
        case actionTypes.SUBSCRIBE_FORM_LOADING:
            return {
                ...state,
                subscribeFromLoading: action.payload
            };
        case actionTypes.SUBSCRIBE_FORM_SUCCESS:
            return {
                ...state,
                subscribeFromLoading: action.payload
            };
        case actionTypes.SUBSCRIBE_FORM_FAILED:
            return {
                ...state,
                errors: action.payload
            };

        default:
            return state;


    }
}