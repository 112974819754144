import axios from 'axios';
import * as actionTypes from '../actions/types';
import {BASE_URL} from "../web-config";
import showToast from "../utils/showToast";
import setAuthToken from "../utils/setAuthToken";

export const actionGetCategories = () => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_CATEGORIES_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-categories`);
        dispatch({type: actionTypes.GET_CATEGORIES_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_CATEGORIES, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_CATEGORIES, payload: []});
            showToast(result.data.message, 'error');
        }
    } catch (error) {
        dispatch({type: actionTypes.GET_CATEGORIES_LOADER, payload: false});
        showToast(error.message, 'error');
    }
};

export const actionGetSubCategories = (parent_category_id) => async (dispatch, getState) => {
    console.log(parent_category_id)
    dispatch({type: actionTypes.GET_SUB_CATEGORIES_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-sub-categories?parent_category_id=${parent_category_id}`);
        dispatch({type: actionTypes.GET_SUB_CATEGORIES_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_SUB_CATEGORIES, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_SUB_CATEGORIES, payload: []});
            showToast(result.data.message, 'error');
        }
    } catch (error) {
        dispatch({type: actionTypes.GET_SUB_CATEGORIES_LOADER, payload: false});
        showToast(error.message, 'error');
    }
};

export const actionGetUnits = () => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_UNIT_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-units`);
        dispatch({type: actionTypes.GET_UNIT_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_UNIT, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_UNIT, payload: []});
            showToast(result.data.message, 'error');
        }
    } catch (error) {
        dispatch({type: actionTypes.GET_UNIT_LOADER, payload: false});
        showToast(error.message, 'error');
    }
};

export const actionGetAmenities = () => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_AMENITIES_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-amenities`);
        dispatch({type: actionTypes.GET_AMENITIES_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_AMENITIES, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_AMENITIES, payload: []});
            showToast(result.data.message, 'error');
        }
    } catch (error) {
        dispatch({type: actionTypes.GET_AMENITIES_LOADER, payload: false});
        showToast(error.message, 'error');
    }
};

export const uploadPropertyImage = (propertyImgInfo, setPropertyImagePreview) => async dispatch => {
    try {
        const result = await axios.post(`${BASE_URL}/upload-property-image`, propertyImgInfo, {headers: {'Content-Type': 'multipart/form-data'}});
        if (result.data.status === 200) {
            setPropertyImagePreview && setPropertyImagePreview(result.data.data);
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

export const actionAddProperty = (propertyData, history) => async (dispatch, getState) => {
    dispatch({type: actionTypes.ADD_PROPERTY_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/add-property`, JSON.stringify(propertyData));
        dispatch({type: actionTypes.ADD_PROPERTY_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            showToast(result.data.message, 'success');
            dispatch({type: actionTypes.ADD_PROPERTY_SUCCESS, payload: result.data.data});
            history&&history.push(`/my-properties`)
            // if(propertyData.property_id ){
            //     history.push(`/my-properties`)
            // }else {
            //     history.push(`/properties`)
            // }

        } else
            dispatch({type: actionTypes.ADD_PROPERTY_FAILED, payload: result.data.data});
    } catch (error) {
        showToast(error.message, 'error');
    }
};

//Delete Agent Property
export const actionDeleteAgentProperty = (property_id, agent_id) => async (dispatch, getState) => {
    console.log(property_id);
    console.log(agent_id);
    dispatch({type: actionTypes.DELETE_AGENT_PROPERTY_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/delete-property`, JSON.stringify({property_id, agent_id}));
        dispatch({type: actionTypes.DELETE_AGENT_PROPERTY_LOADING, payload: false});

        if (parseInt(result.status) === 200) {
            console.log(result);
            console.log(property_id);
            dispatch({type: actionTypes.DELETE_AGENT_PROPERTY_SUCCESS, payload: parseInt(property_id)});
            showToast(result.message, 'success');
        } else {
            showToast(result.message, 'Failed');
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

export const actionGetPropertyByFilter = (q, offset) => async (dispatch, getState) => {
    console.log({offset});
    console.log(q.length);
    dispatch({type: actionTypes.GET_PROPERTY_LOADER, payload: true});

    try {
        (q && offset === '&offset=0') && dispatch({
            type: actionTypes.REMOVE_PROPERTY,
            payload: []
        });
        (q.length && offset === 'offset=0') && dispatch({
            type: actionTypes.REMOVE_PROPERTY,
            payload: []
        });
        (!q && offset === '&offset=0') && dispatch({
            type: actionTypes.REMOVE_PROPERTY,
            payload: []
        });
        let result = await axios.get(`${BASE_URL}/get-properties${q}${offset}&limit=${15}`);
        dispatch({type: actionTypes.GET_PROPERTY_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            (q.length && offset === 'offset=0') && dispatch({
                type: actionTypes.REMOVE_PROPERTY,
                payload: []
            });
            (!q && offset === '&offset=0') && dispatch({
                type: actionTypes.REMOVE_PROPERTY,
                payload: []
            });
            dispatch({type: actionTypes.GET_PROPERTY, payload: result.data.data});
        }
        /*else {
            dispatch({
                type: actionTypes.REMOVE_PROPERTY,
                payload: []
            })
        }*/
    } catch (error) {
        showToast(error.message, 'error');
    }
};

export const actionGetCountry = () => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_COUNTRY_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-countries`);
        dispatch({type: actionTypes.GET_COUNTRY_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_COUNTRY, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_COUNTRY, payload: []});
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

export const actionGetState = (q) => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_STATE_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-states?${q}`);
        dispatch({type: actionTypes.GET_STATE_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_STATE, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_STATE, payload: []});
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

export const actionRemoveStates = () => dispatch => {
    dispatch({type: actionTypes.GET_STATE, payload: []});
};

export const actionGetCity = (q) => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_CITY_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-cities?${q}`);
        dispatch({type: actionTypes.GET_CITY_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_CITY, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_CITY, payload: []});
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

export const actionRemoveCities = () => dispatch => {
    dispatch({type: actionTypes.GET_CITY, payload: []});
};


export const actionGetPropertyDetails = (q) => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_PROPERTY_DETAILS_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-property-detail?${q}`);
        dispatch({type: actionTypes.GET_PROPERTY_DETAILS_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_PROPERTY_DETAILS, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_PROPERTY_DETAILS, payload: []});
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

export const actionGetFeatureProperty = (q) => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_FEATURED_PROPERTY_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-home-featured-properties?is_featured=${q}`);
        dispatch({type: actionTypes.GET_FEATURED_PROPERTY_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_FEATURED_PROPERTY, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_FEATURED_PROPERTY, payload: []});
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

export const actionGetPremiumFeatureProperty = (q) => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_PREMIUM_FEATURED_PROPERTY_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-home-premium-featured-properties?is_premium_featured=${q}`);
        dispatch({type: actionTypes.GET_PREMIUM_FEATURED_PROPERTY_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_PREMIUM_FEATURED_PROPERTY, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_PREMIUM_FEATURED_PROPERTY, payload: []});
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

//Get Property Images
export const actionGetPropertyImages = (q) => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_PROPERTY_IMAGES_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/get-all-property-images?property_id=${q}`);
        dispatch({type: actionTypes.GET_PROPERTY_IMAGES_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_PROPERTY_IMAGES, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_PROPERTY_IMAGES, payload: []});
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

//Delete Property Image
export const actionDeletePropertyImage = (property_image_id) => async (dispatch, getState) => {
    dispatch({type: actionTypes.DELETE_PROPERTY_IMAGE_LOADER, payload: true});
    try {
        let result = await axios.get(`${BASE_URL}/delete-property-image?property_image_id=${property_image_id}`);
        dispatch({type: actionTypes.DELETE_PROPERTY_IMAGE_LOADER, payload: false});
        dispatch({type: actionTypes.DELETE_PROPERTY_IMAGE, payload: property_image_id});
        if (parseInt(result.data.status) === 200) {
            showToast(result.data.message, 'success');
        } else {
            showToast(result.data.message, 'Failed');
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

export const actionAddMultiplePropertyImage = (formData) => async dispatch => {
    try {
        dispatch({type: actionTypes.ADD_MULTIPLE_PROPERTY_IMAGE_LOADER, payload: true});
        const result = await axios.post(`${BASE_URL}/property-multiple-images`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
        dispatch({type: actionTypes.ADD_MULTIPLE_PROPERTY_IMAGE_LOADER, payload: false});
        console.log(result);
        if (parseInt(result.data.status) === 200) {
            showToast(result.data.message, 'success');
            dispatch({type: actionTypes.ADD_MULTIPLE_PROPERTY_IMAGE_SUCCESS, payload: result.data.data});
        }
    } catch (error) {
        dispatch({type: actionTypes.ADD_MULTIPLE_PROPERTY_IMAGE_LOADER, payload: false});
        console.error({"error updating user-profile data": error});
    }
};

//Add Favourite Property
export const actionAddFavourite = (property_id,user_id) => async (dispatch, getState) => {
    dispatch({type: actionTypes.ADD_FAVOURITE_PROPERTY_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/favourite`,JSON.stringify({user_id,property_id}));
        dispatch({type: actionTypes.ADD_FAVOURITE_PROPERTY_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.ADD_FAVOURITE_PROPERTY, payload:result.data.data.property_id});
            // showToast(result.data.message, 'success');
        } else {
            // showToast(result.data.message, 'error');
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};

//Remove Favourite Property
export const actionRemoveFavourite = (user_id,property_id) => async (dispatch, getState) => {
    dispatch({type: actionTypes.REMOVE_FAVOURITE_PROPERTY_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/favourite`,JSON.stringify({user_id,property_id}));
        dispatch({type: actionTypes.REMOVE_FAVOURITE_PROPERTY_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.REMOVE_FAVOURITE_PROPERTY, payload: result.data.data.property_id});
           // showToast(result.data.message, 'success');
        } else {
           // showToast(result.data.message, 'error');
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};


//Get Favorite Property
export const actionGetFavoriteProperty = (q) => async (dispatch, getState) => {
    dispatch({type: actionTypes.GET_FAVOURITE_PROPERTY_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/get-favourite`,JSON.stringify({q}));
        dispatch({type: actionTypes.GET_FAVOURITE_PROPERTY_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.GET_FAVOURITE_PROPERTY, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.GET_FAVOURITE_PROPERTY, payload: []});
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};


//Add Single Page Website
export const actionAddSinglePageWeb = (propertyData) => async (dispatch, getState) => {
    dispatch({type: actionTypes.ADD_SINGLE_PAGE_WEB_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/update-single-page-website`,JSON.stringify(propertyData));
        dispatch({type: actionTypes.ADD_SINGLE_PAGE_WEB_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.ADD_SINGLE_PAGE_WEB, payload:result.data.data});
            showToast(result.data.message, 'success');
        } else {
            showToast(result.data.message, 'error');
        }
    } catch (error) {
        showToast(error.message, 'error');
    }
};


//Action Property View
export const actionPropertyViewSend = (Data) => async (dispatch, getState) => {
    dispatch({type: actionTypes.PROPERTY_VIEW_SEND_LOADING, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/send-view-mail`,JSON.stringify(Data));
        dispatch({type: actionTypes.PROPERTY_VIEW_SEND_LOADING, payload: false});
        if (parseInt(result.data.status) === 200) {
            dispatch({type: actionTypes.PROPERTY_VIEW_SEND, payload: result.data.data});
        } else {
            dispatch({type: actionTypes.PROPERTY_VIEW_SEND, payload: []});
        }
    } catch (error) {
        // showToast(error.message, 'error');
    }
};



// ADD Multiple Images Of Property
/*export const actionAddMultiplePropertyImage = (propertyImageData, history) => async (dispatch, getState) => {
    dispatch({type: actionTypes.ADD_MULTIPLE_PROPERTY_IMAGE_LOADER, payload: true});
    try {
        let result = await axios.post(`${BASE_URL}/property-multiple-images`, JSON.stringify(propertyImageData));
        dispatch({type: actionTypes.ADD_MULTIPLE_PROPERTY_IMAGE_LOADER, payload: false});
        if (parseInt(result.data.status) === 200) {
            showToast(result.data.message, 'success');
            dispatch({type: actionTypes.ADD_MULTIPLE_PROPERTY_IMAGE_SUCCESS, payload: result.data.data});
        } else
            dispatch({type: actionTypes.ADD_MULTIPLE_PROPERTY_IMAGE_FAILED, payload: result.data.data});
    } catch (error) {
        showToast(error.message, 'error');
    }
};*/

