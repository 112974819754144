import React, {Component} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import AgentProfileDropdownMenu from "../global-components/Agent-Profile/agent-profile-dropdown-menu";
import Chat from "../section-components/chat";
import Footer from "../global-components/footer";
import ReactGA from "react-ga";

class PrivateChat extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
        if (!localStorage.jwtToken) {
            window.location ='/register';
            // location.replace("/register")
        }
    }
    componentDidMount() {
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);

        window.scrollTo(0, 0);
        document.title = "Chat | Global Agent Network";
        // ReactGA.initialize('G-2XQB9WGPED');
        // ReactGA.pageview(`/private-chat/${this.props.match.params.userId && this.props.match.params.userId}`);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <PageHeader headertitle="Private Chat"/>
                <AgentProfileDropdownMenu match={this.props.match}/>
                <Chat match={this.props.match} showSidebar={false}/>
                <Footer/>
            </div>
        );
    }
}

export default PrivateChat;