import React, {Component, Fragment} from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import {Link} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {actionGetSuggestedLocations} from "../../actions/authAction";

class AboutUs extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.aboutus


    return <div className="about-area pb-lg-5 pb-sm-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="shape-image-list-wrap">
                <div className="shape-image-list left-top">
                  <img className="shadow-img" src={publicUrl+data.image} alt={ imagealt } />
                </div>
              </div>
            </div>

            <div className="col-lg-6 align-self-center">
              <div className="section-title pd-left mb-0">
                {/*<h5 className="sub-title">{ data.subtitle }</h5>*/}
                <h2 className="title">{ data.title }</h2>
                <p>{ data.content }</p>
                  <p>{ data.contentt }</p>
                  {this.props.isAuthenticated ? "" :
                      <Fragment>
                      <p> To become a member, please sign up here.</p>
                      <Link className="btn btn-yellow" to={"/register"} >Sign Up</Link>
                      </Fragment>
                  }
              </div>
            </div>
          </div>
        </div>
      </div>

        }
}





const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    get_suggested_location_loader: state.auth.get_suggested_location_loader,
    suggestedLocations: state.auth.suggestedLocations
});
export default connect(mapStateToProps, {actionGetSuggestedLocations})(AboutUs);

