import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import banner1 from '../../assets/img/banner/1.jpg';
import SuggestedLocationItem from "../common/SuggestedLocationItem";
import {actionGetSuggestedLocations} from "../../actions/authAction";
import connect from "react-redux/es/connect/connect";
import showToast from "../../utils/showToast";

class Banner extends Component {

    handleSearchResultArea = (value) => setTimeout(() => this.setState({searchInputFocused: value}), 250);
    onLocationInput = (e) => this.setState({[e.target.name]: e.target.value}, () => setTimeout(() => this.props.actionGetSuggestedLocations(this.state.location), 500));
    handleSelectLocation = (location, lat, lng) => this.setState({
        location,
        lat,
        lng
    }, () => this.handleSearchResultArea(false));
    handleSelectCurrentLocation = location => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                this.setState({lat, lng, location}, () => this.handleSearchResultArea(false));
            });
        } else showToast('Geolocation is not supported by this browser.', 'error');
    };
    onChange = (e) => this.setState({[e.target.name]: e.target.value});

    constructor(props) {
        super(props);
        this.state = {
            location: '',
            lat: '',
            lng: '',
            q: '',
            searchInputFocused: false
        };
    }

    componentDidMount() {
        const $ = window.$;
        if ($('.single-select').length) {
            $('.single-select').niceSelect();
        }
    }

    render() {

        const {suggestedLocations} = this.props;
        let data = sectiondata.banner;

        const inlineStyle = {
            backgroundImage: `url(${banner1})`
        };

        return <div className="banner-area" style={inlineStyle}>
            <div className="container pt-lg-5 banner-area-responsiv">
                <div className="banner-inner-wrap">
                    <div className="row">
                        <div className="col-12 mt-sm-5 ">
                            <div className="banner-inner">
                                <h5 className="sub-title">{data.subtitle}</h5>
                                {/*<h1 className="title">{data.title1} <br/> {data.title2}</h1>*/}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="banner-search-wrap">
                                <form action="/property-listing" method="get">
                                    <div className="rld-main-search">
                                        <div className="row">
                                            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                                <div className="rld-single-input left-icon-location">
                                                    <input type="text"
                                                           name="location"
                                                           onChange={this.onLocationInput}
                                                           autoComplete={'off'}
                                                           value={this.state.location}
                                                           onFocus={() => this.handleSearchResultArea(true)}
                                                           onBlur={() => this.handleSearchResultArea(false)}
                                                           className="rdl-mobb"
                                                           placeholder="Enter Location, Street, ZIP Code"/>

                                                    {this.state.searchInputFocused &&
                                                    <div className="suggestion-area">
                                                        <SuggestedLocationItem
                                                            key={0}
                                                            handleSelectLocation={this.handleSelectCurrentLocation}
                                                            formatted_address={''}
                                                            locationName={'Current Location'}/>
                                                        {suggestedLocations.map((location, index) =>
                                                            <SuggestedLocationItem
                                                                key={(index + 1)}
                                                                handleSelectLocation={this.handleSelectLocation}
                                                                lat={location.latitude}
                                                                lng={location.longitude}
                                                                locationName={location.name}
                                                                formatted_address={location.formatted_address}
                                                            />)}
                                                    </div>}

                                                </div>
                                            </div>
                                            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                                <div className="rld-single-input left-icon">
                                                    <input type="text"
                                                           id="query"
                                                           className="rdl-mobb"
                                                           name="q"
                                                           value={this.state.q}
                                                           onChange={this.onChange}
                                                           placeholder="Enter Agent Name, Category Name"/>
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-6 col-md-6 readeal-top">
                                                <button type="submit" className="btn btn-yellow">SEARCH NOW</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {!this.props.isAuthenticated ?
                            <div className="col-12">
                                <div className="banner-search-wrap">
                                    <div className="rld-main-search">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="rld-single-select text-center">
                                                    <h4>For Free</h4>
                                                    <p>Limited Time Offer</p>
                                                    <button className="register-now-btn mt-2"><Link to="/register">Register
                                                        Now</Link>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="rld-single-select text-center rdl-mobile mt">
                                                    <h4>Jawahar Dodani</h4>
                                                    <p>Founder/President Vision Statement</p>
                                                    <button className="register-now-btn mt-2"><Link
                                                        to='/jawahar-dodani'>Watch
                                                        Now</Link></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="col-12">
                                <div className="banner-search-wrap">
                                    <div className="rld-main-search">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="rld-single-select text-center">
                                                    <h4>Sell Your Property ?</h4>
                                                    {/*<p></p>*/}
                                                    <button className="register-now-btn mt-2"><Link to="/add-property">Add Property</Link>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div className="rld-single-select text-center rdl-mobile mt">
                                                    <h4>Looking for the Property ?</h4>
                                                    {/*<p></p>*/}
                                                    <button className="register-now-btn mt-2"><Link
                                                        to='/property-listing'>Properties
                                                       </Link></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>

    }
}


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    get_suggested_location_loader: state.auth.get_suggested_location_loader,
    suggestedLocations: state.auth.suggestedLocations
});
export default connect(mapStateToProps, {actionGetSuggestedLocations})(Banner);