import React, {useEffect} from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import Mission from '../section-components/mission';
import AboutUs from '../section-components/about-us';
import ServiceTwo from '../section-components/service-two';
import Team from '../section-components/team';
import Footer from '../global-components/footer';
import {ABOUT_US} from "../../actions/types";
import ReactGA from "react-ga";

const About = () => {
    window.scrollTo(0, 0);
    useEffect(()=>{
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "About | Global Agent Network";

    },[]);
    return <div>
        <Navbar scenario={ABOUT_US}/>
        <PageHeader headertitle="About" />
        <Mission />
        <AboutUs />
        <ServiceTwo />
        {/*<Team />*/}
        {/*<Client />*/}
        <Footer />
    </div>
};

export default About;