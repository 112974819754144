import React, { Component } from "react";
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import classnames from "classnames";
import { connect } from "react-redux";
import { actionContactForm, actionSubmitForm } from "../../actions/authAction";
import btnLoader from "../../assets/img/button-loader.svg";
import { CONTACT_US } from "../../actions/types";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import PhoneInput from "react-phone-input-2";

class ContactCopy extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      name: "",
      email: "",
      mobile_no: "",
      city: "",
      errors: {},
      // loader: false,
    };
  }
  componentDidMount() {
    ReactGA.initialize("G-2XQB9WGPED");
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
    document.title = "Contact | Global Agent Network";
  }

  onChange = (e) =>
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (
        this.state.name.length > 0 ||
        this.state.mobile_no.length > 0 ||
        this.state.city.length > 0
      ) {
        this.setState({ errors: {} });
      }
    });
  onChangeEmail = (e) =>
    this.setState({ [e.target.name]: e.target.value.trim() }, () => {
      if (this.state.email.length > 0) {
        this.setState({ errors: {} });
      }
    });

  handleContactFormValidation = () => {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    const strchek = /^[A-Z a-z]+$/;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if (!fields.name.trim()) {
      formIsValid = false;
      errors.name = "Name must be Required!";
    } else if (!fields.name.trim().match(strchek)) {
      formIsValid = false;
      errors.name = "Name in must be Required!";
    }
    if (!fields.email) {
      formIsValid = false;
      errors.email = "Email must be Required!";
    } else if (!validEmailRegex.test(fields.email)) {
      formIsValid = false;
      errors.email = "Email must be  Valid!";
    }

    if (!fields.mobile_no) {
      formIsValid = false;
      errors.mobile_no = "Contact number must be Required!";
    } else if (!fields.mobile_no.length === 10) {
      formIsValid = false;
      errors.mobile_no = "Please enter 10 digits for Phone Number!";
    }
    if (!fields.city) {
      formIsValid = false;
      errors.city = "City must be Required!";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  // onContactSubmit = async (e) => {
  //     e.preventDefault();
  //     if (this.handleContactFormValidation()) {
  //         this.state.loader(true);
  //         try {
  //             const requestOptions = {
  //                 method: "POST",
  //                 headers: { "Content-Type": "application/json" },
  //                 body: JSON.stringify({
  //                     title: "React POST Request Example",
  //                     ...this.state.value,
  //                 }),
  //             };
  //             let results = await fetch(`http://192.168.29.118/agent-network-repository/ws/add-contact-event`, requestOptions)
  //                 .then((e) => {
  //                     if (!e.ok) {
  //                         throw new Error("Failed");
  //                     }
  //                     return e.json();
  //                 })
  //                 .then((data) => {
  //                     data.status === 200 && this.state.loader(false);
  //                     this.state.value({
  //                         name: "",
  //                         email: "",
  //                         mobile_no: "",
  //                         city: "",
  //                     });
  //                     this.state.alertSuccess(true);
  //                     // Set a timeout to hide the success message after 3 seconds (3000 milliseconds)
  //                     setTimeout(() => {
  //                         this.state.alertSuccess(false);
  //                     }, 3000);
  //                 });
  //         } catch (error) {
  //             console.log(error);
  //             this.state.loader(false);
  //         }
  //         const { name, email, mobile_no, city } = this.state;
  //         const contactFormRequest = {
  //             name,
  //             mobile_no,

  //             email,
  //             city
  //         };

  //         this.props.actionContactForm(contactFormRequest);
  //         this.setState({
  //             name: '',
  //             email: '',
  //             mobile_no: '',
  //             city: '',
  //             errors: {}
  //         });

  //     }
  // }

  onContactSubmit = (e) => {
    e.preventDefault();
    if (this.handleContactFormValidation()) {
      // Register Success Now API Calling begin
      const { name, email, mobile_no, city } = this.state;
      const registerRequest = {
        name,
        email,
        mobile_no: `+${mobile_no}`
          .replace(/[^+\d]+/g, "")
          .toString()
          .slice(-10),
        city,
      };
      console.log(mobile_no);
      console.log(registerRequest, "registerRequest");
      this.props.actionSubmitForm(registerRequest);
      this.setState({
        name: "",
        email: "",
        city: "",
        mobile_no: "",
        errors: {},
      });
    }
  };
  render() {
    const { errors } = this.state;
    const { contactFromLoading } = this.props.auth;
    return (
      <div>
        <Navbar scenario={CONTACT_US} />
        <PageHeader headertitle="Contact" />
        <div className="contact-area pd-top-100 pd-bottom-65">
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-8">
                                <div className="contact-page-map">

                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3324.42450256965!2d-111.89067358440731!3d33.56832585052229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b7533dcc18a4f%3A0x8ac9e805fe084a0e!2s8989%20E%20V%C3%ADa%20Linda%20%23117A%2C%20Scottsdale%2C%20AZ%2085258%2C%20USA!5e0!3m2!1sen!2sin!4v1612329377859!5m2!1sen!2sin"
                                        width="100%" frameBorder="0"
                                        style={{border: 0}} allowFullScreen/>
                                </div>
                            </div> */}
              <div className="col-lg-4 mx-auto">
                <form
                  className="contact-form-wrap contact-form-bg"
                  autoComplete="off"
                  noValidate={true}
                  onSubmit={this.onContactSubmit}
                >
                  <h4 className="text-center">Register Now</h4>
                  <div className="rld-single-input">
                    <input
                      type="text"
                      placeholder="Full Name"
                      name="name"
                      onChange={this.onChange}
                      style={{ fontSize: "15px !important" }}
                      value={this.state.name}
                      id="rdl-mobbb"
                      className={classnames({
                        "is-invalid": errors.name,
                      })}
                    />
                    {errors.name && (
                      <span className="text-danger">{errors.name}</span>
                    )}
                  </div>
                  <div className="rld-single-input">
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      id="rdl-mobbb"
                      onChange={this.onChangeEmail}
                      value={this.state.email}
                      className={classnames({
                        "is-invalid": errors.email,
                      })}
                    />
                    {errors.email && (
                      <span className="text-danger">{errors.email}</span>
                    )}
                  </div>
                  {/* <div className="d-flex justify-content-between flex-wrap ">
                                        <label><input type="checkbox" className="mr-1" id="agree" value={this.state.agree}
                                                      onChange={(e) => this.setState({agree: e.target.checked})}/>I agree to receive an email from Global India Real Estate.</label>
                                    </div> */}
                  {/* {errors.agree && <span className='text-danger'>{errors.agree}</span>} */}
                  <div className="rld-single-input">
                    {/* <input type="number" placeholder="Contact Number" name='mobile_no
'
                                            id="rdl-mobbb"
                                            value={this.state.mobile_no}
                                            onChange={this.onChange}
                                            className={classnames({
                                                'is-invalid': errors.mobile_no
                                            })} /> */}
                    <input
                    type="number"
                      name="mobile_no"
                      id="rdl-mobbb"
                      placeholder="Mobile No"
                      style={{ width: "100%" }}
                      value={this.state.mobile_no}
                      onChange={this.onChange}
                      className={classnames("w-100", {
                        "is-invalid": errors.mobile_no,
                      })}
                    />
                    {errors.mobile_no && (
                      <span className="text-danger">{errors.mobile_no}</span>
                    )}
                  </div>
                  <div className="rld-single-input">
                    <input
                      rows={10}
                      placeholder="City"
                      defaultValue={""}
                      name="city"
                      value={this.state.city}
                      onChange={this.onChange}
                      className={classnames({
                        "is-invalid": errors.city,
                      })}
                    />
                    {errors.city && (
                      <span className="text-danger">{errors.city}</span>
                    )}
                  </div>
                  <div className="btn-wrap text-center">
                    {/*<button className="btn btn-yellow" type="submit">Submit</button>?*/}
                    {contactFromLoading ? (
                      <button className="btn btn-yellow" type="button">
                        <img src={btnLoader} alt="contact-loader" width={15} />
                      </button>
                    ) : (
                      <button className="btn btn-yellow" type="submit">
                        Register
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="row pd-top-92">
              <div className="col-xl-4 col-md-6 col-sm-12">
                <div className="single-contact-info">
                  <p className="font-weight-bold">
                    <i className="fa fa-phone" />
                    Connect with us:
                  </p>
                  Toll-free:-
                  <a
                    href="tel:+1 1800-891-8535"
                    style={{ cursor: "pointer", fontSize: "16px" }}
                  >
                    {" "}
                    1800-891-8535
                  </a>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-sm-12">
                <div className="single-contact-info">
                  <p className="font-weight-bold">
                    <i className="fa fa-envelope" />
                    Have any Question?
                  </p>
                  <a href="mailto:Jawahar@DodaniGroup.com">
                    Jawahar@DodaniGroup.com
                  </a>{" "}
                  <br />
                  <a href="mailto:Support@GlobalIndiaRealEstate.com">
                    Support@GlobalIndiaRealEstate.com
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12">
                <div className="single-contact-info">
                  <p className="font-weight-bold">
                    <i className="fa fa-map-marker" />
                    Address
                  </p>
                  <p>
                    {" "}
                    8989 East Via Linda, <br />
                    #117A Scottsdale 85250 Arizona{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    submitFormLoading: state.auth.submitFormLoading,
  };
};

export default connect(mapStateToProps, {
  actionContactForm,
  actionSubmitForm,
})(ContactCopy);
