import React from 'react';
import classnames from "classnames";

const PropertyCommonSelect = (props) => {
    return (
        <div className="row mt-4">
            <div className="col-md-7 col-sm-12 text-md-right mt-1 mx-auto">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-4 "><label>{props.label}<span
                        className={'text-danger'}>{props.required}</span></label></div>
                    <div className="col-md-8">
                        <div className="rld-single-select text-center">
                            <select
                                className="select single-select"
                                name={props.name}
                                id={props.name}
                                value={props.value}
                                onChange={props.onChange}
                            >
                                <option value="">Please Select</option>
                                {props.data && props.data.map((d, index) => <option key={index}
                                                                                    selected={index === 0 ? index === 0 : ''}
                                                                                    value={d}>{d}</option>)}
                            </select>
                            {props.error && <span className='text-danger'>{props.error}</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyCommonSelect;