import React, {Component, useEffect} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import AgentFriendList from "../section-components/agent-friend-list";
import Footer from "../global-components/footer";
import {FRIENDS} from "../../actions/types";
import ReactGA from "react-ga";

class AgentFriend extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }
    componentDidMount() {
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Friends | Global Agent Network";


    }

    render() {
        return (
            <div>
                <Navbar scenario={FRIENDS}/>
                <PageHeader headertitle="Agent Profile /Agent Friends List"/>
                <AgentFriendList match={this.props.match}/>
                <Footer/>
            </div>
        );
    }
}

export default AgentFriend;