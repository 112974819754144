import React, {useEffect} from 'react';
import Navbar from "../global-components/navbar";
import {useParams} from "react-router-dom";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import PropertyImageList from "./property-image-list";
import ReactGA from "react-ga";

const PropertyImages = (props) => {
    let params = useParams();
    useEffect(()=>{
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Images | Global Agent Network";
        // ReactGA.initialize('G-2XQB9WGPED');
        // ReactGA.pageview(`/property-images/${params.propertyId &&params.propertyId}`);

    },[]);
    return (
        <div>
            <Navbar/>
            <PageHeader headertitle="Property Images"/>
            <PropertyImageList propertyId={params.propertyId}/>
            <Footer/>
        </div>
    );
};

export default PropertyImages;