import React, {Component, Fragment} from 'react';
import {Link, NavLink} from 'react-router-dom';
import logo from '../../assets/img/logo.png';
import {actionMakeNotificationRead, logoutUser} from "../../actions/authAction";
import {connect} from "react-redux";
import defaultAvatar from '../../assets/img/nophoto.png';
import NotificationItem from "../common/NotificationItem";
import {
    HOME,
    AGENTS,
    PROPERTIES,
    ABOUT_US,
    CONTACT_US,
    BLOG,
    AGENT_PROFILE,
    REGISTER,
    USER_PROFILE,
    FRIENDS, ADD_PROPERTY, MY_PROPERTY, FAVOURITE, TESTIMONIAL, CHAT, CHANGE_PASSWORD
} from "../../actions/types";
import classnames from "classnames";


class Navbar extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.closeNav();
    }


    openNav = () => {
        document.getElementById("mySidenav").style.width = "100%";


    };
    closeNav = () => {
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("agent-network-main-menu").classList.remove('show')
    };

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'
        let imgattr = 'logo';
        let anchor = '#';
        const {notifications} = this.props.user;
        console.log(this.props.scenario)

        const activeStyle = {
            color: '#fd7e14',
        };
        return (
            <div>
                <div className="navbar-area">
                    <nav className="navbar navbar-area navbar-expand-lg">
                        <div className="container nav-container">
                            <div className="responsive-mobile-menu">
                                <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse"
                                        data-target="#agent-network-main-menu" aria-expanded="true"
                                        aria-label="Toggle navigation" onClick={this.openNav}>
                                    &#9776;
                                </button>

                            </div>
                            <div className="logo readeal-top">
                                <NavLink to="/"><img src={logo} alt="logo" style={{width: "150px"}}/></NavLink>
                            </div>
                            <div className="nav-right-part nav-right-part-mobile">
                                {this.props.isAuthenticated &&
                                <li className="nav-item dropdown notification-ui note">
                                    <NavLink className="nav-link dropdown-toggle notification-ui_icon" to="/"
                                             id="navbarDropdown" role="button" data-toggle="dropdown"
                                             aria-haspopup="true"
                                             aria-expanded="false"
                                             onClick={(e) => {
                                                 e.preventDefault();
                                                 //MakeNotificationRead
                                                 this.props.actionMakeNotificationRead(this.props.user.user_id)
                                             }}
                                    >
                                        <i className="fa fa-bell"/>

                                        {(notifications && notifications.filter(n => parseInt(n.is_read) === 0).length > 0) &&
                                        <span className="unread-notification"/>}
                                    </NavLink>
                                    <div className="dropdown-menu notification-ui_dd "
                                         aria-labelledby="navbarDropdown" style={{left: '-52px !important'}}>
                                        <div className="notification-ui_dd-header">
                                            <h3 className="text-center">Notification</h3>
                                        </div>
                                        <div className="notification-ui_dd-content">
                                            {notifications && notifications.length > 0 && notifications.map((notification, index) =>
                                                <NotificationItem
                                                    key={index}
                                                    notificationId={notification.notification_id}
                                                    title={notification.title}
                                                    time={notification.created_at}
                                                    action={notification.action}
                                                    userProfile={notification.created_by.profile_pic}
                                                    first_name={notification.created_by.first_name ? notification.created_by.first_name : ''}
                                                    last_name={notification.created_by.first_name ? notification.created_by.first_name : ''}
                                                    user_id={notification.created_by.user_id ? notification.created_by.user_id : 0}
                                                />)}

                                        </div>
                                    </div>
                                </li>
                                }
                            </div>
                            <div className="collapse navbar-collapse" id="agent-network-main-menu">
                                <ul className="navbar-nav menu-open readeal-top">

                                    <li><NavLink to="/" activeClassName={classnames('active', {
                                        'active-main-menu': this.props.scenario === HOME
                                    })}>Home</NavLink></li>

                                    <li><NavLink to="/agent-list" activeClassName={classnames('active', {
                                        'active-main-menu': this.props.scenario === AGENTS
                                    })}>Agents</NavLink>
                                    </li>
                                    <li><NavLink to="/property-listing" activeClassName={classnames('active', {
                                        'active-main-menu': this.props.scenario === PROPERTIES
                                    })}>Properties</NavLink></li>
                                    <li><NavLink to="/blog" activeClassName={classnames('active', {
                                        'active-main-menu': this.props.scenario === BLOG
                                    })}>Blog</NavLink></li>

                                    <li><NavLink to="/About" activeClassName={classnames('active', {
                                        'active-main-menu': this.props.scenario === ABOUT_US
                                    })}>About Us</NavLink></li>
                                    <li><NavLink to="/contact" activeClassName={classnames('active', {
                                        'active-main-menu': this.props.scenario === CONTACT_US
                                    })}>Contact Us</NavLink></li>

                                    <li><NavLink style={{color:'#fff'}} to={this.props.isAuthenticated ?'/add-property':'/register'} activeClassName={classnames('active', {
                                        'active-main-menu': this.props.scenario === ADD_PROPERTY
                                    })}><button className="btn btn-yellow" style={{borderRadius:"30px"}}> Publish Property <span className="free-btn">Free</span></button></NavLink></li>

                                    {this.props.isAuthenticated &&

                                    <li className="nav-item dropdown notification-ui note nav-right-part-desktop">
                                        <NavLink className="nav-link dropdown-toggle notification-ui_icon" to="/"
                                                 id="navbarDropdown" role="button" data-toggle="dropdown"
                                                 aria-haspopup="true"
                                                 aria-expanded="false"
                                                 onClick={(e) => {
                                                     e.preventDefault();
                                                     //MakeNotificationRead
                                                     this.props.actionMakeNotificationRead(this.props.user.user_id)
                                                 }}
                                        >
                                            <i className="fa fa-bell"/>

                                            {(notifications && notifications.filter(n => parseInt(n.is_read) === 0).length > 0) &&
                                            <span className="unread-notification"/>}
                                        </NavLink>
                                        <div className="dropdown-menu notification-ui_dd "
                                             aria-labelledby="navbarDropdown">
                                            <div className="notification-ui_dd-header">
                                                <h3 className="text-center">Notification</h3>
                                            </div>
                                            <div className="notification-ui_dd-content">
                                                {notifications && notifications.length > 0 && notifications.map((notification, index) =>
                                                    <NotificationItem
                                                        key={index}
                                                        notificationId={notification.notification_id}
                                                        title={notification.title}
                                                        time={notification.created_at}
                                                        action={notification.action}
                                                        userProfile={notification.created_by.profile_pic}
                                                        first_name={notification.created_by.first_name ? notification.created_by.first_name : ''}
                                                        last_name={notification.created_by.first_name ? notification.created_by.first_name : ''}
                                                        user_id={notification.created_by.user_id ? notification.created_by.user_id : 0}
                                                    />)}

                                            </div>
                                        </div>
                                    </li>
                                    }

                                    {!this.props.isAuthenticated &&
                                    <li><NavLink to="/register" activeClassName={classnames('active', {
                                        'active-main-menu': this.props.scenario === REGISTER
                                    })}>Register</NavLink></li>}
                                    {this.props.isAuthenticated && <li className="menu-item-has-children">
                                        <NavLink to="/" className="profile-avatar" data-toggle="sub-menu"
                                                 data-target=".sub-menu" id="dropdownMenuLink"><img
                                            src={this.props.user.profile_pic ? this.props.user.profile_pic.includes("https://")?this.props.user.profile_pic:defaultAvatar: defaultAvatar}
                                            width={35}
                                            alt="user default avatar"/></NavLink>
                                        <ul className="sub-menu">
                                            <li><NavLink
                                                to={`/profile/${this.props.user.user_id}`}
                                                activeClassName={classnames('active', {
                                                    'active-main-menu': this.props.scenario === USER_PROFILE
                                                })}>{`${this.props.user.first_name} ${this.props.user.last_name}`}</NavLink>
                                            </li>
                                            <li><NavLink
                                                to={`/agent-friend-list/${this.props.user.user_id}`}
                                                activeClassName={classnames('active', {
                                                    'active-main-menu': this.props.scenario === FRIENDS
                                                })}>Friends</NavLink>
                                            </li>
                                            <li><NavLink to="/add-property" activeClassName={classnames('active', {
                                                'active-main-menu': this.props.scenario === ADD_PROPERTY
                                            })}>Add Property</NavLink></li>
                                            <li><NavLink to="/my-properties" activeClassName={classnames('active', {
                                                'active-main-menu': this.props.scenario === MY_PROPERTY
                                            })}>My Properties</NavLink></li>
                                            <li><NavLink to="/favourite-properties"
                                                         activeClassName={classnames('active', {
                                                             'active-main-menu': this.props.scenario === FAVOURITE
                                                         })}>Favourite</NavLink></li>
                                            <li><NavLink to="/testimonials" activeClassName={classnames('active', {
                                                'active-main-menu': this.props.scenario === TESTIMONIAL
                                            })}>Testimonials</NavLink></li>
                                            <li><NavLink to="/messages" activeClassName={classnames('active', {
                                                'active-main-menu': this.props.scenario === CHAT
                                            })}>Chat</NavLink></li>
                                            {this.props.isAuthenticated &&
                                            <li><NavLink to={`/change-password/${this.props.user.user_id}`}
                                                         activeClassName={classnames('active', {
                                                             'active-main-menu': this.props.scenario === CHANGE_PASSWORD
                                                         })}>Change
                                                Password</NavLink>
                                            </li>}
                                            <li><NavLink to="/"
                                                         onClick={(e) => {
                                                             e.preventDefault();
                                                             this.props.logoutUser();
                                                         }
                                                         }>Logout</NavLink>
                                            </li>
                                        </ul>
                                    </li>}
                                </ul>


                                <ul id="mySidenav" className="sidenav">
                                    {this.props.user.profile_pic &&
                                    <div className="logo readeal-top text-center d-flex flex-column">
                                        <NavLink to={`/profile/${this.props.user.user_id}`} onClick={this.closeNav}>
                                            <img
                                                src={this.props.user.profile_pic ? this.props.user.profile_pic : defaultAvatar}
                                                alt="logo" style={{width: "90px", borderRadius: '74px'}}
                                                onClick={this.closeNav}/></NavLink>


                                        {this.props.user.first_name &&
                                        <NavLink to={`/profile/${this.props.user.user_id}`} onClick={this.closeNav}
                                                 className="h5 font-weight-bold">
                                            {`${this.props.user.first_name} ${this.props.user.last_name}`}

                                        </NavLink>
                                        }

                                    </div>
                                    }
                                    {!this.props.user.profile_pic &&
                                    <div className="">
                                        <NavLink to="/" onClick={this.closeNav}>
                                            <img
                                                src={logo}
                                                alt="logo" style={{width: "150px"}}
                                                onClick={this.closeNav}/></NavLink>
                                    </div>
                                    }
                                    <a className="closebtn" onClick={this.closeNav}>&times;</a>

                                    <li className="a"><NavLink to="/" onClick={this.closeNav}
                                                               activeClassName={classnames('active', {
                                                                   'active-main-menu': this.props.scenario === HOME
                                                               })}><i
                                        className="fa fa-home"/> Home</NavLink></li>
                                    <li className="a"><NavLink to="/agent-list" onClick={this.closeNav}
                                                               activeClassName={classnames('active', {
                                                                   'active-main-menu': this.props.scenario === AGENTS
                                                               })}><i
                                        className="fa fa-user-secret"/> Agents</NavLink></li>
                                    <li className="a"><NavLink to="/property-listing"
                                                               onClick={this.closeNav}
                                                               activeClassName={classnames('active', {
                                                                   'active-main-menu': this.props.scenario === PROPERTIES
                                                               })}><i
                                        className="fa fa-building-o"/> Properties</NavLink></li>
                                    <li className="a"><NavLink to="/blog"
                                                               onClick={this.closeNav}
                                                               activeClassName={classnames('active', {
                                                                   'active-main-menu': this.props.scenario === BLOG
                                                               })}><i
                                        className="fa fa-building-o"/> Blog</NavLink></li>


                                    <li className="a"><NavLink to="/About" onClick={this.closeNav}
                                                               activeClassName={classnames('active', {
                                                                   'active-main-menu': this.props.scenario === ABOUT_US
                                                               })}><i
                                        className="fa fa-info-circle"/> About Us</NavLink></li>
                                    <li className="a"><NavLink to="/contact" onClick={this.closeNav}
                                                               activeClassName={classnames('active', {
                                                                   'active-main-menu': this.props.scenario === CONTACT_US
                                                               })}><i
                                        className="fa fa-address-card"/> Contact Us</NavLink></li>
                                    <li className="a"> <NavLink to={this.props.isAuthenticated?'/add-property':'/register'} onClick={this.closeNav}
                                                               activeClassName={classnames('active', {
                                                                   'active-main-menu': this.props.scenario === ADD_PROPERTY
                                                               })}><button className="btn btn-yellow" style={{borderRadius:"30px"}}><i
                                        className="fa fa-plus-square"/>Add Property</button></NavLink></li>

                                    {!this.props.isAuthenticated &&
                                    <li className="a"><NavLink to="/register" onClick={this.closeNav}
                                                               activeClassName={classnames('active', {
                                                                   'active-main-menu': this.props.scenario === REGISTER
                                                               })}><i
                                        className="fa fa-user-plus"/> Register</NavLink></li>}
                                    {this.props.isAuthenticated &&
                                    <li className="menu-item-has-children a" style={{border: 'none'}}
                                        onClick={this.closeNav}>
                                        <ul className="sub-menu">
                                            <li className="ab"><NavLink
                                                to={`/agent-friend-list/${this.props.user.user_id}`}
                                                activeClassName={classnames('active', {
                                                    'active-main-menu': this.props.scenario === FRIENDS
                                                })}><i
                                                className="fa fa-users"/> Friends</NavLink></li>
                                            <li className="ab"><NavLink to="/add-property"
                                                                        activeClassName={classnames('active', {
                                                                            'active-main-menu': this.props.scenario === ADD_PROPERTY
                                                                        })}><i
                                                className="fa fa-plus-square"/> Add Property</NavLink></li>
                                            <li className="ab"><NavLink to="/my-properties"
                                                                        activeClassName={classnames('active', {
                                                                            'active-main-menu': this.props.scenario === MY_PROPERTY
                                                                        })}><i
                                                className="fa fa-building"/> My Properties</NavLink></li>
                                            <li className="ab"><NavLink to="/favourite-properties"
                                                                        activeClassName={classnames('active', {
                                                                            'active-main-menu': this.props.scenario === FAVOURITE
                                                                        })}><i
                                                className="fa fa-heart"/> Favorite</NavLink></li>
                                            <li className="ab"><NavLink to="/testimonials"
                                                                        activeClassName={classnames('active', {
                                                                            'active-main-menu': this.props.scenario === TESTIMONIAL
                                                                        })}><i
                                                className="fa fa-comments"/> Testimonials</NavLink></li>
                                            <li className="ab"><NavLink to="/messages"
                                                                        activeClassName={classnames('active', {
                                                                            'active-main-menu': this.props.scenario === CHAT
                                                                        })}><i
                                                className="fa fa-telegram"/> Chat</NavLink></li>
                                            {this.props.isAuthenticated &&
                                            <li className="ab"><NavLink
                                                to={`/change-password/${this.props.user.user_id}`}
                                                activeClassName={classnames('active', {
                                                    'active-main-menu': this.props.scenario === CHANGE_PASSWORD
                                                })}><i
                                                className="fa fa-refresh"/> Change
                                                Password</NavLink>
                                            </li>}
                                            <li className="ab"><NavLink to="/"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.props.logoutUser();
                                                                        }
                                                                        }> <i
                                                className="fa fa-sign-out"/> Logout</NavLink>
                                            </li>
                                        </ul>
                                    </li>}

                                </ul>


                                {/*Umesh Code End*/}


                            </div>


                        </div>
                    </nav>


                </div>


            </div>

        )
    }
}


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user
});
export default connect(mapStateToProps, {logoutUser, actionMakeNotificationRead})(Navbar);