import * as actionTypes from '../actions/types';

const initialState = {
    agentData: {},
    agents: [],
    exploreNewAgents: [],
    agentsCount: 0,
    agentLoader: true,
    getAgentLoader: false,
    TestimonialsLoader: false,
    testimonialsData: [],
    getExploreAgentLoader:false,
    blogLoader: false,
    blogData: [],
    singleBlogData:''
};

export default function (state = initialState, action) {
    switch (action.type) {
        //blog
        case actionTypes.BLOG_DATA:
            return {
                ...state,
                blogData: action.payload
            };
        case actionTypes.BLOG_LOADING:
            return {
                ...state,
                blogLoader: action.payload
            };
            //
        case actionTypes.BLOG_SINGLE_DATA:
            return {
                ...state,
                singleBlogData: action.payload
            };
        // Get Data Agent
        case actionTypes.GET_AGENT:
            return {
                ...state,
                agentData: action.payload
            };
            case actionTypes.GET_AGENT_LOADING:
            return {
                ...state,
                getAgentLoader: action.payload
            };
        // Get Explore new Agent
        case actionTypes.GET_EXPLORE_NEW_AGENT:
            return {
                ...state,
                exploreNewAgents: action.payload
            };
            case actionTypes.GET_EXPLORE_NEW_AGENT_LOADING:
            return {
                ...state,
                getExploreAgentLoader: action.payload
            };
        case  actionTypes.GET_AGENTS:
            return {
                ...state,
                agents: [...state.agents, ...action.payload.payload],
                agentsCount: parseInt(action.payload.totalRecords)
            };
        case  actionTypes.GET_AGENTS_LOADING:
            return {
                ...state,
                agentLoader: action.payload
            };
        case actionTypes.REMOVE_AGENTS:
            return {
                ...state,
                agents: action.payload,
                agentsCount: parseInt(action.payload.totalRecords)
            };
        case  actionTypes.MAKE_REQUESTED:
            return {
                ...state,
                agents: state.agents.map(agentData => parseInt(agentData.user_id) === action.payload.related_user_id ? {
                    ...agentData,
                    user_relation: 'REQUEST',
                    user_relation_object: action.payload.data
                } : agentData)
            };
        case  actionTypes.UN_FRIEND:
            return {
                ...state,
                agents: state.agents.map(agentData => parseInt(agentData.user_id) === action.payload ? {
                    ...agentData,
                    user_relation: 'NoRelation',
                } : agentData)
            };
        case  actionTypes.AGENT_FRIED_UN_FRIEND:
            return {
                ...state,
                agents: state.agents.filter(agentData => parseInt(agentData.user_id) !== parseInt(action.payload))

            };
        case  actionTypes.RESET_USER_RELATION:
            return {
                ...state,
                agents: state.agents.map(agentData => parseInt(agentData.user_id) === action.payload ? {
                    ...agentData,
                    user_relation: 'NoRelation',
                    user_relation_object: false,
                } : agentData)
            };
        case  actionTypes.ACCEPT_FRIEND_REQUEST:
            return {
                ...state,
                agents: state.agents.map(agentData => parseInt(agentData.user_id) === action.payload.itemId ? {
                    ...agentData,
                    user_relation: 'FRIEND',
                    user_relation_object: action.payload.data,
                } : agentData)
            };

        case  actionTypes.GET_USER_RELATION:
            return {
                ...state,
                agentData: {
                    ...state.agentData,
                    user_relation_object: action.payload ? action.payload : '',
                    user_relation: action.payload.type ? action.payload.type : 'NoRelation',


                }
            };

        // Get Testimonials
        case actionTypes.GET_TESTIMONIALS:
            return {
                ...state,
                testimonialsData: action.payload
            };
        case actionTypes.GET_TESTIMONIALS_LOADING:
            return {
                ...state,
                TestimonialsLoader: action.payload
            };

        // ADD Testimonials
        case actionTypes.ADD_TESTIMONIAL:
            return {
                ...state,
                testimonialsData: [...state.testimonialsData,action.payload]
            };
        case actionTypes.ADD_TESTIMONIAL_LOADING:
            return {
                ...state,
                TestimonialsLoader: action.payload
            };
        // Update Testimonials
        case actionTypes.UPDATE_TESTIMONIALS:
            return {
                ...state,

                testimonialsData: [...state.testimonialsData.filter(val=>  parseInt(val.testimonial_id) !== parseInt(action.payload.testimonial_id)), {
                    agent_id:action.payload.agent_id,
                    created_at:action.payload.created_at,
                    deleted_at:action.payload.deleted_at,
                    description: action.payload.description,
                    image: `${action.payload.image}`,
                    testimonial_id: action.payload.testimonial_id,
                    title:action.payload.title,
                    updated_at:action.payload.updated_at,
                }]
            };
        case actionTypes.UPDATE_TESTIMONIALS_LOADING:
            return {
                ...state,
                TestimonialsLoader: action.payload
            };
        // DELETE Testimonials
        case actionTypes.DELETE_TESTIMONIALS_SUCCESS:
            return {
                ...state,
                testimonialsData: [...state.testimonialsData.filter(p => parseInt(p.testimonial_id) !== parseInt(action.payload))],



            };
        case actionTypes.DELETE_TESTIMONIALS_LOADING:
            return {
                ...state,
                TestimonialsLoader: action.payload
            };

        default:
            return state;
    }
}