import React, {useEffect} from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import PrivacyPolicy from '../section-components/privacy-policy';
import Footer from '../global-components/footer';
import ReactGA from "react-ga";

const Privacy = () => {
    window.scrollTo(0, 0);
    useEffect(()=>{
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Privacy Policy | Global Agent Network";


    },[]);
    return <div>
        <Navbar />
        <PageHeader headertitle="Privacy Policy" />
        <PrivacyPolicy/>
        <Footer />
    </div>
}

export default Privacy;