import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {

    actionGetPropertyByFilter,
    actionGetUnits
} from "../../actions/propertyAction";
import IntoSection from "../home/into-section";

class PropertiesByCities extends Component {


    render() {
        const {propertyData, getPropertyLoader} = this.props;

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.propertiesbycities

        const inlineStyle = {
            backgroundImage: 'url('+publicUrl+'/assets/img/bg/2.png)'
        };

        console.log(data.items.map( i => i.class));
        console.log(data.items.map( i => i.url));
    return <div className="city-intro-area pd-bottom-70" style={ inlineStyle }>
        {/* city area start */}
        <div className="city-area pd-top-92">
          <div className="container">
            <div className="section-title text-center">
              <h2 className="title">{ data.title }</h2>
            </div>
            <div className="city-filter-area row">
              <div className="city-sizer col-1" />

              { data.items.map( ( item, i ) =>
                  <div key={ i } className={"rld-city-item "+item.class}>
                    <div className={"single-city "+item.sectionclass}>
                      <div className="sc-overlay" />
                      <div className="thumb">
                        <img src={ publicUrl+item.image } alt={ imagealt } />
                      </div>
                      <div className="details">
                        <h5 className="title"><Link to={`/property-listing?location=${encodeURI(item.city_url)}` }>{ item.city }</Link></h5>
                        <p>{ item.content }</p>
                      </div>
                    </div>
                  </div>
               ) }

            </div>
          </div>
        </div>
        {/* city area end */}
        {/* intro area start */}
       {/*<IntoSection/>*/}
        {/* intro area start */}
      </div>


        }
}


const mapStateToProps = state => {
    return {


        getPropertyLoader: state.property.getPropertyLoader,
        units: state.property.units,
        propertyData: state.property.propertyData,

    };
};

export default connect(mapStateToProps, {

    actionGetUnits,
    actionGetPropertyByFilter,
})
(PropertiesByCities);

