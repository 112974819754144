import React from 'react';
import appBanner from '../../assets/img/App-Banner/popertys.png'


const MobileAppBanner = () => {
    return (
        <div className="mobile-app-banner-section my-5">
            <div className="container mobile-app-banner-section1">
                <div className="row">
                    <div className="col-12  col-xl-6 col-lg-6 m-auto">
                        <div>
                            <div>
                                <h1 className="h3 text-dark m-0">Download GIRE mobile App</h1>
                                <p className="m-0">and never miss out any update</p>
                            </div>
                            <div>
                                <ul className="my-1">
                                    <li><i className="fa fa-check text-primary"/> Get to know about newly posted properties as soon as they are posted</li>
                                    <li><i className="fa fa-check text-primary"/>Network with indian agents & share properties across the globe.</li>
                                </ul>
                            </div>
                        <div className="app-btn my-2">
                            <div className="row ml-1">
                                <a href="https://play.google.com/store/apps/details?id=com.agentnet" target="_blank">
                                    <img className="maut_button-appstore-logo"
                                         src="https://lh3.googleusercontent.com/cjsqrWQKJQp9RFO7-hJ9AfpKzbUb_Y84vXfjlP0iRHBvladwAfXih984olktDhPnFqyZ0nu9A5jvFwOEQPXzv7hr3ce3QVsLN8kQ2Ao=s0"
                                         alt="Get it on Google Play"/></a>
                                <a href="https://apps.apple.com/us/app/global-india-real-estate/id1587126493" target="_blank">
                                <img className="maut_button-appstore-logo"
                                     src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png"
                                     alt="Get it on Apple Store"/></a>

                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 col-lg-6">
                        <img src={appBanner} alt="appBanner"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileAppBanner;