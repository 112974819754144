import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class PrivacyPolicy extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'
        let data = sectiondata.aboutus


        return <div className="about-area pd-top-60 pd-bottom-60">
            <div className="container">
                <div className="row">

                    <div className="col-lg-12 align-self-center">
                        <div className="section-title pd-left mb-0">
                            <h2>Privacy Policy</h2>
                            <p>Globalindiarealestate.com (hence by referred to as “GLOBAL INDIA REAL ESTATE″, or “we”,
                                or “us ) is committed to ensuring that your privacy is protected. Should we ask you to
                                provide certain information by which you can be identified when using this website, and
                                then you can be assured that it will only be used in accordance with this privacy
                                policy.
                                GLOBAL INDIA REAL ESTATE may change this policy from time to time by updating this page.
                                You should check this page from time to time to ensure that you are happy with any
                                changes. This policy is effective from 3rd May 2021.
                                Information We Collect
                                We may collect the following information when you subscribe to our email newsletter or
                                fill in our enquiry form:
                                <ul>
                                    <li className="font-weight-bold">• First name</li>
                                    <li className="font-weight-bold">• Last name</li>
                                    <li className="font-weight-bold">• Email address</li>
                                    <li className="font-weight-bold">• Contact information including email address</li>
                                    <li className="font-weight-bold">• Demographic information such as postcode,
                                        preferences and interests
                                    </li>
                                    <li className="font-weight-bold">• Other information relevant to customer surveys
                                        and/or offers
                                    </li>
                                </ul>


                                What We Do With This Information
                                We require this information to understand your needs and provide you with a better
                                service, and in particular for the following reasons:
                                <lu>
                                    <li className="font-weight-bold">• Internal record keeping.</li>
                                    <li className="font-weight-bold">• We may use the information to improve our
                                        products and services.
                                    </li>
                                    <li className="font-weight-bold">• We may periodically send you emails about new
                                        products, special offers or other
                                    </li>
                                </lu>


                                information which we think you may find interesting using the email address which you
                                have provided.
                                <ul>
                                    <li className="font-weight-bold">• From time to time, we may also use your
                                        information to contact you for market research
                                    </li>
                                </ul>
                                purposes. We may contact you by email, phone, fax or mail. We may use the information to
                                customize the website according to your interests.
                                <div className="font-weight-bold">• We use your Mobile Number to send you OTP (One Time
                                    Password) to authenticate a User</div>
                                who would like to sign-up with Our Portal.

                                <div className="font-weight-bold">• By clicking "Sign Up", you agree to Global India
                                    Real Estate Portal “Terms of Use” and
                                    “Privacy Policy”. You consent to receive phone calls and SMS messages from Global
                                    India
                                    Real Estate Portal to provide updates on Property Listing and/or for marketing
                                    purposes.
                                    Message frequency depends on your activity. You may opt-out by texting "STOP" to
                                    86753.
                                    Message and data rates may apply.
                                    Security
                                    We are committed to ensuring that your information is secure. In order to prevent
                                    unauthorized access or disclosure, we have put in place suitable physical,
                                    electronic
                                    and managerial procedures to safeguard and secure the information we collect online.
                                    Cookies
                                    We use cookies on this website.
                                    A cookie is a small file which is placed on your computer’s hard drive with your
                                    implied
                                    consent. Cookies allow web applications to remember you and personalize your
                                    experience.
                                    The website can tailor its operations to your needs, likes and dislikes by gathering
                                    and
                                    remembering information about your preferences.
                                    Google Analytics & Conversion Tracking
                                    We use traffic log cookies from Google Analytics to identify which pages are being
                                    used
                                    and are an essential part of monitoring and improving our electronic communications.
                                    This helps us analyze data about web page traffic and improve our website in order
                                    to
                                    tailor it to customer needs. We use this non-identifiable aggregated traffic
                                    information
                                    for statistical analysis purposes.
                                    We also use conversion tracking to understand customer behavior.
                                    Session Cookies
                                    This site uses essential session cookies for the operation of the website.
                                    Google Adwords Cookies
                                    We may use cookies in Google Adwords for remarketing purposes. This means we
                                    remember
                                    your visit to our website to provide you with advertising elsewhere on the Google
                                    Display Network.
                                    Other Applications
                                    If we embed YouTube videos in the site, watching the video will set a cookie on your
                                    computer.
                                    We may also use embedded applications such as Twitter, Facebook. These third parties
                                    may
                                    set cookies to remember your preferences.
                                    How to Disable Cookies
                                    You can choose to accept or decline cookies. Most web browsers automatically accept
                                    cookies, but you can usually modify your browser setting to decline cookies if you
                                    prefer. This may prevent you from taking full advantage of the website.</div>

                                <ul>
                                    <li className="font-weight-bold"><b>• Google Chrome</b></li>
                                    <li className="font-weight-bold"><b>• Internet Explorer</b></li>
                                    <li className="font-weight-bold"><b>• Safari</b></li>
                                    <li className="font-weight-bold"><b>• Firefox</b></li>
                                </ul>

                                If you disable cookies, your experience of some parts of the website may be affected.
                                Links to Other Websites
                                Our website contains links to other websites of interest. However, once you have used
                                these links to leave our site, you should note that we do not have any control over that
                                other website. Therefore, we cannot be responsible for the protection and privacy of any
                                information which you provide whilst visiting such sites and such sites are not governed
                                by this privacy policy. You should exercise caution and look at the privacy policy
                                applicable to the website in question.
                                Controlling Your Personal Information
                                You may choose to restrict the collection or use of your personal information in the
                                following ways:
                                <ul>
                                    <li className="font-weight-bold"><b>• whenever you are asked to fill in a form on
                                        the website, look for the box
                                        that you can click to indicate that you do not want the information to be used
                                        by anybody for direct marketing purposes.</b></li>
                                    <li className="font-weight-bold"><b>• if you have previously agreed to us using your
                                        personal information for
                                        direct marketing purposes, you may change your mind at any time by writing to or
                                        emailing us at If you have any questions, please reach out to us at
                                        info@globalindiarealestate.com
                                        We will not sell, distribute or lease your personal information to third parties
                                        unless we have your permission or are required by law to do so. We may use your
                                        personal information to send you promotional information about third parties
                                        which we think you may find interesting if you tell us that you wish this to
                                        happen.
                                        If you believe that any information we are holding on you is incorrect or
                                        incomplete, please write to or email us as soon as possible, at the above
                                        address. We will promptly correct any information found to be incorrect.</b>
                                    </li>
                                </ul>

                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}

export default PrivacyPolicy