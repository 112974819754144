import React from 'react';
import sectiondata from "../../data/sections";

const IntoSection = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    let data = sectiondata.propertiesbycities;
    return (
        <div className="intro-area pd-top-70">
            <div className="container">
                <div className="row">
                    {data.intro.map( ( item, i )=>
                        <div key={ i } className="col-md col-sm-6-6">
                            <a href={item.url} className={"single-intro-media media "+item.class}>
                                <div className="media-left">
                                    <img src={ publicUrl+item.icon } alt={ imagealt } />
                                </div>
                                <div className="media-body">
                                    <h4>{ item.title }</h4>
                                    <p>{ item.content }</p>
                                </div>
                            </a>
                        </div>

                    )}

                </div>
            </div>
        </div>
    );
};

export default IntoSection;