import React, {Fragment, useEffect} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import MobileVerificationCard from "./MobileVerificationCard";
import {useHistory} from "react-router-dom";
import ReactGA from "react-ga";

const MobileVerification = (props) => {
    const history = useHistory();
    useEffect(()=>{
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Mobile Verification | Global Agent Network";


    },[]);
    console.log(props,"ddd");
    return (
        <Fragment>
            <Navbar/>
            <PageHeader headertitle={"Mobile Verification"}/>
            <div className="register-page-area pd-bottom-50">
                <div className="container">
                    <div className="row justify-content-center">
                        <MobileVerificationCard history={history} isAuth={props.location.pathname ==="/mobile-verification"} />
                    </div>
                </div>
            </div>

            <Footer/>
        </Fragment>
    );
};

export default MobileVerification;