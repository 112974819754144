import React, {useEffect} from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import TermsConditions from '../section-components/terms-conditions';
import Footer from '../global-components/footer';
import ReactGA from "react-ga";

const Terms = () => {
    window.scrollTo(0, 0)
    useEffect(()=>{
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Terms of Service | Global Agent Network";


    },[]);
    return <div>
        <Navbar />
        <PageHeader headertitle="Terms of Service" />
       <TermsConditions/>
        <Footer />
    </div>
}

export default Terms;