import React, {Component, Fragment} from 'react';
import PropertyImageCard from "./property-image-card";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import defaultAvatar from "../../assets/img/no-property-image.svg";
import defaultAvatar1 from "../../assets/img/company-logo-placeholder.png";

import connect from "react-redux/es/connect/connect";
import {
    actionGetPropertyImages,
    actionDeletePropertyImage,
    actionAddMultiplePropertyImage
} from "../../actions/propertyAction";
import Spinner from "../section-components/Spinner";
import btnLoader from "../../assets/img/button-loader.svg";
import {SRLWrapper} from "simple-react-lightbox";
import SimpleReactLightbox from 'simple-react-lightbox'
import styled from 'styled-components';





class PropertyImageList extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
        this.state = {
            propertyImgLoader: false,
            src: null,
            crop: {

                unit: 'px',
                width: 100,
                height:100,
                aspect: '',

            },
            uploadedImagePreview: '',
        }
    }


    componentDidMount() {
        console.log('pid', this.props.propertyId);
        this.props.propertyId && this.props.actionGetPropertyImages(this.props.propertyId);
    }


    /*  Image Crop */
    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({src: reader.result})
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };


    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({crop});
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName) {
        // const canvas = document.createElement('canvas');
        // const scaleX = image.naturalWidth / image.width;
        // const scaleY = image.naturalHeight / image.height;
        // canvas.width = crop.width;
        // canvas.height = crop.height;
        // const ctx = canvas.getContext('2d');
        //
        // ctx.drawImage(
        //     image,
        //     crop.x * scaleX,
        //     crop.y * scaleY,
        //     crop.width * scaleX,
        //     crop.height * scaleY,
        //     0,
        //     0,
        //     crop.width,
        //     crop.height

        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        var originWidth = crop.width * scaleX;
        var originHeight = crop.height * scaleY;
        // maximum width/height
        var maxWidth = 1200, maxHeight = 1200 / (16 / 8);
        var targetWidth = originWidth,
            targetHeight = originHeight;
        if (originWidth > maxWidth || originHeight > maxHeight) {
            if (originWidth / originHeight > maxWidth / maxHeight) {
                targetWidth = maxWidth;
                targetHeight = Math.round(maxWidth * (originHeight / originWidth));
            } else {
                targetHeight = maxHeight;
                targetWidth = Math.round(maxHeight * (originWidth / originHeight));
            }
        }
        // set canvas size
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            targetWidth,
            targetHeight
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
                this.setState({blob}) //added by SO huy nguyen
            }, 'image/jpeg');
        });
    }

    handleProfileChange = async (e) => {
        const config = {responseType: 'blob'};
        /*let file = axios.get(this.state.croppedImageUrl, config).then(response => {
            new File([response.data], 'my_image.png', {type: "image/jpg", lastModified: new Date()});
        });*/
        var file = new File([this.state.croppedImageUrl], "property_image.png", {
            type: "image/png",
            lastModified: new Date()
        });
        let formData = new FormData();
        formData.append('agent_id', this.props.user.user_id);
        formData.append('property_id', parseInt(this.props.propertyId));
        formData.append('property_img', this.state.blob, () => {
            document.getElementById('property-pic-selector').value = this.state.blob
        });
        // formData.append('remove_profile', 0);
        this.setState({propertyImgLoader: true}, document.getElementById('close-propertyImageUpload') && document.getElementById('close-propertyImageUpload').click());
        try {
            await this.props.actionAddMultiplePropertyImage(formData);
            console.log(formData);
            this.setState({
                propertyImgLoader: false,
                src: null,
                crop: {
                    unit: 'px',
                    width: 100,
                    height:100,
                    aspect: '',
                },
                blob: ''
            }, () => document.getElementById('property-pic-selector').value = '');
            console.log(this.state);
        }
        catch (error) {
            this.setState({propertyImgLoader: false});
            console.error({"error setting property pic": error});
        }
    };


    /*  Image Crop Ends */



    render() {
        console.log(encodeURIComponent(window.location.pathname))
        const options = {
            buttons: {
                backgroundColor: 'rgba(30,30,36,0.8)',
                iconColor: 'rgba(255, 255, 255, 0.8)',
                iconPadding: '10px',
                showAutoplayButton: false,
                showCloseButton: true,
                showDownloadButton: false,
                showFullscreenButton: true,
                showNextButton: true,
                showPrevButton: true,
                showThumbnailsButton: true,
                size: '40px'
            }
        }
        const {getPropertyImages} = this.props;
        const {src, crop,propertyImgLoader} = this.state;
console.log(getPropertyImages)
        return (
            <div className="container mt-5">
                <div className="propety-img-section">
                    <div className="row">

                        {getPropertyImages.length === 0 &&
                            <div className=" mx-auto mt-3">
                                <img src={defaultAvatar} width={'200px'} alt=""/>
                                <h5 style={{color:'#fda94f'}}>Sorry... Images not found. <br/>Click Here! to add new images

                                </h5>
                            </div>

                        }

                        {propertyImgLoader && <div className="text-center col-md-12"><Spinner width={150}/></div>}

                        {getPropertyImages.length > 0 && getPropertyImages.map((val, i) =>

                        <PropertyImageCard actionDeletePropertyImage={this.props.actionDeletePropertyImage}
                                               img={val.image} key={i} i={i} id={val.property_image_id}/>

                        )
                        }


                    </div>
                    {/*<SimpleReactLightbox >*/}
                    {/*<SRLWrapper options={options}>*/}
                        {/*{getPropertyImages.length > 0 && getPropertyImages.map((val, i) =>*/}
                            {/*<div className="col-image-small1">*/}
                            {/*<img src={val.image} alt=""  srl_gallery_image="true" />*/}
                            {/*</div>*/}
                        {/*)*/}
                        {/*}*/}
                    {/*</SRLWrapper>*/}
                    {/*</SimpleReactLightbox>*/}

                    <div className="text-center mt-3">
                        {propertyImgLoader ?
                            <button
                                className="btn btn-yellow"
                                type="button">
                                <img src={btnLoader} alt="add-image-loader"
                                     width={15}/>
                            </button> :
                            <div data-toggle="modal"
                                 data-target="#propertyImageUpload"
                                 className="btn btn-yellow">Add Image
                            </div>}

                    </div>
                    <Fragment>
                        <div className="modal fade" id="propertyImageUpload" role="dialog" data-backdrop="static"
                             data-keyboard="false"
                             aria-labelledby="propertyImageUploadTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLongTitle">Add Property Image</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body text-center">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                {src ? (
                                                    <ReactCrop
                                                        src={src}
                                                        crop={crop}
                                                        ruleOfThirds
                                                        onImageLoaded={this.onImageLoaded}
                                                        onComplete={this.onCropComplete}
                                                        onChange={this.onCropChange}
                                                        className={'profile-preview-image'}
                                                    />
                                                ) : <img width={250}
                                                         src={defaultAvatar1}
                                                         alt="Profile Picture"/>}
                                            </div>
                                        </div>
                                        <hr/>
                                        <input id={'property-pic-selector'} type="file" accept="image/*"
                                               onChange={this.onSelectFile}/>

                                    </div>
                                    <div className="modal-footer">
                                        <button id={'close-propertyImageUpload'} type="button"
                                                className="btn btn-custom-default" data-dismiss="modal">Close
                                        </button>
                                        <button className="btn btn-yellow" onClick={this.handleProfileChange}>Save
                                            changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        getPropertyImagesLoader: state.property.getPropertyImagesLoader,
        getPropertyImages: state.property.getPropertyImages,
        propertyData: state.property.propertyData,
    };

};

export default connect(mapStateToProps, {
    actionGetPropertyImages,
    actionDeletePropertyImage,
    actionAddMultiplePropertyImage
})(PropertyImageList)
