import React, {Component,Fragment} from 'react'
import n21 from '../../assets/img/nophoto.png';
import i1 from '../../assets/img/icons/1.png';
import builderavtar from '../../assets/img/icons/5.png';
import PropertyListDetailsCard from "../property-listing/property_list_details_card";
import connect from "react-redux/es/connect/connect";
import {actionGetPropertyDetails, actionGetPropertyImages, actionPropertyViewSend} from "../../actions/propertyAction";
import {IMAGE_BASE_URL} from "../../web-config";
import PrevArrow from "../common/PrevArrow";
import NextArrow from "../common/NextArrow";
import Slider from "react-slick/lib";
import {Link} from "react-router-dom";
import Spinner from "./Spinner";
import serversideIssue from "../../assets/img/undraw_bug_fixing_oc7a.svg";
import PropertyDetailsLoaderCard from "../property-details/property-details-loader-card";
import jwt_decode from "jwt-decode";
import {actionSharePropertyUrl} from "../../actions/chatAction";
import ImageLightboxSlider from "../property-details/image-lightbox-slider";


class PropertyDetailsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }


    componentDidMount() {
        this.props.actionGetPropertyDetails(`property_id=${this.props.propertyId}`);
        /*this.props.actionGetPropertyImages(this.props.propertyId);*/

        const script = document.createElement("script");
        script.src = "../../assets/js/vendore.js";
        script.async = true;
        document.body.appendChild(script);

         setTimeout(()=>{
            const request={
                user_id:this.props.user ? this.props.user.user_id:null,
                property_id:this.props.propertyId,
                agent_id:4212,

            };
              console.log(request)
            this.props.actionPropertyViewSend(request)
        },10000)

    }

    onContactAgent =(propertyId)=>{
console.log("sshare url ...",propertyId);
        this.props.actionSharePropertyUrl(window.location.href);

        
        


    };

    onCloseImage=(d)=>{
        this.setState({isOpen:d})
    };

    render() {

        const {  isOpen } = this.state;

        const data = this.props.getPropertyDetails;
        const {title, address, country, state, city, list_date, description, disclaimer, bathrooms, bedrooms, super_area, carpet_area, land_area, booking_amount, car_parking, status, transaction_type, rera_id, furnished_status, flooring, age_of_property, floor_availability, units_on_floor, no_of_cabins, no_of_conf_room, pantry, fire_equipment, leed_certificate, authority_approval, boundary_wall, type_of_ownership, overlooking, no_of_open_sides, corner_plot, floor_allowed_for_construction, landmarks, facing, gated_colony, name_of_industrial_estate, amenities, area_unit_name, flat_type, builder_name, owner_name,home_size, image,agent_id,property_link} = data;
        const settings = {
            loop: true,
            autoplay: false,
            dots: false,
            slidesToScroll: 1,
            speed: 400,
            slidesToShow: 1,
            items: 1,
            prevArrow: <PrevArrow/>,
            nextArrow: <NextArrow/>,

        };
        const {getPropertyDetailsLoader}=this.props;
        const user_id= this.props.user && this.props.user.user_id;
        const d1=data.price&&data.price.replace(/[^0-9.-]/g, '')
        console.log((d1 >= 10000000)?( d1/ 10000000).toFixed(2) + ' Cr':(d1 / 100000).toFixed(2) + ' Lac' )

        const decoded = localStorage.jwtToken ?jwt_decode(localStorage.jwtToken):'';

        const imgData=[];
        imgData.push(data.property_images&&data.property_images.map(val=>val.image));
        console.log(imgData);



        return <div className="property-details-area bg-gray " style={{BackgroundColor: '#F6F7FB'}}>
            {/*{getPropertyDetailsLoader && <div className="row">*/}
                {/*<div className="text-center col-md-12 mt-5"><Spinner width={150}/></div>*/}
            {/*</div>}*/}
            {getPropertyDetailsLoader && <div className="row">
                <div className="text-center col-12 my-5"><PropertyDetailsLoaderCard/></div>
            </div>}

            {(data && !data.length >0 )&&
            <Fragment>
            <div className="pd-top-80">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-8 col-lg-8">



                            <div className="item">
                                <div className="thumb">
                                    <img
                                        className="w-100"
                                        src={image?image:''}
                                        alt={'propertyImage Default'}/>

                                    {(data.property_images && data.property_images.length ) ? <a className='more-img-link text-light font-weight-bold'  onClick={() => this.setState({ isOpen: true })}>
                                        {data.property_images.length }+ Images...
                                    </a>:''
                                    }
                                </div>

                            </div>


                            {/*<Slider {...settings} className={'property-details-slider-vytech'}>*/}
                                {/*{(data.property_images && data.property_images.length < 1) &&*/}
                                {/*<div className="item">*/}
                                    {/*<div className="thumb">*/}
                                        {/*<img*/}
                                            {/*className="w-100"*/}
                                            {/*src={`${IMAGE_BASE_URL}uploads/${image}` ? image : `${IMAGE_BASE_URL}uploads/${image}`}*/}
                                            {/*alt={'propertyImage Default'}/>*/}
                                    {/*</div>*/}
                                {/*</div>}*/}
                                {/*{(data.property_images && data.property_images.length > 0) && data.property_images.map((val, i) =>*/}
                                    {/*<div className="item" key={i}>*/}
                                        {/*<div className="thumb">*/}
                                            {/*<img className="w-100" src={`${IMAGE_BASE_URL}uploads/${val.image}`}*/}
                                                 {/*alt={'Proprty Multiple Images'}/>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*)*/}
                                {/*}*/}
                            {/*</Slider>*/}
                            <div className="property-details-slider-info d-none d-lg-block">
                                <h3 className="m-0 p-1">
                                    <span>{data.currency === 'INR' && <i className={'fa fa-inr'}/>}
                                    {data.currency === 'USD' && <i className={'fa fa-dollar'}/>}
                                    {data.price ?
                                        (d1 >= 10000000)?( d1/ 10000000).toFixed(2) + ' Cr':(d1>=100000)?(d1 / 100000).toFixed(2) + ' Lac' : data.price:'0'}</span> <br/> <strong>{title} </strong>  <span className="text-primary">{flat_type} {carpet_area && `${carpet_area} ${area_unit_name}`} {super_area && `${super_area} ${area_unit_name}`} {land_area && `${land_area}${area_unit_name}`}</span>
                                </h3>
                                <br />
                                <div className="media">
                                    <div className="media-left ">
                                        <img src={i1} alt={''} width={'15px'}/>
                                    </div>
                                    <div className="media-body ml-2">
                                        <h5 style={{fontSize: '1em'}}>{address} {country && `, ${country}`} {state && `, ${state}`} {city && `, ${city}`} </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="property-details-slider-info d-lg-none">
                                <h3 className="m-0 p-1">
                                    <span>{data.currency === 'INR' && <i className={'fa fa-inr'}/>}
                                    {data.currency === 'USD' && <i className={'fa fa-dollar'}/>}
                                        {data.price ? data.price : '0'}</span> <br /> <strong>{title} </strong> <span className="text-primary"> {flat_type}  {carpet_area && `${carpet_area} ${area_unit_name}`} {super_area && `${super_area} ${area_unit_name}`} {land_area && `${land_area}${area_unit_name}`}</span>
                                </h3>
                                <br />
                                <div className="media">
                                    <div className="media-left ">
                                        <img src={i1} alt={''} width={'15px'}/>
                                    </div>
                                    <div className="media-body ml-2">
                                        <h5 style={{fontSize: '1em'}}>{address} {country && `, ${country}`} {state && `, ${state}`} {city && `, ${city}`} </h5>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-xl-4 col-lg-4">
                            <div className="widget widget-owner-info mt-lg-0 mt-5">
                                <div className="owner-info text-center">
                                    <div className="thumb">
                                        <img src={data.agent_profile_pic ? data.agent_profile_pic : n21} alt={''}
                                             height={'72px'} width={'72px'}/>
                                    </div>
                                    <div className="details">
                                        <h6>{(data.agent_first_name || data.agent_last_name ) && `${data.agent_first_name} ${data.agent_last_name}`}</h6>
                                        <span className="designation font-weight-bold h4"><i
                                            className="fa fa-user-secret" style={{color: '#fda94f', fontSize: '20px'}}
                                        />   Agent</span>
                                        {/*<p className="reviews"><i className="fa fa-star"/><span>4.8</span> 70 Review</p>*/}
                                    </div>
                                </div>
                                <div className="contact text-center">
                                    {(parseInt(decoded.uid)  !== parseInt(agent_id) ) && <Link className="btn btn-yellow" to={(this.props.isAuthenticated) ?`/private-chat/${data.agent_id}`:'/register'} onClick={this.onContactAgent}>Contact Agent</Link>}

                                </div>
                                <div className="contact-info">
                                    <h6 className="mb-3">Contact Info</h6>
                                    <div className="media">
                                        <div className="media-left">
                                            <img src={i1} alt={''}/>
                                        </div>
                                        <div className="media-body">
                                            <p>Address</p>
                                            <span>{data.agent_city} {data.agent_state && `, ${data.agent_state}`} {data.agent_country && `, ${data.agent_country}`}</span>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <div className="media-left">
                                            <i className="fa fa-phone"/>
                                        </div>
                                        <div className="media-body">
                                            <p>Phone</p>
                                            <span>{data.agent_phone ? data.agent_phone : '+00 111 222 333'}</span>
                                        </div>
                                    </div>
                                    <div className="media mb-0">
                                        <div className="media-left">
                                            <i className="fa fa-envelope"/>
                                        </div>
                                        <div className="media-body">
                                            <p>Email</p>
                                            <span>{data.agent_email ? data.agent_email : 'info@example.com'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isOpen &&<ImageLightboxSlider images={imgData} onCloseImage={this.onCloseImage}/>}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row pd-top-60">
                    <div className="col-lg-9">
                        {description && <div className="property-news-single-card style-two border-bottom-yellow">
                            <h4>Description</h4>
                            <p className="h6 desc-text">{description.split('\n').map(str => <p>{str}</p>)}</p>
                        </div>}
                        {builder_name && <div className="property-news-single-card style-two border-bottom-yellow">
                            <h4>Builder Name</h4>
                            <div className='d-flex '>
                                <img src={builderavtar} alt="Builder Avtar " className="mr-1"/>
                                <p className="my-auto h6 font-weight-bold ml-1">{builder_name}</p>
                            </div>

                        </div>}
                        {owner_name && <div className="property-news-single-card style-two border-bottom-yellow">
                            <h4>Owner Name</h4>
                            <div className='d-flex '>
                                <img src={builderavtar} alt="Builder Avtar" className="mr-1"/>
                                <p className="my-auto h5 font-weight-bold ml-1">{owner_name}</p>
                            </div>

                        </div>}
                        <div className="property-info mb-5">
                            <div className="row">


                                {list_date && <PropertyListDetailsCard title={'List Date'} icon={'fa fa-calendar'}
                                                                       data={list_date ? new Date(list_date).toLocaleDateString() : '00-00-0000'}/>}

                                {
                                    bedrooms && <PropertyListDetailsCard title={'Bedrooms'} icon={'fa fa-bed'}
                                                                         data={bedrooms ? bedrooms : '0'}/>
                                }

                                {
                                    bathrooms && <PropertyListDetailsCard title={'Bathrooms'} icon={'fa fa-bath'}
                                                                          data={bathrooms ? bathrooms : ''}/>
                                }

                                {
                                    super_area && <PropertyListDetailsCard title={'Super Area'} icon={'fa fa-codepen'}
                                                                           data={`${super_area} ${area_unit_name}` ? `${super_area} ${area_unit_name}` : `0 sq. ft.`}/>
                                }

                                {
                                    carpet_area && <PropertyListDetailsCard title={'Carpet Area'} icon={'fa fa-codepen'}
                                                                            data={`${carpet_area} ${area_unit_name && area_unit_name}` ? `${carpet_area} ${area_unit_name && area_unit_name}` : `0 sq. ft.`}/>
                                }

                                {
                                    land_area && <PropertyListDetailsCard title={'Area'} icon={'fa fa-codepen'}
                                                                          data={`${land_area}` ? `${land_area} ${area_unit_name}` : '0 sq. ft.'}/>
                                }
                                {
                                    home_size && <PropertyListDetailsCard title={'Home Size'} icon={'fa fa-arrows-alt'}
                                                                          data={`${home_size}` ? `${home_size} (Sq Feet)` : '0 sq. ft.'}/>
                                }

                                {
                                    car_parking && <PropertyListDetailsCard title={'Parking'} icon={'fa fa-car'}
                                                                            data={car_parking ? car_parking : '0'}/>
                                }
                                {
                                    booking_amount &&
                                    <PropertyListDetailsCard title={'Booking Amount'} icon={'fa fa-money'}
                                                             data={booking_amount ? booking_amount : '0'}/>
                                }

                                {
                                    status && <PropertyListDetailsCard title={'Status'} icon={'fa fa-ban'}
                                                                       data={status ? status : ''}/>
                                }

                                {
                                    transaction_type &&
                                    <PropertyListDetailsCard title={'Transaction Type'} icon={'fa fa-recycle'}
                                                             data={transaction_type ? transaction_type : ''}/>
                                }

                                {
                                    rera_id && <PropertyListDetailsCard title={'RERA ID'} icon={'fa fa-home'}
                                                                        data={rera_id ? rera_id : ''}/>
                                }

                                {
                                    furnished_status &&
                                    <PropertyListDetailsCard title={'Furnished Status'} icon={'fa fa-inbox'}
                                                             data={furnished_status ? furnished_status : ''}/>
                                }

                                {
                                    flooring && <PropertyListDetailsCard title={'Flooring'} icon={'fa fa-clone'}
                                                                         data={flooring ? flooring : ''}/>
                                }

                                {
                                    age_of_property &&
                                    <PropertyListDetailsCard title={'Age of Property'} icon={'fa fa-calendar-check-o'}
                                                             data={age_of_property ? age_of_property : ''}/>
                                }

                                {
                                    floor_availability &&
                                    <PropertyListDetailsCard title={'Floor availability'} icon={'fa fa-building'}
                                                             data={floor_availability ? floor_availability : ''}/>
                                }

                                {
                                    units_on_floor &&
                                    <PropertyListDetailsCard title={'Units on Floor'} icon={'fa fa-building-o'}
                                                             data={units_on_floor ? units_on_floor : ''}/>
                                }

                                {
                                    no_of_cabins &&
                                    <PropertyListDetailsCard title={'Number Of Cabins'} icon={'fa fa-square-o'}
                                                             data={no_of_cabins ? no_of_cabins : ''}/>
                                }

                                {
                                    no_of_conf_room && <PropertyListDetailsCard title={'Number Of Conference rooms'}
                                                                                icon={'fa fa-bullhorn'}
                                                                                data={no_of_conf_room ? no_of_conf_room : ''}/>
                                }

                                {
                                    pantry && <PropertyListDetailsCard title={'Pantry'} icon={'fa fa-hdd-o'}
                                                                       data={pantry ? pantry : ''}/>
                                }

                                {
                                    fire_equipment && <PropertyListDetailsCard title={'Fire Fighting Equipment'}
                                                                               icon={'fa fa-fire-extinguisher'}
                                                                               data={fire_equipment ? fire_equipment : ''}/>
                                }

                                {
                                    leed_certificate &&
                                    <PropertyListDetailsCard title={'Leed Certificate'} icon={'fa fa-list-alt'}
                                                             data={leed_certificate ? leed_certificate : ''}/>
                                }

                                {
                                    authority_approval &&
                                    <PropertyListDetailsCard title={'Authority Approval'} icon={'fa fa-check-square'}
                                                             data={authority_approval ? authority_approval : ''}/>
                                }
                                {
                                    boundary_wall &&
                                    <PropertyListDetailsCard title={'Boundary wall'} icon={'fa fa-external-link'}
                                                             data={boundary_wall ? boundary_wall : ''}/>
                                }

                                {
                                    type_of_ownership &&
                                    <PropertyListDetailsCard title={'Type of Ownership'} icon={'fa fa-users'}
                                                             data={type_of_ownership ? type_of_ownership : ''}/>
                                }

                                {
                                    overlooking &&
                                    <PropertyListDetailsCard title={'Overlooking'} icon={'fa fa-snowflake-o'}
                                                             data={overlooking ? overlooking : ''}/>
                                }

                                {
                                    no_of_open_sides && <PropertyListDetailsCard title={'Number Of Open Sides'}
                                                                                 icon={'fa fa-window-restore'}
                                                                                 data={no_of_open_sides ? no_of_open_sides : ''}/>
                                }

                                {
                                    corner_plot && <PropertyListDetailsCard title={'Corner Plot'} icon={'fa fa-columns'}
                                                                            data={corner_plot ? corner_plot : ''}/>
                                }
                                {
                                    floor_allowed_for_construction &&
                                    <PropertyListDetailsCard title={'Floor allowed for Construction'}
                                                             icon={'fa fa-cogs'}
                                                             data={floor_allowed_for_construction ? floor_allowed_for_construction : ''}/>
                                }

                                {
                                    landmarks &&
                                    <PropertyListDetailsCard title={'Landmark'} icon={'fa fa-location-arrow'}
                                                             data={landmarks ? landmarks : ''}/>
                                }

                                {
                                    facing && <PropertyListDetailsCard title={'Facing'} icon={'fa fa-compass'}
                                                                       data={facing ? facing : ''}/>
                                }

                                {
                                    gated_colony &&
                                    <PropertyListDetailsCard title={'Gated Colony'} icon={'fa fa-hashtag'}
                                                             data={gated_colony ? gated_colony : ''}/>
                                }

                                {
                                    name_of_industrial_estate &&
                                    <PropertyListDetailsCard title={'Name Of Industrial Estate'} icon={'fa fa-industry'}
                                                             data={name_of_industrial_estate ? name_of_industrial_estate : ''}/>
                                }


                            </div>


                        </div>


                        {disclaimer && <div className="property-news-single-card style-two border-bottom-yellow">
                            <h4>Disclaimer</h4>
                            <p className="h6">{disclaimer}</p>

                        </div>}

                        <div className="property-news-single-card ">
                            {amenities && <h4>Amenities</h4>}
                            <div className="">

                                <ul className="rld-list-style mb-3 mb-sm-0  row">
                                    {
                                        amenities && amenities.length > 0 && amenities.map((val, i) => <li key={i}
                                                                                                           className={'col-md-6 col-lg-6 col-xl-6 col-sm-12   show-ami pl-sm-2 p-md-0'}>
                                            <img src={val.image}/>{val.name}</li>)
                                    }

                                </ul>

                            </div>
                        </div>

                        {property_link && <div className="property-news-single-card style-two border-bottom-yellow">
                            <h4>Link</h4>
                            <p className="h5"><a href={property_link} target="_blank">Visit the Property Website <i
                                className="fa fa-external-link" aria-hidden="true"/></a> </p>

                        </div>}

                    </div>
                </div>
            </div>
            </Fragment>
            }

            {/*{(!getPropertyDetailsLoader && data.length >0) &&*/}
            {/*<div className=" mx-auto mt-3">*/}
                {/*<img src={serversideIssue} width={'200px'} alt=""/>*/}
                {/*<h5 style={{color: '#fda94f'}}>Sorry... Images not found. <br/>Click Here! to add new images*/}

                {/*</h5>*/}
            {/*</div>*/}
            {/*}*/}

        </div>


    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated,
        getPropertyImagesLoader: state.property.getPropertyImagesLoader,
        getPropertyImages: state.property.getPropertyImages,
        getPropertyDetails: state.property.getPropertyDetails,
        getPropertyDetailsLoader: state.property.getPropertyDetailsLoader,
        sharePropertyUrlLoader: state.chat.sharePropertyUrlLoader,
        sharePropertyUrl: state.chat.sharePropertyUrl,
    };
};
export default connect(mapStateToProps, {
    actionGetPropertyDetails,
    actionGetPropertyImages,
    actionSharePropertyUrl,
    actionPropertyViewSend
})(PropertyDetailsSection);


