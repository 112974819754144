import React, {Component,Fragment} from 'react';
import sectiondata from '../../data/sections.json';
import {Link} from 'react-router-dom';
import Spinner from "./Spinner";
import connect from "react-redux/es/connect/connect";
import {
    actionGetFeatureProperty, actionGetPremiumFeatureProperty,
    actionGetUnits
} from "../../actions/propertyAction";
import DataNotFound from "./DataNotFound";
import FeatureProperyListCard from "../property-listing/feature-propery-list-card";
import companyPlaceholder from '../../assets/img/company-logo-placeholder.png';
import defaultAvatar from "../../assets/img/nophoto.png";
import PropertyListLoaderCard from "../property-listing/property-list-loader-card";
import PremiumFeaturedPropertyLoaderCard from "../property-listing/premium-featured-property-loader-card";
import btnLoader from "../../assets/img/button-loader.svg";
import BroadcastUserListLoaderCard from "../common/broadcast-user-list-loader-card";
import BroadcastModelChatUser from "../common/broadcast-model-chat-user";
import broadcastIcon from "../../assets/img/broadcast_icon.png";
import {actionGetChatList, actionLiveSharePropertyUrl, actionSharePropertyUrl} from "../../actions/chatAction";
import {actionGetAgent} from "../../actions/agentAction";
import PropertyListCard from "../property-listing/property-list-card";
import jwt_decode from "jwt-decode";

class Featured extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chatPerson: '',
            chatFilter: '',
            broadcastSearchFilter: '',
            broadcast_members: [],
            broadcast_members_index: [],
            propertyId: '',
        }
    }

    componentDidMount() {
        this.props.actionGetFeatureProperty(1);
        this.props.actionGetPremiumFeatureProperty(1);
        if (this.props.user.user_id){
            this.props.actionGetChatList(this.props.user.user_id);
        }


    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user.user_id !== this.props.user.user_id) {
            this.props.actionGetChatList(this.props.user.user_id);
        }
        prevProps.chatAgent !== this.props.chatAgent && this.setState({chatPerson: this.props.chatAgent})
    }

    onChangeBroadcastFilter = (e) => this.setState({[e.target.name]: e.target.value});

    handleSelectBroadcastPerson = (personIndex) => {
        this.setState({broadcast_members_index: this.state.broadcast_members_index.find(m => parseInt(m) === parseInt(personIndex)) ? [...this.state.broadcast_members_index.filter(m => parseInt(personIndex) !== parseInt(m))] : [...this.state.broadcast_members_index, personIndex]});

    };
    handleSelectPropertyCard = (propertyId) => {
console.log(propertyId);
        if (this.props.isAuthenticated){

            this.setState({propertyId})
            console.log(propertyId);
        }
        else {
            document.getElementById('closeBtn').click();
            window.location.pathname='/register';

        }

    };


    onShareProperty = (e) => {
        e.preventDefault();

        const selectedUsers = this.state.broadcast_members_index && this.state.broadcast_members_index.map(userIndex => this.props.chatPersonList.find((person, i) => parseInt(userIndex-1) === parseInt(i)));

        this.setState({broadcast_members: selectedUsers.map(val => [val.user_id])});
        this.props.actionSharePropertyUrl(`https://globalindiarealestate.com/property-detail/${this.state.propertyId}`);


        setTimeout(() => {
            if (localStorage.jwtToken) {
                const decoded = jwt_decode(localStorage.jwtToken);
                const request = {
                    sender_id: parseInt(decoded.uid),
                    payload: selectedUsers.map(user => ({
                        user_id: user.user_id,
                        type: user.chat_type,
                        message: this.props.sharePropertyUrl,
                    }))
                };
                this.props.actionLiveSharePropertyUrl(request);
            }

        }, 600);

        this.setState({broadcast_members_index:[],
            broadcast_members:''})

    };


    render() {
        const userId= localStorage.jwtToken?jwt_decode(localStorage.jwtToken).uid:null;
        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'
        let data = sectiondata.featuredproperties
        let Customclass = this.props.Customclass ? this.props.Customclass : 'pd-top-60'


        const {getFeaturedPropertyLoader, getFeaturedProperty, chatPersonList,getPremiumFeaturedPropertyLoader,getPremiumFeaturedProperty,createBroadcastLoader} = this.props;

        const {chatPerson} = this.state;




        let filteredBroadcastPersonList = !this.state.broadcastSearchFilter ? chatPersonList : chatPersonList.filter(a => {
            return (a.first_name && a.first_name.toLowerCase().indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1) || (a.last_name && a.last_name.toLowerCase().indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1) || (a.email && a.email.toLowerCase().indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1);
        });

        const serachlStle = {
            position: 'absolute',
            right: '35px',
            top: '14px',

        };
        const serachiStle = {
            width: '100%',
            // height:'37px',
            lineHeight: '36px',
            padding: '1px 20px',
            fontSize: '15px',
            border: '1px solid #d4d4d4'

        };

        const d1=getPremiumFeaturedProperty.price&&getPremiumFeaturedProperty.price.replace(/[^0-9.-]/g, '')
        return<Fragment>
        <div className={"featured-area-section  " + Customclass}>
            <div className="container">
                <div className="section-title text-center">
                    <h2 className="title">{data.title}</h2>
                </div>
                <div className="row justify-content-center">

                    <div className="col-xl-6 col-lg-8">
                        <div className="single-leading-feature">
                            {getPremiumFeaturedPropertyLoader && <PremiumFeaturedPropertyLoaderCard/>}

                            {!getPremiumFeaturedPropertyLoader && <div>
                            <img src={getPremiumFeaturedProperty.agent_profile_pic?getPremiumFeaturedProperty.agent_profile_pic:defaultAvatar}  className="featured-agent-profile-pic"/>
                            <div className="slf-overlay"/>
                            <div className="thumb">
                                <img src={getPremiumFeaturedProperty.image?getPremiumFeaturedProperty.image:companyPlaceholder} alt={getPremiumFeaturedProperty.title} width={'100%'}/>
                            </div>
                            <div className="details">
                                <h4 className="title readeal-top"><Link
                                    to={`/property-detail/${getPremiumFeaturedProperty.property_id}`}>{getPremiumFeaturedProperty.title ? getPremiumFeaturedProperty.title:'We are open for premium featured property'}</Link></h4>
                                <h5 className="price">{getPremiumFeaturedProperty.currency === 'USD' && <i className="fa fa-dollar"/>}
                                    {getPremiumFeaturedProperty.currency === 'INR' && <i className="fa fa-inr"/>}
                                    {getPremiumFeaturedProperty.price?(d1 >= 10000000)?( d1/ 10000000).toFixed(2) + ' Cr':(d1>=100000)?(d1 / 100000).toFixed(2) + ' Lac':getPremiumFeaturedProperty.price:''}</h5>
                                <span>{getPremiumFeaturedProperty.carpet_area ? getPremiumFeaturedProperty.carpet_area :getPremiumFeaturedProperty.land_area} {getPremiumFeaturedProperty.name} {getPremiumFeaturedProperty.city_id} {getPremiumFeaturedProperty.state_id} {getPremiumFeaturedProperty.country_id}  </span>
                            </div>
                            </div>
                            }
                        </div>
                    </div>
                    <hr/>
                    {/*{getFeaturedPropertyLoader && <Spinner width={100}/>}*/}
                    {getFeaturedPropertyLoader &&
                    <PropertyListLoaderCard/>}

                    {getFeaturedPropertyLoader &&
                    <PropertyListLoaderCard/>}
                    {getFeaturedPropertyLoader &&
                    <PropertyListLoaderCard/>}
                    {getFeaturedPropertyLoader &&
                    <PropertyListLoaderCard/>}


                    {(!getFeaturedPropertyLoader && getFeaturedProperty.length > 0) && getFeaturedProperty.map((item, i) =>
                        <FeatureProperyListCard
                            imagealt={item.title}
                            carpet_area={item.carpet_area}
                            land_area={item.land_area}
                            super_area={item.super_area}
                            flat_type={item.flat_type}
                            auth={this.props.isAuthenticated}
                            image={item.image}
                            logo={item.agent_profile_pic}
                            agentName={`${item.agent_first_name} ${item.agent_last_name}`}
                            title={item.title}
                            address={item.address}
                            // url={`/property-detail/${item.property_id}`}
                            url={`/property-detail/${item.property_id}/${item.title?`${item.title.replace(/\\|\//g, '-')}-`:''}${item.address.replace(/\s+/g, '-')}`}
                            propertyId={item.property_id}
                            newerprice={item.price}
                            beds={item.bedroom}
                            // area={item.super_area ? item.super_area : item.carpet_area}
                            area_unit_name={item.area_unit_name ? item.area_unit_name : item.area_unit_name}
                            key={i}
                            index={i}
                            currency={item.currency}
                            img={publicUrl + item.features}
                            sub_category_name={item.sub_category_name}
                            userAuth={userId !== item.agent_id}
                            handleSelectPropertyCard={this.handleSelectPropertyCard}
                        />)}
                    {(!getFeaturedPropertyLoader && getFeaturedProperty.length < 1) &&
                    <div className="text-center col-md-12"><DataNotFound
                        message={`Sorry! No any Feature Property found.`}/></div>}


                </div>
            </div>
            <Fragment>
                {/* Create Broadcast Modal */}

                <div className="modal fade share-property-modal" id="createBroadcastModal" tabIndex={-1}
                     role="dialog"
                     aria-labelledby="createBroadcastModalLabel" aria-hidden="true" style={{marginTop: "2em"}}>
                    <div className="modal-dialog modal-dialog-centered" role="document">

                        <div className="modal-content" style={{zIndex: '99999999999'}}>

                            <div className="modal-header ">
                                <h5 className="modal-title w-100 text-center" id="createBroadcastModalLabel ">Share
                                    this property..</h5>
                                <button type="button" id={"closeBtn"} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true h3">×</span>
                                </button>
                            </div>
                            <div className="modal-body pt-0">
                                <Fragment>
                                    <div id="sidepanel" className="p-2">

                                        <div id="search" className="mb-2">
                                            <label htmlFor="" style={serachlStle}><i className="fa fa-search"
                                                                                     aria-hidden="true"/></label>
                                            <input type="text" style={serachiStle} name="broadcastSearchFilter"
                                                   onChange={this.onChangeBroadcastFilter}
                                                   value={this.state.broadcastSearchFilter}
                                                   placeholder="Search Friend"/>
                                        </div>


                                        {this.state.broadcast_members_index.length > 0 &&
                                        <div
                                            className="d-flex align-items-center justify-content-between pl-2 pr-2">
                                            {this.state.broadcast_members_index.length > 0 &&
                                            <div>{this.state.broadcast_members_index.length} Person
                                                Selected.</div>}
                                            {createBroadcastLoader ?
                                                <div className="broadcast-next-btn"><img src={btnLoader}
                                                                                         alt="contact-loader"
                                                                                         width={15}/>
                                                </div>
                                                :
                                                <div className="btn btn-yellow h4" id="clg" data-dismiss="modal"
                                                     onClick={this.onShareProperty}><i
                                                    className="fa fa-share-alt mr-2"/>
                                                    Share
                                                </div>
                                                //
                                                // <button className="btn btn-yellow" onClick={this.handlePropertyImgChange}>Save
                                                // changes
                                                // </button>
                                            }
                                        </div>}

                                        <hr/>
                                        <div id="contacts" className="broadcast-model-contact">
                                            <ul>


                                                {(!filteredBroadcastPersonList.length) && <div className="text-center">
                                                    <DataNotFound
                                                        message={`Sorry! No any Data found.`}/>
                                                </div>

                                                }
                                                {(filteredBroadcastPersonList && filteredBroadcastPersonList.length > 0) && filteredBroadcastPersonList.map((person, index) =>
                                                    <BroadcastModelChatUser
                                                        key={index}
                                                        handleSelectChatPerson={this.handleSelectBroadcastPerson}
                                                        name={`${person.first_name ? person.first_name : ""} ${person.last_name ? person.last_name : ""}`}

                                                        profile_pic={person.chat_type === "Agent" ? person.profile_pic : broadcastIcon}
                                                        lastMessage={person.last_message}
                                                        chatPerson={chatPerson}
                                                        isActive={this.state.broadcast_members_index.find(m => parseInt(index + 1) === parseInt(m))}
                                                        user_id={parseInt(index + 1)}/>)}


                                            </ul>
                                        </div>
                                    </div>

                                </Fragment>
                            </div>
                            <div className="modal-footer">

                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
        </div>
        </Fragment>

    }
}

const mapStateToProps = state => {
    return {

        getFeaturedPropertyLoader: state.property.getFeaturedPropertyLoader,
        getFeaturedProperty: state.property.getFeaturedProperty,
        getPremiumFeaturedPropertyLoader: state.property.getPremiumFeaturedPropertyLoader,
        getPremiumFeaturedProperty: state.property.getPremiumFeaturedProperty,
        units: state.property.units,
        agent: state.agent,
        propertyData: state.property.propertyData,
        propertyCount: state.property.propertyCount,
        suggestedLocations: state.auth.suggestedLocations,
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        chatPersonList: state.chat.chatPersonList,
        createBroadcastLoader: state.chat.createBroadcastLoader,
        sharePropertyUrlLoader: state.chat.sharePropertyUrlLoader,
        sharePropertyUrl: state.chat.sharePropertyUrl,

    };
};

export default connect(mapStateToProps, {
    actionGetFeatureProperty,
    actionGetPremiumFeatureProperty,
    actionGetUnits,
    actionGetChatList,
    actionGetAgent,
    actionSharePropertyUrl,
    actionLiveSharePropertyUrl
})
(Featured);

