import React, {useEffect} from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import UserListSection from '../section-components/agent-list';
import Footer from '../global-components/footer';
import {AGENTS} from "../../actions/types";
import ReactGA from "react-ga";

const AgentList = () => {
    window.scrollTo(0, 0);
    useEffect(()=>{
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Agent | Global Agent Network";


    },[]);
    return <div>
        <Navbar scenario={AGENTS}/>
        <PageHeader headertitle="Agents List" />
        <UserListSection />
        <Footer />
    </div>
};

export default AgentList;

