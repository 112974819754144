import React, {Component} from 'react';
import classnames from "classnames";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css'
import {actionRegister} from "../../actions/authAction";
import {connect} from 'react-redux';
import btnLoader from '../../assets/img/button-loader.svg';
import {Link} from "react-router-dom";
import PropertyCommonSelect from "../add-property/PropertyCommonSelect";


class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user_type: 'USER',
            first_name: '',
            last_name: '',
            email: '',
            mobile: '',
            password: '',
            c_password: '',
            other:'',
            agree: false,
            heard_us_from:'',
            errors: {}
        };
    }

    onChange = e => this.setState({[e.target.name]: e.target.value}, () => {
        if (this.state.first_name.length > 0 || this.state.last_name.length > 0 || this.state.mobile.length > 0 || this.state.email.length > 0 || this.state.password.length > 0 || this.state.c_password.length > 0) {
            this.setState({errors: {}})
        }
    });

    onChangeSelect = e => this.setState({[e.target.name]: e.target.value}, () => {
        if (this.state.first_name.length > 0 || this.state.last_name.length > 0 || this.state.mobile.length > 0 || this.state.email.length > 0 || this.state.password.length > 0 || this.state.c_password.length > 0) {
            this.setState({errors: {}})
        }
    });

    handleRegisterValidation = () => {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        const charCheck = RegExp(/[a-zåäö ]/i);
        const validEmailRegex = RegExp(
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
        // const phonergx = new RegExp(/^[0-9\b]+$/);
        // const strchek = /^[A-Z a-z]+$/;

        const strchek1 = /^[^abc$0-9(?:abc)]+([-_\s]{1}[a-z0-9]+)*$/i;

        // Checkpoint for First Name is empty

        if (!fields.first_name.trim()) {

            formIsValid = false;
            errors.first_name = "*Please Enter your First Name...!";
        }
        // else if (!fields.first_name.match(strchek1)) {
        //     formIsValid = false;
        //     errors.first_name = "Please Enter Valid Inputs...!";
        // }

        // Checkpoint for Last Name is empty
        if (!fields.last_name.trim()) {
            formIsValid = false;
            errors.last_name = "Please Enter Your Last Name...!";
        }
        // Checkpoint for Last Name is empty
        if (this.state.heard_us_from==="Other"){
            if (!fields.other.trim()) {
                formIsValid = false;
                errors.other = "Please Enter value...!";
            }
        }

        // else if (!fields.last_name.match(strchek1)) {
        //     formIsValid = false;
        //     errors.last_name = "Please Enter Valid Inputs...!";
        // }
        // Checkpoint for Last Name is empty
        if (!fields.email) {
            formIsValid = false;
            errors.email = "Please Enter Your Email";
        } else if (!validEmailRegex.test(fields.email)) {
            formIsValid = false;
            errors.email = "Please Enter Valid Email...!";
        }
        // Checkpoint for Last Name is empty
        if (!fields.mobile) {
            formIsValid = false;
            errors.mobile = "Please Enter Your Mobile";
        } else if (fields.mobile.length < 10) {
            formIsValid = false;
            errors.mobile = "Please Enter Valid Mobile Number...!";
        }
        else if (fields.mobile.length > 16) {
            formIsValid = false;
            errors.mobile = "Mobile Number is Not Valid!";
        }
        // else if (phonergx.test(fields.mobile)) {
        //     formIsValid = false;
        // }
        // Checkpoint forPassword is empty
        if (!fields.password) {
            formIsValid = false;
            errors.password = "Please Enter Your Password";
        } else if (fields.password.length < 8) {
            formIsValid = false;
            errors.password = '*Your password should be minimum 8 characters';

        }
        // Checkpoint for Confrom Password is empty
        if (!fields.c_password) {
            formIsValid = false;
            errors.c_password = "Please Re-enter Your Password";
        } else if (fields.password !== fields.c_password) {
            formIsValid = false;
            errors.c_password = "Password not match please re-enter as above..!";
        }

        if (!fields.agree) {
            formIsValid = false;
            errors.agree = "Please agree to our terms of service and privacy policy.";
        }

        this.setState({errors: errors});
        return formIsValid;
    };

    onRegisterSubmit = e => {
        e.preventDefault();
        if (this.handleRegisterValidation()) {
            console.log(this.state,'this.state')
            // Register Success Now API Calling begin
            const {first_name, last_name, email, mobile, password, user_type,heard_us_from} = this.state;
            const heard_us_from_other= this.state.other;
            const registerRequest = {
                first_name,
                last_name,
                email,
                mobile: `+${mobile}`.replace(/[^+\d]+/g, '').toString(),
                password,
                role: user_type,
                heard_us_from,
                heard_us_from_other
            };
            this.props.actionRegister(registerRequest, this.props.history);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        prevProps.auth.errors !== this.props.auth.errors && this.setState({errors: this.props.auth.errors});
    }


    render() {
        const {errors} = this.state;
        const {registerLoading} = this.props.auth;
        return (
            <div className="col-xl-4 col-lg-5 col-md-6 register-container">
                <form className="contact-form-wrap contact-form-bg" autoComplete="off" noValidate={true}
                      onSubmit={this.onRegisterSubmit}>
                    <h4>Registration</h4>
                    <div className="radio">
                        <input className="radio_type" type="radio" value="USER" name="user_type"
                               checked={this.state.user_type === "USER"}
                               onChange={this.onChange}/> User &nbsp;&nbsp;
                        <input className="radio_type" type="radio" value="AGENT" name="user_type"
                               onChange={this.onChange}/> Agent
                    </div>
                    <div id="user">
                        <div className="rld-single-input">
                            <input type="text"
                                   name="first_name"
                                   id="rdl-mobbb"
                                   value={this.state.first_name}
                                   onChange={this.onChange}
                                   className={classnames({
                                       'is-invalid': errors.first_name
                                   })}
                                   placeholder="First Name"/>
                            {errors.first_name && <span className='text-danger'>{errors.first_name}</span>}
                        </div>
                        <div className="rld-single-input">
                            <input type="text"
                                   name="last_name"
                                   id="rdl-mobbb"
                                   value={this.state.last_name}
                                   onChange={this.onChange}
                                   className={classnames({
                                       'is-invalid': errors.last_name
                                   })}
                                   placeholder="Last Name"/>
                            {errors.last_name && <span className='text-danger'>{errors.last_name}</span>}
                        </div>
                        <div className="rld-single-input">
                            <input type="text"
                                   name="email"
                                   id="rdl-mobbb"
                                   value={this.state.email.trim()}
                                   onChange={this.onChange}
                                   className={classnames({
                                       'is-invalid': errors.email
                                   })}
                                   placeholder="Email"/>
                            {errors.email && <span className='text-danger'>{errors.email}</span>}

                        </div>
                        <div className="rld-single-input">
                            <PhoneInput
                                international={true}
                                name="mobile"

                                id="rdl-mobbb"
                                placeholder="Eg(+01 1234567890)"
                                autoComplete="off"
                                country={'in'}
                                countryCodeEditable={false}
                                specialLabel={false}
                                value={this.state.mobile}
                                onChange={value => this.setState({mobile: value})}
                                className={classnames("w-100",{
                                    'is-invalid': errors.mobile
                                })}
                            />
                            {errors.mobile && <span className='text-danger'>{errors.mobile}</span>}
                        </div>
                        <div className="rld-single-input">
                            <input type="password"
                                   name="password"
                                   id="rdl-mobbb"
                                   value={this.state.password}
                                   onChange={this.onChange}
                                   className={classnames({
                                       'is-invalid': errors.password
                                   })}
                                   placeholder="Password"/>
                            {errors.password && <span className='text-danger'>{errors.password}</span>}

                        </div>
                        <div className="rld-single-input">
                            <input type="password"
                                   name="c_password"
                                   id="rdl-mobbb"
                                   value={this.state.c_password}
                                   onChange={this.onChange}
                                   className={classnames({
                                       'is-invalid': errors.c_password
                                   })}
                                   placeholder="Re-enter password"/>
                            {errors.c_password && <span className='text-danger'>{errors.c_password}</span>}
                        </div>

                        <div className="rld-single-input">
                            <label htmlFor="exampleFormControlSelect1">Select Heard from us</label>
                            <select className=""  id="exampleFormControlSelect1"
                                    name={'heard_us_from'}
                                    value={this.state.heard_us_from}
                                    onChange={this.onChangeSelect}
                            >
                                <option value="Facebook">Facebook</option>
                                <option value="Twitter">Twitter</option>
                                <option value="Instagram">Instagram</option>
                                <option value="LinkedIn">LinkedIn</option>
                                <option value="Google">Google</option>
                                <option value="Other">Other</option>
                            </select>
                            {errors.heard_us_from && <span className='text-danger'>{errors.heard_us_from}</span>}
                        </div>
                        {this.state.heard_us_from ==="Other"&&
                        <div className="rld-single-input">
                            <input type="text"
                                   name="other"
                                   id="rdl-other"
                                   value={this.state.other}
                                   onChange={this.onChange}
                                   className={classnames({
                                       'is-invalid': errors.other
                                   })}
                                   placeholder="Other"/>
                            {errors.other && <span className='text-danger'>{errors.other}</span>}
                        </div>}


                        <div className="d-flex justify-content-between flex-wrap ">
                            <label><input type="checkbox" className="mr-1" id="agree" value={this.state.agree}
                                          onChange={(e) => this.setState({agree: e.target.checked})}/>By clicking
                                "Register", you agree to our <Link to={'/privacy-policy'}
                                                                   className="text-warning"
                                                                   target={'_blank'}>Terms
                                    of Use</Link> and <Link className="text-warning" to={'/terms-of-service'}
                                                            target={'_blank'}>Privacy Policy</Link>. We will be sending you a verification code by SMS to your phone number.</label>
                        </div>
                        {errors.agree && <span className='text-danger'>{errors.agree}</span>}
                    </div>
                    <div className="btn-wrap">
                        {registerLoading ?
                            <button
                                className="btn btn-yellow"
                                type="button">
                                <img src={btnLoader} alt="login-loader"
                                     width={15}/>
                            </button> :
                            <button
                                className="btn btn-yellow"
                                type="submit">Register
                            </button>}
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps, {actionRegister})(Register);