import React, {Component,Fragment} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import TestimonialsList from "./testimonials-list";
import {TESTIMONIAL} from "../../actions/types";
import ReactGA from "react-ga";


class Testimonials extends Component {

    componentDidMount() {

        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Testimonials | Global Agent Network";

    }
    render() {
        return (
            <Fragment>
                <Navbar scenario={TESTIMONIAL}/>
                <PageHeader headertitle="Testimonials"/>
                <TestimonialsList/>
                <Footer/>
            </Fragment>
        );
    }
}

export default Testimonials;