import React, {Component, Fragment} from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer';
import connect from "react-redux/es/connect/connect";
import {actionGetAgent} from "../../actions/agentAction";
import {
    actionGetCities,
    actionGetCountries,
    actionGetStates, actionUpdateUserProfile,
    changeCompanyProfilePic,
    changeProfilePic
} from "../../actions/authAction";
import defaultAvatar from "../../assets/img/nophoto.png";
import classnames from "classnames";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css'
import companyPlaceholder from "../../assets/img/company-logo-placeholder.png";
import btnLoader from "../../assets/img/button-loader.svg";
import ReactCrop from "react-image-crop";

import 'react-image-crop/dist/ReactCrop.css';
import icon5 from '../../assets/img/icons/5.png'
import icon29 from '../../assets/img/icons/29.png'
import icon1 from '../../assets/img/icons/8.png'
import icon23 from '../../assets/img/icons/23.png'
import SelectSearch from "react-select-search";
import {
    actionGetCity,
    actionGetCountry,
    actionGetState,
    actionRemoveCities,
    actionRemoveStates
} from "../../actions/propertyAction";
import {IMAGE_BASE_URL} from "../../web-config";
import {USER_PROFILE} from "../../actions/types";
import {Link} from "react-router-dom";
import ReactGA from "react-ga";

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: 0,
            first_name: "",
            last_name: "",
            mobile: "",
            email: "",
            country_id: '',
            state_id: 0,
            city_id: 0,
            zip_code: "",
            gender: "M",
            user_profile_error: "",
            company_profile_error: "",
            profilePicLoader: false,
            companyProfilePicLoader: false,
            company_name: "",
            company_street_address: "",
            team_name: "",
            company_state: "",
            company_zip_code: "",
            home_number: "",
            number_of_years_in_business: "",
            designations: "",
            languages_spoken: "",
            hobbies: "",
            cites_expert: "",
            subdivisions_expert: "",
            specialty: '',
            website: '',
            facebook_link: '',
            instagram_link: '',
            twitter_link: '',
            profile_pic: '',
            errors: {},


            src: null,
            cp_src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1,
            },
            company_profile_crop: {
                unit: 'px',
                width: 80,
                aspect: 16 / 9,
            },
            blob: '',
            blob1: ''
        };
        this.inputOpenFileRef = React.createRef();
    }

    /*  Image Crop */
    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({src: reader.result})
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };


    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({crop});
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
                this.setState({blob}) //added by SO huy nguyen
            }, 'image/jpeg');
        });
    }

    handleProfileChange = async (e) => {
        const config = {responseType: 'blob'};
        /*let file = axios.get(this.state.croppedImageUrl, config).then(response => {
            new File([response.data], 'my_image.png', {type: "image/jpg", lastModified: new Date()});
        });*/
        var file = new File([this.state.croppedImageUrl], "my_image.png", {
            type: "image/png",
            lastModified: new Date()
        });
        let formData = new FormData();
        formData.append('user_id', this.props.user.user_id);
        formData.append('profile_pic', this.state.blob);
        formData.append('remove_profile', 0);
        this.setState({profile_pic: this.state.blob.toString()});
        this.setState({profilePicLoader: true}, document.getElementById('close-profilePictureUpload') && document.getElementById('close-profilePictureUpload').click());
        try {
            await this.props.changeProfilePic(formData);
            this.setState({
                profilePicLoader: false,
                src: null,
                blob: ''
            }, () => document.getElementById('profile-pic-selector').value = '');
        }
        catch (error) {
            this.setState({profilePicLoader: false});
            console.error({"error setting profile pic": error});
        }
    };

    /*  Image Crop Ends */

    onSelectCompanyProfileFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader_C = new FileReader();
            reader_C.addEventListener('load', () =>
                this.setState({cp_src: reader_C.result})
            );
            reader_C.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onCompanyImageLoaded = image => {
        this.imageRef = image;
    };

    onCompanyImageCropComplete = company_profile_crop => {
        this.makeClientCropCompanyImage(company_profile_crop);
    };

    onCompanyImageCropChange = (company_profile_crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });

        this.setState({company_profile_crop});
    };

    async makeClientCropCompanyImage(company_profile_crop) {
        if (this.imageRef && company_profile_crop.width && company_profile_crop.height) {
            const croppedCompanyImageUrl = await this.getCompanyCroppedImg(
                this.imageRef,
                company_profile_crop,
                'newCompan' +
                'yProfileFile.jpeg'
            );
            this.setState({croppedCompanyImageUrl});
        }
    }

    getCompanyCroppedImg(image, company_profile_crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = company_profile_crop.width;
        canvas.height = company_profile_crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            company_profile_crop.x * scaleX,
            company_profile_crop.y * scaleY,
            company_profile_crop.width * scaleX,
            company_profile_crop.height * scaleY,
            0,
            0,
            company_profile_crop.width,
            company_profile_crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob1 => {
                if (!blob1) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob1.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob1);
                resolve(this.fileUrl);
                this.setState({blob1}) //added by SO huy nguyen
            }, 'image/jpeg');
        });
    }

    handleCompanyProfileChange = async (e) => {
        const config = {responseType: 'blob'};

        var file = new File([this.state.croppedCompanyImageUrl], "company_profile_image.jpg", {
            type: "image/png",
            lastModified: new Date()
        });
        let formData = new FormData();
        formData.append('user_id', this.props.user.user_id);
        formData.append('company_logo', this.state.blob1);
        formData.append('remove_company_logo', 0);
        this.setState({companyProfilePicLoader: true}, document.getElementById('close-companyprofilePictureUpload') && document.getElementById('close-companyprofilePictureUpload').click());
        try {
            await this.props.changeCompanyProfilePic(formData);

            this.setState({
                companyProfilePicLoader: false,
                cp_src: null,
                blob1: ''
            }, () => document.getElementById('company-profile-pic-selector').value = '');

        }
        catch (error) {
            this.setState({company_profilePicLoader: false});
            console.error({"error setting company profile pic": error});
        }
    };

    /*  Company Image Crop Ends */

    componentDidMount() {
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Profile | Global Agent Network";
        // ReactGA.initialize('G-2XQB9WGPED');
        // ReactGA.pageview(`/profile/${this.props.match.params.userId && this.props.match.params.userId}`);

        this.props.actionGetAgent(this.props.match.params.userId);
        this.props.actionGetCountry();
    }

    componentDidUpdate(prevProps, prevState) {
        (prevProps.agent.agentData !== this.props.agent.agentData) && this.setState({
            ...this.state,
            ...this.props.agent.agentData,
            country_id: parseInt(this.props.agent.agentData.country_id)?parseInt(this.props.agent.agentData.country_id):'',
            state_id: parseInt(this.props.agent.agentData.state_id)?parseInt(this.props.agent.agentData.state_id):'',
            city_id: parseInt(this.props.agent.agentData.city_id)?parseInt(this.props.agent.agentData.city_id):''
        });
        (prevState.country_id !== this.state.country_id && !this.props.states) && this.props.actionGetState(`country_id=${this.state.country_id}&q=`);
        (prevState.state_id !== this.state.state_id && !this.props.city) && this.props.actionGetCity(`state_id=${this.state.state_id}&q=`);
        prevProps.user !== this.props.user && this.setState({
            profile_pic: this.props.user.profile_pic,
            company_logo: this.props.user.company_logo
        })
    }

    onChange = e => this.setState({[e.target.name]: e.target.value},()=>{
        if (this.state.first_name.length>0 || this.state.last_name.length>0 || this.state.mobile.length>0 || this.state.mobile.length>0 || this.state.zip_code.length>0 || this.state.company_name.length>0 || this.state.company_street_address.length>0 || this.state.team_name.length>0 || this.state.company_state.length>0 || this.state.company_zip_code.length>0 || this.state.home_number.length>0 || this.state.number_of_years_in_business.length>0 || this.state.designations.length>0 || this.state.languages_spoken.length>0 || this.state.hobbies.length>0 || this.state.cites_expert.length>0 || this.state.subdivisions_expert.length>0 || this.state.specialty.length>0 || this.state.website.length>0 || this.state.facebook_link.length>0 || this.state.instagram_link.length>0 || this.state.twitter_link.length>0){
            this.setState({errors:{}})
        }
    });

    onChangeEmail = e => this.setState({[e.target.name]: e.target.value.trim()},()=>{
        if (this.state.email.length>0) {
            this.setState({errors:{}})
        }
    });

    onChangeCountry = e => this.setState({[e.target.name]: e.target.value}, () => {
        console.log(this.state.state_id);
        this.props.actionGetStates(this.state.country_id);
    });

    onChangeState = e => this.setState({[e.target.name]: e.target.value}, () => {
        this.props.actionGetCities(this.state.state_id);
    });

    handleSaveValidation = () => {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        const validEmailRegex = RegExp(
            /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)

        const strchek1 = /^[^0-9]+([-_\s]{1}[a-z0-9]+)*$/i;
        // Personal Info Validation

        if (!fields.first_name) {
            formIsValid = false;
            errors.first_name = "*Please Enter your First Name...!";
        }
        // else if (!fields.first_name.match(strchek1)) {
        //     formIsValid = false;
        //     errors.first_name = "Please Enter Valid Inputs...!";
        // }
        // Last Name Validation
        if (!fields.last_name.trim()) {
            formIsValid = false;
            errors.last_name = "Please Enter Your Last Name...!";
        }
        // else if (!fields.last_name.match(strchek1)) {
        //     formIsValid = false;
        //     errors.last_name = "Please Enter Valid Inputs...!";
        // }
        if (!fields.specialty) {
            // formIsValid = false;
            // errors.specialty = "Specialty Required!";
        }
        if (!fields.website) {
            //formIsValid = false;
            //errors.website = "website Required!";
        }

        // Email Validation
        if (!fields.email) {
            formIsValid = false;
            errors.email = "Please Enter Your Email";
        } else if (!validEmailRegex.test(fields.email)) {
            formIsValid = false;
            errors.email = "Please Enter Valid Email...!";
        }

        //MObile Validation
        if (!fields.mobile) {
            formIsValid = false;
            errors.mobile = "Please Enter Your Mobile";
        } else if (fields.mobile.length < 10) {
            formIsValid = false;
            errors.mobile = "Please Enter Valid Mobile Number...!";
        }
        else if (fields.mobile.length > 16) {
            formIsValid = false;
            errors.mobile = "Mobile Number is Not Valid!";
        }
        if (!fields.team_name) {
            // formIsValid = false;
            // errors.team_name = "Team Name Required!";
        }

        // Company Info Validation
        if (!fields.company_name) {
            // formIsValid = false;
            // errors.company_name = "Company Name Required!";
        }
        if (!fields.company_street_address) {
            // formIsValid = false;
            // errors.company_street_address = "Company Street Address Required!";
        }
        if (!fields.company_state) {
            // formIsValid = false;
            // errors.company_state = "Company State Required!";
        }
        if (!fields.company_zip_code) {
            // formIsValid = false;
            // errors.company_zip_code = "Company Zip Required!";
        }
        if (!fields.home_number) {
            // formIsValid = false;
            // errors.home_number = "Home Number Required!";
        }
        if (!fields.number_of_years_in_business) {
            // formIsValid = false;
            // errors.number_of_years_in_business = "Number of Years in Business Required!";
        }
        if (fields.designations === "") {
            // formIsValid = false;
            // errors.designations = "Designations Required!";
        }
        if (!fields.languages_spoken) {
            // formIsValid = false;
            // errors.languages_spoken = "Language Spoken Required!";
        }
        if (!fields.hobbies) {
            // formIsValid = false;
            // errors.hobbies = "Hobbies Required!";
        }
        if (!fields.cites_expert) {
            // formIsValid = false;
            // errors.cites_expert = "Cites Expert Required!";
        }
        if (!fields.subdivisions_expert) {
            // formIsValid = false;
            // errors.subdivisions_expert = "Subdivisions Expert Required!";
        }

        this.setState({errors: errors}, () => {
            const ID = Object.keys(errors)[0];
            console.log({ID});
            document.getElementById(ID) &&  document.getElementById(ID).scrollIntoView();
        });
        return formIsValid;
    };

    onSaveSubmit = e => {
        e.preventDefault();
        const {user_id,
            first_name,
            last_name,
            mobile,
            email,
            country_id,
            state_id,
            city_id,
            zip_code,
            gender,
            user_profile_error,
            company_profile_error,
            profilePicLoader,
            companyProfilePicLoader,
            company_name,
            company_street_address,
            team_name,
            company_state,
            company_zip_code,
            home_number,
            number_of_years_in_business,
            designations,
            languages_spoken,
            hobbies,
            cites_expert,
            subdivisions_expert,
            specialty,
            website,
            facebook_link,
            instagram_link,
            twitter_link,
            }=this.state;


        // const {user_id, first_name, last_name, email, specialty, mobile, website, company_name, team_name, company_state, company_zip_code, home_number, number_of_years_in_business, designations, languages_spoken, hobbies, cites_expert, subdivisions_expert, city_id, state_id, country_id, zip_code, errors} = this.state;
        if (this.handleSaveValidation()) {
            // Update Success Now API Calling begin
            const profile_pic = this.state.profile_pic.split('/')[4];
            const company_logo= this.state.company_logo.split('/')[4];
            const request = {
                user_id,
                first_name,
                last_name,
                email,
                specialty,
                mobile,
                website,
                company_name,
                team_name,
                company_state,
                company_zip_code,
                home_number,
                number_of_years_in_business,
                designations,
                languages_spoken,
                hobbies,
                cites_expert,
                subdivisions_expert,
                city_id,
                state_id,
                country_id,
                zip_code,
                gender,
                company_street_address,
                facebook_link,
                instagram_link,
                twitter_link,
                profile_pic:this.state.profile_pic?this.state.profile_pic.split('/')[4]:'',
                company_logo:this.state.company_logo?this.state.company_logo.split('/')[4]:'',
            };

            // const request= {...this.state};
            this.props.actionUpdateUserProfile(request);

        }
    };

    onChangeFile(event) {
        var user_profile = event.target.files[0];
        console.log(user_profile);
        this.setState({user_profile});
    }

    render() {
        const {crop, company_profile_crop, src, cp_src, errors} = this.state;
        const {city, state, country, updateProfileLoader} = this.props;
        const countryData = (this.props.country && this.props.country.length > 0) && this.props.country.map(c => {
            return {
                name: c.name,
                value: c.id
            }
        });
        const stateData = (this.props.states && this.props.states.length > 0) && this.props.states.map(s => ({
            name: s.name,
            value: s.id
        }));
        const cityData = (this.props.city && this.props.city.length > 0) && this.props.city.map(c => ({
            name: c.name,
            value: c.id
        }));
        return (
            <Fragment>
                <Navbar scenario={USER_PROFILE}/>
                <PageHeader headertitle="User Profile"/>
                <Fragment>
                    <div className="add-new-property-area mg-bottom-100">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-9 col-lg-10">
                                    <form autoComplete="off" noValidate={true} onSubmit={this.onSaveSubmit}
                                          id={'user-profile-form'}>
                                        <div className="row pd-top-60">
                                            <div className="col-md-4">
                                                <div className="section-title">
                                                    <h4><img src={icon5} alt="img"/>Agent
                                                        Info
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="col-md-8 bg-white p-3 border-radius-10">
                                                <div className="agentprofile-container text-center"><img
                                                    src={this.props.user.profile_pic ? this.props.user.profile_pic : defaultAvatar}
                                                    alt="img"/>
                                                    {this.state.profilePicLoader ?
                                                        <div className="add-media" style={{background: 'red'}}>
                                                            <i className="fa fa-spinner fa-pulse"
                                                               style={{color: '#fda94f', boxShadow: 'none'}}/>
                                                        </div> :
                                                        <div className="add-media" data-toggle="modal"
                                                             data-target="#profilePictureUpload">
                                                            <i className="fa fa-pencil"/>
                                                        </div>}
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="first_name"
                                                                id="fname"
                                                                value={this.state.first_name}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.first_name
                                                                })}
                                                                placeholder="First Name"/>
                                                            {errors.first_name &&
                                                            <span className='text-danger'>{errors.first_name}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="last_name"
                                                                id="lname"
                                                                value={this.state.last_name}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.last_name
                                                                })}
                                                                placeholder="Last Name"/>
                                                            {errors.last_name &&
                                                            <span className='text-danger'>{errors.last_name}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="specialty"
                                                                id="specialty"
                                                                value={this.state.specialty}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.specialty
                                                                })}
                                                                placeholder="Specialty"/>
                                                            {errors.specialty &&
                                                            <span className='text-danger'>{errors.specialty}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="website"
                                                                id="website"
                                                                value={this.state.website}
                                                                onChange={this.onChangeEmail}
                                                                className={classnames({
                                                                    'is-invalid': errors.website
                                                                })}
                                                                placeholder="Website"/>
                                                            {errors.website &&
                                                            <span className='text-danger'>{errors.website}</span>}
                                                        </div>
                                                    </div>
                                                    {/*<div className="col-lg-6 mb-3">*/}
                                                    {/*<div className="rld-single-input">*/}
                                                    {/*<input*/}
                                                    {/*type="text"*/}
                                                    {/*name="company_name"*/}
                                                    {/*id="company_name"*/}
                                                    {/*value={this.state.company_name}*/}
                                                    {/*onChange={this.onChange}*/}
                                                    {/*className={classnames({*/}
                                                    {/*'is-invalid': errors.company_name*/}
                                                    {/*})}*/}
                                                    {/*placeholder="Company Name"/>*/}
                                                    {/*{errors.company_name &&*/}
                                                    {/*<span className='text-danger'>{errors.company_name}</span>}*/}
                                                    {/*</div>*/}
                                                    {/*</div>*/}
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                id="email"

                                                                value={this.state.email}
                                                                onChange={this.onChangeEmail}
                                                                className={classnames({
                                                                    'is-invalid': errors.email
                                                                })}
                                                                placeholder="Email"/>
                                                            {errors.email &&
                                                            <span className='text-danger'>{errors.email}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-1 ">
                                                        <div className="rld-single-input">
                                                            <PhoneInput
                                                                name="mobile"
                                                                id="mobile"
                                                                placeholder="Mobile"
                                                                autoComplete="off"
                                                                country={'in'}
                                                                // prefix={+}
                                                                onlyCountries={['in']}
                                                                enableAreaCodeStretch
                                                                specialLabel={false}
                                                                disabled={true}
                                                                value={this.state.mobile}
                                                                onChange={(value) => this.setState({mobile: value})}
                                                                international={true}
                                                                className={classnames({
                                                                    'is-invalid': errors.mobile
                                                                })}
                                                            />

                                                            {/*{errors.mobile &&*/}
                                                            {/*<span className='text-danger'>{errors.mobile}</span>}*/}
                                                            <Link to={'/change-mobile-number'} className="text-primary"> Change Mobile Number</Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-input" onChange={this.onChange}>
                                                            <input type="radio" value="M"
                                                                   checked={this.state.gender === "M"}
                                                                   name="gender"/> Male &nbsp;
                                                            <input type="radio" value="F"
                                                                   checked={this.state.gender === "F"}
                                                                   name="gender"/> Female
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pd-top-80">
                                            <div className="col-md-4">
                                                <div className="section-title">
                                                    <h4><img src={icon29} alt="img"/>Address
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="col-md-8 bg-white p-3 border-radius-10">
                                                <div className="section-title">
                                                    <h4>Address Here</h4>
                                                    {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing </p>*/}
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input mt-0 text-center">
                                                            <SelectSearch
                                                                options={countryData.length ? countryData : []}
                                                                value={this.state.country_id}
                                                                onChange={(country_id) =>{
                                                                    console.log(country_id);
                                                                    this.setState({
                                                                    country_id,
                                                                    state_id: '',
                                                                    city_id: ''
                                                                }, this.props.actionRemoveStates());
                                                                    this.props.actionGetState(`country_id=${country_id}`)
                                                                }}
                                                                name="country_id"
                                                                autoComplete="none"

                                                                placeholder="Choose your country"
                                                                disabled={!countryData.length}
                                                                search={true}/>
                                                            {errors.country_id &&
                                                            <span className='text-danger'>{errors.country_id}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input mt-0 text-center ">
                                                            <SelectSearch
                                                                options={stateData.length ? stateData : []}
                                                                getOptions={(query) => this.props.actionGetState(`country_id=${this.state.country_id}&q=${query}`)}
                                                                value={this.state.state_id}
                                                                onChange={(state_id) => {this.setState({
                                                                    state_id,
                                                                    city_id: ''
                                                                }, this.props.actionRemoveCities())
                                                                    this.props.actionGetCity(`state_id=${state_id}`)
                                                                }}
                                                                name="state_id"
                                                                autoComplete="none"
                                                                placeholder="Choose your state"
                                                                disabled={!this.state.country_id}
                                                                search={true}/>

                                                            {errors.state_id &&
                                                            <span className='text-danger'>{errors.state_id}</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input mt-0 text-center ">
                                                            <SelectSearch
                                                                options={cityData.length ? cityData : []}
                                                                getOptions={(query) => this.props.actionGetCity(`state_id=${this.state.state_id}&q=${query}`)}
                                                                value={this.state.city_id && this.state.city_id}
                                                                onChange={(city_id) => this.setState({city_id})}
                                                                name="city_id"
                                                                autoComplete="none"
                                                                placeholder="Choose your city"
                                                                disabled={!this.state.state_id || !this.state.country_id}
                                                                search={true}/>
                                                            {errors.city_id &&
                                                            <span className='text-danger'>{errors.city_id}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="zip_code"
                                                                id="zip_code"
                                                                value={this.state.zip_code}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.zip_code
                                                                })}
                                                                placeholder="ZIP Code"/>
                                                            {errors.zip &&
                                                            <span className='text-danger'>{errors.zip_code}</span>}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>


                                        </div>
                                        <div className="row pd-top-60">
                                            <div className="col-md-4">
                                                <div className="section-title">
                                                    <h4><img src={icon23} alt="img"/>Company
                                                        info
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="col-md-8 bg-white p-3 border-radius-10">
                                                <div className="companyprofile-container text-center"><img
                                                    src={this.props.user.company_logo ? this.props.user.company_logo : companyPlaceholder}
                                                    alt="img"/>
                                                    {this.state.companyProfilePicLoader ?
                                                        <div className="add-media" style={{background: 'red'}}>
                                                            <i className="fa fa-spinner fa-pulse"
                                                               style={{color: '#fda94f', boxShadow: 'none'}}/>
                                                        </div> :
                                                        <div className="add-media" data-toggle="modal"
                                                             data-target="#companyprofilePictureUpload">
                                                            <i className="fa fa-pencil"/>
                                                        </div>}
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="company_name"
                                                                id="company_name"
                                                                value={this.state.company_name}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.company_name
                                                                })}
                                                                placeholder="Company Name"/>
                                                            {errors.company_name &&
                                                            <span className='text-danger'>{errors.company_name}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="company_street_address"
                                                                id="company_street_address"

                                                                value={this.state.company_street_address}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.company_street_address
                                                                })}
                                                                placeholder="Company Street Address"/>
                                                            {errors.company_street_address &&
                                                            <span
                                                                className='text-danger'>{errors.company_street_address}</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="team_name"
                                                                id="team_name"
                                                                value={this.state.team_name}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.team_name
                                                                })}
                                                                placeholder="Team Name"/>
                                                            {errors.team_name &&
                                                            <span className='text-danger'>{errors.team_name}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="company_state"
                                                                id="company_state"
                                                                value={this.state.company_state}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.company_state
                                                                })}
                                                                placeholder="Company State"/>
                                                            {errors.company_state &&
                                                            <span className='text-danger'>{errors.company_state}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="company_zip_code"
                                                                id="company_zip_code"
                                                                value={this.state.company_zip_code}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.company_zip_code
                                                                })}
                                                                placeholder="Company Zip"/>
                                                            {errors.company_zip_code &&
                                                            <span
                                                                className='text-danger'>{errors.company_zip_code}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="home_number"
                                                                id="home_number"
                                                                value={this.state.home_number}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.home_number
                                                                })}
                                                                placeholder="Home Number"/>
                                                            {errors.home_number &&
                                                            <span className='text-danger'>{errors.home_number}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="number"
                                                                name="number_of_years_in_business"
                                                                id="number_of_years_in_business"
                                                                value={this.state.number_of_years_in_business}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.number_of_years_in_business
                                                                })}
                                                                placeholder="Number Of Year In Business"/>
                                                            {errors.number_of_years_in_business &&
                                                            <span
                                                                className='text-danger'>{errors.number_of_years_in_business}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="designations"
                                                                id="designations"
                                                                value={this.state.designations}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.designations
                                                                })}
                                                                placeholder="Designations"/>
                                                            {errors.designations &&
                                                            <span className='text-danger'>{errors.designations}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="languages_spoken"
                                                                id="languages_spoken"
                                                                value={this.state.languages_spoken}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.languages_spoken
                                                                })}
                                                                placeholder="Languages Known"/>
                                                            {errors.languages_spoken &&
                                                            <span
                                                                className='text-danger'>{errors.languages_spoken}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="hobbies"
                                                                id="hobbies"
                                                                value={this.state.hobbies}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.hobbies
                                                                })}
                                                                placeholder="Hobbies"/>
                                                            {errors.hobbies &&
                                                            <span className='text-danger'>{errors.hobbies}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="cites_expert"
                                                                id="cites_expert"
                                                                value={this.state.cites_expert}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.cites_expert
                                                                })}
                                                                placeholder="Cites Expert"/>
                                                            {errors.cites_expert &&
                                                            <span className='text-danger'>{errors.cites_expert}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="subdivisions_expert"
                                                                id="subdivisions_expert"
                                                                value={this.state.subdivisions_expert}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.subdivisions_expert
                                                                })}
                                                                placeholder="Subdivisions Expert"/>
                                                            {errors.subdivisions_expert &&
                                                            <span
                                                                className='text-danger'>{errors.subdivisions_expert}</span>}
                                                        </div>
                                                    </div>

                                                    {/*<div className="col-lg-6 mb-3">*/}
                                                    {/*<div className="rld-single-input">*/}
                                                    {/*<PhoneInput*/}
                                                    {/*name="contact_person_no"*/}
                                                    {/*id="contact_person_no"*/}
                                                    {/*placeholder="Contact person No"*/}
                                                    {/*autoComplete="off"*/}
                                                    {/*value={this.state.contact_person_no}*/}
                                                    {/*onChange={value => this.setState({contact_person_no: value})}*/}
                                                    {/*className={classnames({*/}
                                                    {/*'is-invalid': errors.contact_person_no*/}
                                                    {/*})}*/}
                                                    {/*/>*/}

                                                    {/*{errors.contact_person_no &&*/}
                                                    {/*<span className='text-danger'>{errors.contact_person_no}</span>}*/}
                                                    {/*</div>*/}
                                                    {/*</div>*/}

                                                    <div className="btn-wrap ">

                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                        <div className="row pd-top-80">
                                            <div className="col-md-4">
                                                <div className="section-title">
                                                    <h4><img src={icon1} alt="img"/>Social Link
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="col-md-8 bg-white p-3 border-radius-10">
                                                <div className="section-title">
                                                    <h4>Social Link</h4>
                                                    <p>Copy your Social Link And paste here</p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <label>Facebook </label>
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="facebook_link"
                                                                id="facebook_link"
                                                                value={this.state.facebook_link}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.facebook_link
                                                                })}
                                                                placeholder="https:// (Link Paste here.)"/>
                                                            {errors.facebook_link &&
                                                            <span
                                                                className='text-danger'>{errors.facebook_link}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <label>Instagram</label>
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="instagram_link"
                                                                id="instagram_link"
                                                                value={this.state.instagram_link}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.instagram_link
                                                                })}
                                                                placeholder="https:// (Link Paste here.)"/>
                                                            {errors.instagram_link &&
                                                            <span
                                                                className='text-danger'>{errors.instagram_link}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <label>Twitter</label>
                                                        <div className="rld-single-input">
                                                            <input
                                                                type="text"
                                                                name="twitter_link"
                                                                id='twitter_link'
                                                                value={this.state.twitter_link}
                                                                onChange={this.onChange}
                                                                className={classnames({
                                                                    'is-invalid': errors.twitter_link
                                                                })}
                                                                placeholder="https:// (Link Paste here.)"/>
                                                            {errors.twitter_link &&
                                                            <span
                                                                className='text-danger'>{errors.twitter_link}</span>}
                                                        </div>
                                                    </div>


                                                </div>

                                                <div className="text-center mt-3">
                                                    {updateProfileLoader ?
                                                        <button
                                                            className="btn btn-yellow"
                                                            type="button">
                                                            <img src={btnLoader} alt="-login-loader"
                                                                 width={15}/>
                                                        </button> :
                                                        <button className="btn btn-yellow">Save Profile</button>}

                                                </div>

                                            </div>


                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Fragment>
                        <div className="modal fade" id="profilePictureUpload" role="dialog" data-backdrop="static"
                             data-keyboard="false"
                             aria-labelledby="profilePictureUploadTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLongTitle">Change Profile
                                            Picture</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body text-center">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                {src ? (
                                                    <ReactCrop
                                                        src={src}
                                                        crop={crop}
                                                        ruleOfThirds
                                                        onImageLoaded={this.onImageLoaded}
                                                        onComplete={this.onCropComplete}
                                                        onChange={this.onCropChange}
                                                        className={'profile-preview-image'}
                                                    />
                                                ) : <img width={250}
                                                         src={this.props.user.profile_pic ? this.props.user.profile_pic : defaultAvatar}
                                                         alt="Profile Picture"/>}
                                            </div>
                                        </div>
                                        <hr/>
                                        <input id={'profile-pic-selector'} type="file" accept="image/*"
                                               onChange={this.onSelectFile}/>

                                    </div>
                                    <div className="modal-footer">
                                        <button id={'close-profilePictureUpload'} type="button"
                                                className="btn btn-custom-default" data-dismiss="modal">Close
                                        </button>
                                        <button className="btn btn-yellow" onClick={this.handleProfileChange}>Save
                                            changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade" id="companyprofilePictureUpload" role="dialog"
                             data-backdrop="static"
                             data-keyboard="false"
                             aria-labelledby="companyprofilePictureUploadTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLongTitle">Change Company Profile
                                            Picture</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body text-center">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                {cp_src ? (
                                                    <ReactCrop
                                                        src={cp_src}
                                                        crop={company_profile_crop}
                                                        ruleOfThirds
                                                        onImageLoaded={this.onCompanyImageLoaded}
                                                        onComplete={this.onCompanyImageCropComplete}
                                                        onChange={this.onCompanyImageCropChange}
                                                        className={'company-profile-preview-image'}
                                                    />
                                                ) : <img width={250}
                                                         src={this.state.company_logo ? `${IMAGE_BASE_URL}uploads/${this.state.company_logo}` : companyPlaceholder}
                                                         alt="Company Profile Picture"/>}
                                            </div>
                                        </div>
                                        <hr/>
                                        <input id={'company-profile-pic-selector'} type="file" accept="image/*"
                                               onChange={this.onSelectCompanyProfileFile}/>

                                    </div>
                                    <div className="modal-footer">
                                        <button id={'close-companyprofilePictureUpload'} type="button"
                                                className="btn btn-custom-default" data-dismiss="modal">Close
                                        </button>
                                        <button className="btn btn-yellow"
                                                onClick={this.handleCompanyProfileChange}>Save
                                            changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </Fragment>
                <Footer/>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        agent: state.agent,
        user: state.auth.user,
        updateProfileLoader: state.auth.updateProfileLoader,
        country: state.property.country,
        states: state.property.state,
        city: state.property.city,
    };
};
export default connect(mapStateToProps, {
    actionGetAgent,
    actionGetCountries,
    actionGetStates,
    actionGetCities,
    changeCompanyProfilePic,
    changeProfilePic,
    actionUpdateUserProfile,
    actionGetCountry,
    actionGetState,
    actionGetCity,
    actionRemoveStates,
    actionRemoveCities
})(UserProfile);