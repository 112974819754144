import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {actionGetAgents, actionGetExploreNewAgent} from "../../actions/agentAction";
import HomePageAgentCard from "../common/HomePageAgentCard";
import DataNotFound from "./DataNotFound";
import Spinner from "./Spinner";
import HomePageAgentListLoaderCard from "../agent-list/home-page-agent-list-loader-card";

class Explore extends Component {

    constructor(props) {
        super(props);

        this.state = {
            q: ''
        }
    }

    componentDidMount() {
        this.props.actionGetExploreNewAgent();
    }


    render() {
        const {exploreNewAgents,getExploreAgentLoader} = this.props.agent;
console.log(exploreNewAgents)
        return <div className="explore-area pd-top-85">
            <div className="container">
                <div className="section-title text-center">
                    <h2 className="title">Explore the Newest Agents</h2>
                </div>
                <div className="row">
                    {(exploreNewAgents && exploreNewAgents.length >0 )&& exploreNewAgents.map((item, i) =>
                        <HomePageAgentCard key={i} index={i} item={item}/>)}

                    {(!getExploreAgentLoader && exploreNewAgents.length < 1) &&
                    <div className="text-center col-md-12"><DataNotFound
                        message={`Data not found.`}/></div>}
                    {/*{agentLoader && <div className="text-center col-md-12"><Spinner width={120}/></div>}*/}
                    {getExploreAgentLoader && <div className="row">
                        <HomePageAgentListLoaderCard/>
                        <HomePageAgentListLoaderCard/>
                        <HomePageAgentListLoaderCard/>
                        <HomePageAgentListLoaderCard/>
                    </div>}
                </div>
                {!getExploreAgentLoader &&<div className="text-center">
                    <Link to="/agent-list" className="btn btn-yellow">View All</Link>
                </div>
                }

            </div>
        </div>


    }
}


const mapStateToProps = state => {
    return {
        agent: state.agent,
    };
};

export default connect(mapStateToProps, {actionGetExploreNewAgent})(Explore);
