import React, {Component, Fragment} from 'react';
import Navbar from '../global-components/navbar';
import Banner from '../section-components/banner';
import SubCategory from '../section-components/sub-category';
import Explore from '../section-components/explore';
import FeaturedProperties from '../section-components/featured-properties';
import Ads from '../section-components/ads';
import PropertiesByCities from '../section-components/properties-by-cities';
import WhyChooseUs from '../section-components/why-choose-us';
import OurPartner from '../section-components/our-partner';
import Footer from '../global-components/footer';
import {HOME} from "../../actions/types";
import ReactGA from 'react-ga';
import IntoSection from "./into-section";
import MobileAppBanner from "./mobile-app-banner";



class Home extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }
    componentDidMount(){
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Home | Global Agent Network";

    }


    render() {


        return (
            <Fragment>

                <Navbar scenario={HOME}/>
                <Banner/>
                <SubCategory/>
                <WhyChooseUs/>
                <Explore/>
                <FeaturedProperties/>
                <Ads/>
                <PropertiesByCities/>
                {/*<RecentProperties/>*/}
                {/*<FeaturedPorject/>*/}
                <IntoSection/>
                <OurPartner/>
                <MobileAppBanner/>
                <Footer/>
            </Fragment>

        );
    }
}

export default Home;