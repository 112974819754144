import * as actionTypes from '../actions/types';

const initialState = {
    propertyData: [],
    properties: [],
    getPropertyLoader: false,
    getPropertyCategoryLoader: false,
    getPropertySubCategoryLoader: false,
    getUnitsLoader: false,
    categories: [],
    subCategories: [],
    units: [],
    getAmenitiesLoader: false,
    addPropertyLoader: false,
    addPropertyData: {},
    error: {},
    country: '',
    state: '',
    city: '',
    getPropertyDetailsLoader: false,
    getPropertyDetails: '',
    getFeaturedPropertyLoader: false,
    getFeaturedProperty: '',
    getPremiumFeaturedPropertyLoader: false,
    getPremiumFeaturedProperty: {},
    getPropertyImagesLoader: false,
    getPropertyImages: [],
    addMultiplePropertyImageLoader: false,
    propertyCount:0,
    deleteAgentPropertyLoader: false,
    favouritePropertyLoader: false,
    getFavouriteProperty: [],
    getFavouritePropertyLoader: false,
    addSinglePageWebLoader: false,

    // uploadPropertyImg:[],


    amenities: []

};

export default function (state = initialState, action) {
    switch (action.type) {

        case actionTypes.GET_PROPERTY:
            return {
                ...state,
                propertyData:[...state.propertyData, ...action.payload.payload],
                propertyCount: action.payload.totalRecords
            };
        case actionTypes.GET_PROPERTY_LOADER:
            return {
                ...state,
                getPropertyLoader: action.payload
            };
        case actionTypes.REMOVE_PROPERTY:
            return {
                ...state,
                propertyData: action.payload
            };
        case actionTypes.GET_CATEGORIES_LOADER:
            return {
                ...state,
                getPropertyCategoryLoader: action.payload
            };
        case actionTypes.GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            };
        case actionTypes.GET_SUB_CATEGORIES_LOADER:
            return {
                ...state,
                getPropertySubCategoryLoader: action.payload
            };
        case actionTypes.GET_SUB_CATEGORIES:
            return {
                ...state,
                subCategories: action.payload
            };
        case actionTypes.GET_UNIT_LOADER:
            return {
                ...state,
                getUnitsLoader: action.payload
            };
        case actionTypes.GET_UNIT:
            return {
                ...state,
                units: action.payload
            };


        // case actionTypes.UPLOAD_PROPERTY_IMG:
        //     return {
        //         ...state,
        //         uploadPropertyImg: action.payload
        //     };
       //Delete agent Porperty
        case actionTypes.DELETE_AGENT_PROPERTY_SUCCESS:
            return {
                ...state,
                propertyData: [...state.propertyData.filter(p => parseInt(p.property_id) !== parseInt(action.payload))]
            };
        case actionTypes.DELETE_AGENT_PROPERTY_LOADING:
            return {
                ...state,
                // getPropertyImages: [...state.getPropertyImages.filter(img => parseInt(img.property_image_id) !== parseInt(action.payload))]
                deleteAgentPropertyLoader: action.payload
            };



        case actionTypes.GET_AMENITIES_LOADER:
            return {
                ...state,
                getAmenitiesLoader: action.payload
            };
        case actionTypes.GET_AMENITIES:
            return {
                ...state,
                amenities: action.payload
            };

        case actionTypes.ADD_PROPERTY_LOADING:
            return {
                ...state,
                addPropertyLoader: action.payload
            };
        case actionTypes.ADD_PROPERTY_SUCCESS:
            return {
                ...state,
                addPropertyData: action.payload
            };
        case actionTypes.ADD_PROPERTY_FAILED:
            return {
                ...state,
                error: action.payload
            };
        case actionTypes.GET_COUNTRY:
            return {
                ...state,
                country: action.payload
            };
        case actionTypes.GET_COUNTRY_LOADER:
            return {
                ...state,
                country: action.payload
            };
        case actionTypes.GET_STATE:
            return {
                ...state,
                state: action.payload
            };
        case actionTypes.GET_STATE_LOADER:
            return {
                ...state,
                state: action.payload
            };

        case actionTypes.GET_CITY:
            return {
                ...state,
                city: action.payload
            };
        case actionTypes.GET_CITY_LOADER:
            return {
                ...state,
                city: action.payload
            };

        case actionTypes.GET_PROPERTY_DETAILS:
            return {
                ...state,
                getPropertyDetails: action.payload
            };
        case actionTypes.GET_PROPERTY_DETAILS_LOADER:
            return {
                ...state,
                getPropertyDetailsLoader: action.payload
            };
        case actionTypes.GET_FEATURED_PROPERTY:
            return {
                ...state,
                getFeaturedProperty: action.payload
            };
        case actionTypes.GET_FEATURED_PROPERTY_LOADER:
            return {
                ...state,
                getFeaturedPropertyLoader: action.payload
            };
        case actionTypes.GET_PREMIUM_FEATURED_PROPERTY:
            return {
                ...state,
                getPremiumFeaturedProperty: action.payload
            };
        case actionTypes.GET_PREMIUM_FEATURED_PROPERTY_LOADER:
            return {
                ...state,
                getPremiumFeaturedPropertyLoader: action.payload
            };
        case actionTypes.GET_PROPERTY_IMAGES:
            return {
                ...state,
                getPropertyImages: action.payload
            };
        case actionTypes.GET_PROPERTY_IMAGES_LOADER:
            return {
                ...state,
                getPropertyImagesLoader: action.payload
            };
        case actionTypes.DELETE_PROPERTY_IMAGE:
            return {
                ...state,
                getPropertyImages: [...state.getPropertyImages.filter(img => parseInt(img.property_image_id) !== parseInt(action.payload))]
            };

        case actionTypes.ADD_MULTIPLE_PROPERTY_IMAGE_LOADER:
            return {
                ...state,
                addMultiplePropertyImageLoader: action.payload
            };
        case actionTypes.ADD_MULTIPLE_PROPERTY_IMAGE_SUCCESS:
            return {
                ...state,
                getPropertyImages: [...state.getPropertyImages, action.payload]
            };
        case actionTypes.ADD_MULTIPLE_PROPERTY_IMAGE_FAILED:
            return {
                ...state,
                error: action.payload
            };
            //Add Favourite Property
        case actionTypes.ADD_FAVOURITE_PROPERTY_LOADING:
            return {
                ...state,
                favouritePropertyLoader: action.payload
            };
        case actionTypes.ADD_FAVOURITE_PROPERTY:
            return {
                ...state,
                propertyData: state.propertyData.map(propertyData =>  parseInt(propertyData.property_id) === parseInt(action.payload) ? {
                    ...propertyData,
                    isFavorite:true
                }: propertyData)
            };
        case actionTypes.REMOVE_FAVOURITE_PROPERTY_LOADING:
            return {
                ...state,
                favouritePropertyLoader: action.payload
            };
        case actionTypes.REMOVE_FAVOURITE_PROPERTY:
            return {
                ...state,

                propertyData: state.propertyData.map(propertyData =>  parseInt(propertyData.property_id) === parseInt(action.payload) ? {
                    ...propertyData,
                    isFavorite:false
                }: propertyData)
            };

        //    Get Favorite Property
        case actionTypes.GET_FAVOURITE_PROPERTY:
            return {
                ...state,
                getFavouriteProperty: action.payload
            };
        case actionTypes.GET_FAVOURITE_PROPERTY_LOADING:
            return {
                ...state,
                getFavouritePropertyLoader: action.payload
            };

        //Add Single Page Website
        case actionTypes.ADD_SINGLE_PAGE_WEB:
            return {
                ...state,
                propertyData: state.propertyData.map(propertyData =>  parseInt(propertyData.property_id) === parseInt(action.payload.property_id) ? {
                    ...propertyData,
                    is_single:1,
                    sub_domain:action.payload.sub_domain,
                    theme:action.payload.theme,
                }: propertyData)
            };
        case actionTypes.ADD_SINGLE_PAGE_WEB_LOADING:
            return {
                ...state,
                addSinglePageWebLoader: action.payload
            };


        default:
            return state;
    }
}