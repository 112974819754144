import React, {Fragment} from 'react';
import connect from "react-redux/es/connect/connect";
import {
    actionSendFriendRequest,
    actionUnFriend,
    actionAcceptFriendRequest,
    actionRejectRequest
} from "../../actions/agentAction";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import swal from 'sweetalert';
import defaultAvatar from "../../assets/img/nophoto.png";

const AgentCard = ({index, profilePic, imagealt, address, mobile, email, url, name, user_relation, userId, isAuthenticated, actionSendFriendRequest, user, user_relation_object, actionUnFriend, actionAcceptFriendRequest, actionRejectRequest,page}) =>

{
    const history = useHistory();



    return (
        <div key={index} className="col-lg-4 col-md-6">
            <div className="single-user-list text-center bg-white">
                <div className="user-avatar ">
                    <img className="" src={profilePic ? profilePic.includes("https://")?profilePic:defaultAvatar:defaultAvatar} alt={imagealt}/>
                </div>
                <div className="details d-lg-flex flex-lg-column">
                    <h4><Link to={url}>{name}</Link></h4>
                    <p dangerouslySetInnerHTML={{__html: address}}/>
                    <span className="phone text-dark"><i className="fa fa-phone"/>{mobile}</span>
                    <span className="fax text-dark"><i className="fa fa-envelope"/>{email}</span>
                    <div className="social-list">
                        {/* If No Relation Found and iterated user is not iqual to logged in user */}
                        {(user_relation === 'NoRelation' && parseInt(userId) !== parseInt(user.user_id)) &&
                        <Link to="/" className={'btn text-warning 1'} onClick={(e) => {
                            e.preventDefault();
                            isAuthenticated ? actionSendFriendRequest(user.user_id, userId) : history.push('/register');
                        }}> <i className="fa fa-user-plus"/> Connect Now</Link>}

                        {/* If Relation REQUEST Found and sender user is not equal to logged in user then he can sent request */}
                        {((user_relation === 'REQUEST' && parseInt(user_relation_object.user_id) !== parseInt(user.user_id))) &&
                        <Fragment>
                            <Link to="/" className={'btn text-warning 2'} onClick={(e) => {
                                e.preventDefault();
                                const request = {
                                    user_id: user.user_id,
                                    related_user_id: userId,
                                    itemId: userId
                                };
                                isAuthenticated ? actionAcceptFriendRequest(request) : history.push('/register');
                            }}> <i className="fa fa-check"/> Accept</Link>

                            <Link to="/" className={'btn text-danger 2'} onClick={(e) => {
                                e.preventDefault();

                                swal({
                                    title: "Are you sure?",
                                    text: "Cancel received friend request!",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then((willDelete) => {

                                        if (willDelete) {

                                            const request = {
                                                user_id: user.user_id,
                                                related_user_id: userId,
                                                itemId: userId
                                            };
                                            isAuthenticated ? actionRejectRequest(request) : history.push('/register');
                                            swal("Poof! You received friend request is cancelled!", {
                                                icon: "success",
                                            });

                                        } else {
                                            swal("You received friend request is not cancelled!");
                                        }
                                    });


                            }}> <i className="fa fa-close"/> Cancel</Link>
                        </Fragment>}

                        {(user_relation === 'NoRelation' && parseInt(userId) === parseInt(user.user_id)) &&
                        <Link to={`/agent-profile/${user.user_id}`} className={'btn text-primary'}> <i
                            className="fa fa-user"/> Your Profile</Link>}

                        {user_relation === 'FRIEND' && <Fragment>
                            <Link to={`/private-chat/${userId}`} className={'btn text-success'}><i
                                className="fa fa-comments-o"/> Message</Link>
                            <Link to="/" className={'btn text-danger'} onClick={(e) => {
                                e.preventDefault();
                                swal({
                                    title: "Are you sure?",
                                    text: "Remove friend!",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then((willDelete) => {

                                        if (willDelete) {
                                            const request = {
                                                user_id: user.user_id,
                                                related_user_id: userId
                                            };
                                            actionUnFriend(request,page);
                                            swal("Poof! Your friend has been removed!", {
                                                icon: "success",
                                            });

                                        } else {
                                            swal("Your friendship is Safe!");
                                        }
                                    });

                            }}><i className="fa fa-user-times"/> Remove</Link>
                        </Fragment>}

                        {(user_relation === 'REQUEST' && parseInt(user_relation_object.user_id) === parseInt(user.user_id)) &&
                        <Link to="/" className={'btn text-danger'} onClick={(e) => {
                            e.preventDefault();

                            swal({
                                title: "Are you sure?",
                                text: "Cancel send friend request!",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                            })
                                .then((willDelete) => {

                                    if (willDelete) {
                                        const request = {
                                            user_id: user.user_id,
                                            related_user_id: userId,
                                            itemId: userId
                                        };
                                        isAuthenticated ? actionRejectRequest(request) : history.push('/register');
                                        swal("Poof! The friend request you sent has been canceled!", {
                                            icon: "success",
                                        });

                                    } else {
                                        swal("Your friend request is Safe!");
                                    }
                                });



                            // TODO: If client wants cancel friend request then code here

                        }}><i className="fa fa-close"/> Cancel</Link>}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        agent: state.agent,
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated,
    };
};
export default connect(mapStateToProps, {
    actionSendFriendRequest,
    actionUnFriend,
    actionAcceptFriendRequest,
    actionRejectRequest
})(AgentCard);