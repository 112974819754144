import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({component: Component, auth, ...rest}) =>  localStorage.jwtToken ? (
    parseInt(auth.user.is_mobile_verified) === 0 ?
        <Redirect to="/mobile-verification"/> : <Route
            {...rest}
            render={props =>
                localStorage.jwtToken ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/register"/>
                )
            }
        />
) : (<Redirect to="/register"/>);

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
