import React, {Component, Fragment} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class ImageLightboxSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }

    render() {
        const { photoIndex, isOpen } = this.state;
        const {images}=this.props;
        console.log(images)
        return (
            <Fragment>
                <Lightbox reactModalStyle={{zIndex:'99999999'}}
                          mainSrc={images[0][photoIndex]}
                          nextSrc={images[0][(photoIndex + 1) % images[0].length]}
                          prevSrc={images[0][(photoIndex + images[0].length - 1) % images[0].length]}
                          onCloseRequest={() => this.props.onCloseImage(false)}
                          onMovePrevRequest={() =>
                              this.setState({
                                  photoIndex: (photoIndex + images[0].length - 1) % images[0].length,
                              })
                          }
                          onMoveNextRequest={() =>
                              this.setState({
                                  photoIndex: (photoIndex + 1) % images[0].length,
                              })
                          }
                />
            </Fragment>
        );
    }
}

export default ImageLightboxSlider;