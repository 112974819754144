import React, {Component, Fragment} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import PropertyListCard from "./property-list-card";
import Footer from "../global-components/footer";
import sectiondata from "../../data/sections";
import connect from "react-redux/es/connect/connect";
import {
    actionAddFavourite,
    actionGetCategories,
    actionGetPropertyByFilter,
    actionGetSubCategories,
    actionGetUnits, actionRemoveFavourite
} from "../../actions/propertyAction";
import classnames from "classnames";
import queryString from 'query-string'
import SuggestedLocationItem from "../common/SuggestedLocationItem";
import {actionGetSuggestedLocations} from "../../actions/authAction";
import showToast from "../../utils/showToast";
import Spinner from "../section-components/Spinner";
import DataNotFound from "../section-components/DataNotFound";
import InfiniteScroll from "react-infinite-scroll-component";
import {
    actionGetChatList, actionLiveSharePropertyUrl, actionSharePropertyUrl
} from "../../actions/chatAction";
import {actionGetAgent} from "../../actions/agentAction";
import btnLoader from "../../assets/img/button-loader.svg";
import BroadcastModelChatUser from "../common/broadcast-model-chat-user";
import ChatUser from "../common/ChatUser";
import broadcastIcon from '../../assets/img/broadcast_icon.png'
import {WS_URL} from "../../web-config";
import jwt_decode from "jwt-decode";
import PropertyListLoaderCard from "./property-list-loader-card";
import BroadcastUserListLoaderCard from "../common/broadcast-user-list-loader-card";
import {PROPERTIES} from "../../actions/types";
import ReactGA from "react-ga";

// import Slider from 'react-input-slider';

class PropertyList extends Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
        this.state = {
            toggler: false,
            price: 100000,
            category_id: '',
            sub_category_id: '',
            area_unit_id: '',
            location: '',
            query: '',
            transaction_type: '',
            status: '',
            furnished_status: '',
            bedrooms: '',
            bathrooms: '',
            is_featured: '',
            recently_add: '',
            min_area: '',
            max_area: '',
            min_price: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
            selected_min_price: '',
            max_price: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
            selected_max_price: '',
            mn: '',
            lat: 23.022505,
            lng: 72.5713621,
            errors: {},
            offset: 0,


            chatPerson: '',
            chatFilter: '',
            broadcastSearchFilter: '',
            broadcast_members: [],
            broadcast_members_index: [],
            propertyId: '',
            isWishlist: false,


        };

    }

    ws = new WebSocket(WS_URL);

    componentDidMount() {
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Property Listing | Global Agent Network";


        this.ws.onopen = () => {
            // on connecting, do nothing but log it to the console
            // this.setState({isOnline: true});
            if (localStorage.jwtToken) {
                const decoded = jwt_decode(localStorage.jwtToken);
                this.ws.send(JSON.stringify({'action': 'setName', 'name': parseInt(decoded.uid)}));
            }

        };

        this.ws.onmessage = evt => {
            // on receiving a message, add it to the list of messages
            const message = JSON.parse(evt.data);
            console.log('Response:' + message.type);
            if (message.type === 'receiver_message_received') {
                this.props.actionAppendMessage(message.data, this.scrollToBottom);
            } else if (message.type === 'new_message_received') {
                this.props.actionAppendMessage(message.data, this.scrollToBottom);
            }
        };

        this.ws.onclose = () => {
            console.log('disconnected');
            // this.setState({isOnline: false});
            // automatically try to reconnect on connection loss
            /*this.setState({
                ws: new WebSocket(URL),
            })*/
        };


        window.addEventListener('scroll', () => {

            let scrollHeight = document.documentElement.scrollHeight;
                let scrollTop = document.documentElement.scrollTop;
            let clientHeight = document.documentElement.clientHeight;
// this.setState({location: urlParams.location ? urlParams.location : 'Ahemdabad'});
            if ((scrollTop + clientHeight) > (scrollHeight - 300) &&this.props.propertyData.length && this.props.propertyData && !this.props.getPropertyLoader && parseInt(this.props.propertyData.length) !== parseInt(this.props.propertyCount)) {
                console.log("offset", this.state.offset);
                let urlParams = queryString.parse(this.props.location.search);
                this.setState({
                    offset: this.state.offset + 1,
                    location: urlParams.location ? urlParams.location : this.state.location,
                    query: urlParams.q ? urlParams.q : this.state.query


                }, () => {


                    const {category_id, sub_category_id, area_unit_id, location, query, transaction_type, status, furnished_status, bedrooms, bathrooms, is_featured, recently_add, min_area, max_area, min_price, selected_min_price, max_price, selected_max_price, mn, lat, lng} = this.state;
                    const FILTER_URL = `?location=${location !== 'Current Location' ? location : location}&q=${query}&category_id=${category_id}&subCategory_id=${sub_category_id}&sMax=${max_area}&sMin=${min_area}&maxPrice=${selected_max_price ? (selected_max_price * 100000) : ''}&minPrice=${selected_min_price ? (selected_min_price * 100000) : ''}&bedRooms=${bedrooms}&bathRooms=${bathrooms}&is_featured=${is_featured}&furnishedStatus=${furnished_status}&transactionType=${transaction_type}&status=${status}&lat=${lat}&lng=${lng}&`;
                    this.props.actionGetPropertyByFilter(`${FILTER_URL}loggedInUserId=${this.props.user.user_id ? this.props.user.user_id : null}&`, `offset=${this.state.offset}`);

                })
            }
        });
        console.log(this.props.user.user_id);

        setTimeout(() => {
            this.props.actionGetChatList(this.props.user.user_id);
        }, 1000);


        this.props.actionGetCategories();
        this.props.actionGetSubCategories(1);
        this.props.actionGetUnits();
        let urlParams = queryString.parse(this.props.location.search);
        console.log(urlParams,'urlParams');
        console.log(queryString.parse(this.props.location.search),'this.props.location');
        this.setState({
            sub_category_id:urlParams.subCategory_id?urlParams.subCategory_id:'',
            location: urlParams.location ? urlParams.location : '',
            query: urlParams.q ? urlParams.q : this.state.query
        }, () => {

            const {category_id, sub_category_id, area_unit_id, location, query, transaction_type, status, furnished_status, bedrooms, bathrooms, is_featured, recently_add, min_area, max_area, min_price, selected_min_price, max_price, selected_max_price, mn, lat, lng} = this.state;
            const FILTER_URL = `?location=${location !== 'Current Location' ? location : ''  }&q=${query}&category_id=${category_id}&subCategory_id=${sub_category_id}&sMax=${max_area}&sMin=${min_area}&maxPrice=${selected_max_price ? (selected_max_price * 100000) : ''}&minPrice=${selected_min_price ? (selected_min_price * 100000) : ''}&bedRooms=${bedrooms}&bathRooms=${bathrooms}&is_featured=${is_featured}&furnishedStatus=${furnished_status}&transactionType=${transaction_type}&status=${status}&lat=${lat}&lng=${lng}&`;

            if (localStorage.jwtToken) {
                const decoded = jwt_decode(localStorage.jwtToken);
                this.props.actionGetPropertyByFilter(`${FILTER_URL}loggedInUserId=${parseInt(decoded.uid)}&`, `offset=${this.state.offset}`)
            } else {
                this.props.actionGetPropertyByFilter(`${FILTER_URL}loggedInUserId=${null}&`, `offset=${this.state.offset}`)
            }

        });


    }

    componentDidUpdate(prevProps, prevState) {
        (prevProps.categories !== this.props.categories && this.props.categories.length < 1) && this.props.actionGetCategories();
        (prevProps.categories !== this.props.categories && this.props.categories.length < 1) && this.props.actionGetSubCategories(1);
        (prevProps.units !== this.props.units && this.props.units.length < 1) && this.props.actionGetUnits();

        if (prevProps.user.user_id !== this.props.user.user_id) {
            this.props.actionGetChatList(this.props.user.user_id);
        }
        prevProps.chatAgent !== this.props.chatAgent && this.setState({chatPerson: this.props.chatAgent})


    }

    onChangeCategory = e => this.setState({[e.target.name]: e.target.value}, () => this.props.actionGetSubCategories(this.state.category_id));

    onChangeSubCategory = e => this.setState({[e.target.name]: e.target.value});

    onChangeArea = e => this.setState({[e.target.name]: e.target.value}, () => {
        //this.props.actionGetPropertyByFilter(`squareFeetUnit=${this.state.area_unit_id}&sMin=${this.state.min_area}&sMax=${this.state.max_area}`);
    });

    onChangeFurnishedStatus = e => this.setState({[e.target.name]: e.target.value}, () => {
        //this.props.actionGetPropertyByFilter(`furnishedStatus=${this.state.furnished_status}`);
    });

    onChangeTransactionType = e => this.setState({[e.target.name]: e.target.value}, () => {
        //this.props.actionGetPropertyByFilter(`transactionType=${this.state.transaction_type}`);
    });

    onChangeStatus = e => this.setState({[e.target.name]: e.target.value}, () => {
        //this.props.actionGetPropertyByFilter(`status=${this.state.status}`);
    });

    onChangeIsFeatured = e => this.setState({[e.target.name]: e.target.value}, () => {
        //this.props.actionGetPropertyByFilter(`is_featured=${this.state.is_featured}`);
    });

    onChangeBedrooms = e => this.setState({[e.target.name]: e.target.value}, () => {
        // this.props.actionGetPropertyByFilter(`bedRooms=${this.state.bedroom}`);
    });

    onChangeBathrooms = e => this.setState({[e.target.name]: e.target.value}, () => {
        //this.props.actionGetPropertyByFilter(`bathRooms=${this.state.bathrooms}`);
    });

    onChange = e => this.setState({[e.target.name]: e.target.value});
    onChangeSearch = e => this.setState({[e.target.name]: e.target.value}, () => {
        if (this.state.query.length < 1) {
            this.setState({offset: 0});
            this.props.actionGetPropertyByFilter(this.props.location.search, `?offset=${this.state.offset}`);
        }
    });

    onChangeBudget = e => this.setState({[e.target.name]: e.target.value}, () => {

        const min = this.state.selected_min_price * 100000;
        const max = this.state.selected_max_price * 100000;
        //this.props.actionGetPropertyByFilter(`minPrice=${min}&maxPrice=${max}`);

    });

    onLocationInput = (e) => this.setState({[e.target.name]: e.target.value}, () => setTimeout(() => this.props.actionGetSuggestedLocations(this.state.location), 500));

    checkbox = () => {
        var checked = false;
        if (document.querySelector('#opt1:checked')) {
            checked = true;

        }
        if (checked !== false) {
            document.getElementById('advf').style.display = 'block';
        }
        else {
            document.getElementById('advf').style.display = 'none';
        }
    };

    handleSearchSubmit = (e) => {
        e.preventDefault();

        //let FILTER_URL = `/property-listing?location=Ahmedabad`;
        //let urlParams = queryString.parse(this.props.location.search);
        // console.log({urlParams});
        /*Object.keys(urlParams).map((keyName, i) => {
             console.log({keyName});
             console.log({keyValue: urlParams[keyName]});
            if (urlParams[keyName]) {
                FILTER_URL += "&" + keyName + "=" + urlParams[keyName];
            }
        });*/
        //console.log(FILTER_URL);
        //this.props.history.push(FILTER_URL);

        const {category_id, sub_category_id, area_unit_id, location, query, transaction_type, status, furnished_status, bedrooms, bathrooms, is_featured, recently_add, min_area, max_area, min_price, selected_min_price, max_price, selected_max_price, mn, lat, lng} = this.state;
        const FILTER_URL = `?location=${location !== 'Current Location' ? location : ''  }&q=${query}&category_id=${category_id}&subCategory_id=${sub_category_id}&sMax=${max_area}&sMin=${min_area}&maxPrice=${(selected_max_price * 100000)}&minPrice=${(selected_min_price * 100000)}&bedRooms=${bedrooms}&bathRooms=${bathrooms}&is_featured=${is_featured}&furnishedStatus=${furnished_status}&transactionType=${transaction_type}&status=${status}&lat=${lat}&lng=${lng}`;
        this.setState({offset: 0}, () => this.props.actionGetPropertyByFilter(FILTER_URL, `offset=${this.state.offset}`));


    };

    removeChar = () => {
        var a = document.getElementById("min_area").value;
        a = a.replace('', '');
        // document.getElementById.innerHTML = a;
    };

    handleSearchResultArea = (value) => setTimeout(() => this.setState({searchInputFocused: value}), 250);

    handleSelectCurrentLocation = location => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                this.setState({lat, lng, location}, () => this.handleSearchResultArea(false));
            });
        } else showToast('Geolocation is not supported by this browser.', 'error');
    };

    handleSelectLocation = (location, lat, lng) => this.setState({
        location,
        lat,
        lng
    }, () => this.handleSearchResultArea(false));


    //fetchMoreData = () => this.setState({offset: this.state.offset + 1}, () => this.props.actionGetPropertyByFilter(this.props.location.search, `?offset=${this.state.offset}`));
    fetchMoreData = () => {
        const {category_id, sub_category_id, area_unit_id, location, query, transaction_type, status, furnished_status, bedrooms, bathrooms, is_featured, recently_add, min_area, max_area, min_price, selected_min_price, max_price, selected_max_price, mn, lat, lng} = this.state;
        const FILTER_URL = `?location=${location !== 'Current Location' ? location : ''  }&q=${query}&category_id=${category_id}&subCategory_id=${sub_category_id}&sMax=${max_area}&sMin=${min_area}&maxPrice=${selected_max_price ? (selected_max_price * 100000) : ''}&minPrice=${selected_min_price ? (selected_min_price * 100000) : ''}&bedRooms=${bedrooms}&bathRooms=${bathrooms}&is_featured=${is_featured}&furnishedStatus=${furnished_status}&transactionType=${transaction_type}&status=${status}&lat=${lat}&lng=${lng}&`;
        this.setState({offset: this.state.offset + 1}, () => this.props.actionGetPropertyByFilter(FILTER_URL, `offset=${this.state.offset}`));
    };
    onChangeBroadcastFilter = (e) => this.setState({[e.target.name]: e.target.value});


    // handleSelectBroadcastPerson = (personId) => this.setState({broadcast_members: this.state.broadcast_members.find(m => parseInt(m) === parseInt(personId)) ? [...this.state.broadcast_members.filter(m => parseInt(m) !== parseInt(personId))] : [...this.state.broadcast_members, personId]});

    handleSelectBroadcastPerson = (personIndex) => {
        this.setState({broadcast_members_index: this.state.broadcast_members_index.find(m => parseInt(m) === parseInt(personIndex)) ? [...this.state.broadcast_members_index.filter(m => parseInt(personIndex) !== parseInt(m))] : [...this.state.broadcast_members_index, personIndex]});

    };


    // handleSelectBroadcastPerson = (personIndex) => this.setState({broadcast_members_index: this.state.broadcast_members_index.find(m => parseInt(m) === parseInt(personIndex)) ? [...this.state.broadcast_members.filter(m => parseInt(m) !== parseInt(personIndex))] : [...this.state.broadcast_members, personIndex]});

    // handleSelectBroadcastPerson = (personIndex) => {
    //     // this.setState({broadcast_members_index: [...this.state.broadcast_members_index,personIndex]});
    //     this.setState({broadcast_members_index: this.state.broadcast_members_index.find(m => parseInt(m) === parseInt(personIndex)) ? [...this.state.broadcast_members_index.filter(m => parseInt(m) !== parseInt(personIndex))] : [...this.state.broadcast_members_index, personIndex]})
    //
    // };

    // handleSelectBroadcastPerson = (personIndex) => this.setState({broadcast_members_index: this.state.broadcast_members_index.find(m => parseInt(m) === parseInt(personIndex)) && [...this.state.broadcast_members, personIndex]});

    onShareProperty = (e) => {
        e.preventDefault();
        // this.setState({broadcast_members:this.state.broadcast_members_index.map(val=>val-1)});

        const selectedUsers = this.state.broadcast_members_index && this.state.broadcast_members_index.map(userIndex => this.props.chatPersonList.find((person, i) => parseInt(userIndex - 1) === parseInt(i)));
        console.log(selectedUsers);
        //
        //
        // var drop1 = [];
        // myData.map((main, key) => {
        //     var obj = {};
        //     obj['value'] = main.status_order;
        //     obj['label'] = main.status_name;
        //     drop1.push(obj);
        // });
        //
        //
        /*var drop1 = [];
        selectedUsers.map((main, key) => {
            var obj = {};
            obj['user_id'] = main.user_id;
            obj['chat_type'] = main.chat_type;
            obj['massage']='www.goole.com'
            drop1.push(obj);
        });*/
        /*console.log(drop1)*/


        //
        //
        //  const data =selectedUsers.map((val ,i)=>{user_id:val,user_id,chat_type:val.chat_type});
        // console.log(data)

        const user_id = selectedUsers.map(val => val.user_id);
        const chat_type = selectedUsers.map(val => val.chat_type);


        this.setState({broadcast_members: selectedUsers.map(val => [val.user_id])});
        this.props.actionSharePropertyUrl(`https://globalindiarealestate.com/property-detail/${this.state.propertyId}`);

        setTimeout(() => {
            if (localStorage.jwtToken) {
                const decoded = jwt_decode(localStorage.jwtToken);
                const request = {
                    sender_id: parseInt(decoded.uid),
                    payload: selectedUsers.map(user => ({
                        user_id: user.user_id,
                        type: user.chat_type,
                        message: this.props.sharePropertyUrl,
                    }))
                };
                this.props.actionLiveSharePropertyUrl(request);
            }

        }, 600);


        this.setState({broadcast_members_index:[],
            broadcast_members:''})


    };

    handleSelectPropertyCard = (propertyId) => {
        console.log(propertyId);
        this.setState({propertyId})
    };

    handleFavouriteSelect = (property_id, isWishlist) => {
        const user_id = this.props.user && this.props.user.user_id;
        if (this.props.isAuthenticated) {

            if (isWishlist) {
                this.props.actionRemoveFavourite(user_id, property_id);
                this.setState({isWishlist: false})

            } else {
                this.props.actionAddFavourite(property_id, user_id);
                this.setState({isWishlist: true})
            }

        }
        else {
            window.location.href = '/register';
        }


    };


    render() {

        console.log(this.props.user.user_id);
        const {categories, subCategories, units, propertyData, getPropertyLoader, chatPersonList} = this.props;

        const mx = this.state.min_price.filter((val) => val > this.state.selected_min_price);
        const mn = this.state.max_price.filter((val) => val < this.state.selected_max_price);


        const {errors} = this.state;
        let publicUrl = process.env.PUBLIC_URL + '/';
        let imagealt = 'image';
        let data = sectiondata.searchgrid;
        const {suggestedLocations, propertyCount, createBroadcastLoader} = this.props;

        const {chatPerson} = this.state;

        let filteredBroadcastPersonList = !this.state.broadcastSearchFilter ? chatPersonList : chatPersonList.filter(a => {
            return (a.first_name && a.first_name.toLowerCase().indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1) || (a.last_name && a.last_name.toLowerCase().indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1) || (a.email && a.email.toLowerCase().indexOf(this.state.broadcastSearchFilter.toLowerCase()) > -1);
        });

        /*let filteredBroadcastPersonList = chatPersonList;*/

        function closeNav() {
            document.getElementById("mySidenav").style.width = "0";

        }

        const serachlStle = {
            position: 'absolute',
            right: '35px',
            top: '14px',

        };
        const serachiStle = {
            width: '100%',
            // height:'37px',
            lineHeight: '36px',
            padding: '1px 20px',
            fontSize: '15px',
            border: '1px solid #d4d4d4'

        };
        //
        // console.log(this.state.query);
        // console.log(filteredBroadcastPersonList);
        console.log(this.state.broadcast_members);
        // console.log(this.state.chatPerson);
        // console.log(this.state.broadcast_members_index);
        return (
            <div className={'property-list-container'}>
                <Navbar scenario={PROPERTIES}/>
                <PageHeader headertitle="Property Listing" subheader="Nearest Property Listing"/>
                <div className="search-page-wrap pd-top-100 pd-bottom-70 search-grid-component">
                    <div className="search-container">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 col-sm-12 ">

                                    <div className="pb-3 advance-filter d-lg-none d-xl-none d-block">
                                        <button className="btn  btn-custom-default text-primary" type="button"
                                                data-toggle="collapse"
                                                data-target="#collapseResponsive" aria-expanded="false"
                                                aria-controls="collapseResponsive">
                                            <i className="fa fa-gear"/> Show Property Filter..
                                        </button>
                                    </div>
                                    <div className=" collapse  collapseResponsivec pd-bottom-60" id={'collapseResponsive'}>
                                    <form onSubmit={this.handleSearchSubmit}
                                          className="widget widget-sidebar-search-wrap bg-white p-3" autoComplete="off">
                                        <div className="widget-sidebar-search p-1 col-sm-12">
                                            <div className="title">Filter</div>
                                            <div className="rld-single-input left-icon-location mb-3">
                                                <div className=""><label>Enter Location</label></div>
                                                <input type="text"
                                                       className="rdl-mob"
                                                       name="location"
                                                       onChange={this.onLocationInput}
                                                       autoComplete={'off'}
                                                       value={this.state.location}
                                                       onFocus={() => this.handleSearchResultArea(true)}
                                                       onBlur={() => this.handleSearchResultArea(false)}
                                                       placeholder="Enter Location, Street, ZIP Code"/>

                                                {this.state.searchInputFocused &&
                                                <div className="suggestion-area">
                                                    <SuggestedLocationItem
                                                        key={0}
                                                        handleSelectLocation={this.handleSelectCurrentLocation}
                                                        formatted_address={''}
                                                        locationName={'Current Location'}/>
                                                    {suggestedLocations.map((location, index) =>
                                                        <SuggestedLocationItem
                                                            key={(index + 1)}
                                                            handleSelectLocation={this.handleSelectLocation}
                                                            lat={location.latitude}
                                                            lng={location.longitude}
                                                            locationName={location.name}
                                                            formatted_address={location.formatted_address}
                                                        />)}
                                                </div>}

                                            </div>

                                            <div className="widget-sidebar-item-wrap rld-single-select">
                                                <div className=""><label>Categories</label></div>
                                                <select className="select single-select p-0"
                                                        onChange={this.onChangeCategory} name={'category_id'}
                                                        value={this.state.category_id}>
                                                    <option value={''}>Please Select</option>
                                                    {categories && categories.map(category => <option
                                                        key={category.category_id}
                                                        value={category.category_id}>{category.name}</option>)}
                                                </select>
                                            </div>

                                            <div className="widget-sidebar-item-wrap rld-single-select">
                                                <div className=""><label>Sub Categories</label></div>
                                                <select className="select single-select p-0"
                                                        onChange={this.onChangeSubCategory} name={'sub_category_id'}>
                                                    <option value={''}>Select Select</option>
                                                    {subCategories && subCategories.map(subcategory => <option
                                                        key={subcategory.sub_category_id}
                                                        value={subcategory.sub_category_id}>{subcategory.name}</option>)}
                                                </select>
                                            </div>

                                            <div className="pb-3 advance-filter">
                                                <button className="btn  btn-custom-default text-primary" type="button"
                                                        data-toggle="collapse"
                                                        data-target="#collapseExample" aria-expanded="false"
                                                        aria-controls="collapseExample">
                                                    <i className="fa fa-gear"/> Advance Filter
                                                </button>
                                            </div>

                                            <div className="pb-3 advance-filters d-none">
                                                <button type="button" data-toggle="modal"
                                                        data-target="#exampleModalCenter"
                                                        className="btn  btn-custom-default text-primary"
                                                >
                                                    <i className={'fa fa-gear'}/> Advance Filter
                                                </button>
                                            </div>

                                            <div className="modal fade" id="exampleModalCenter" tabIndex="-1"
                                                 role="dialog" aria-labelledby="exampleModalCenterTitle"
                                                 aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title"
                                                                id="exampleModalLongTitle"> Advance Filter </h5>
                                                            <button type="button" className="close" data-dismiss="modal"
                                                                    aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="">
                                                                <div className="text-left"><label>Area</label></div>
                                                                <div
                                                                    className="widget-sidebar-item-wrap rld-single-select d-flex">
                                                                    <select id="area_unit_id" name="area_unit_id"
                                                                            className="select single-select col-4 p-0 mr-1"
                                                                            onChange={this.onChange}
                                                                            value={this.state.area_unit_id}
                                                                    >
                                                                        {units && units.map(unit => <option
                                                                            value={unit.name}
                                                                            key={unit.units_of_measures_id}
                                                                        >{unit.name}</option>)}
                                                                    </select>

                                                                    <input type="text" placeholder="Min" id={'min_area'}
                                                                           name={'min_area'}
                                                                           onChange={this.onChangeArea}
                                                                           value={this.state.min_area}
                                                                           className=" rld-single-input col-4 mr-1"
                                                                           style={{border: '1px solid #D4D4D4'}}/>
                                                                    <input type="text" placeholder="Max"
                                                                           name={'max_area'}
                                                                           onChange={this.onChangeArea}
                                                                           value={this.state.max_area}
                                                                           className="rld-single-input col-4"
                                                                           style={{border: '1px solid #D4D4D4'}}/>
                                                                </div>
                                                            </div>
                                                            <div className="widget-sidebar-item-wrap rld-single-select">
                                                                <div className=""><label>Status</label></div>
                                                                <select className="select single-select p-0"
                                                                        name={'status'} onChange={this.onChangeStatus}
                                                                        value={this.state.status}>
                                                                    <option value={''}>Select</option>
                                                                    <option value={'Ready to Move'}>Ready to Move
                                                                    </option>
                                                                    <option value={'Work In Progress'}>Work In Progress
                                                                    </option>

                                                                </select>
                                                            </div>
                                                            <div className="widget-sidebar-item-wrap rld-single-select">
                                                                <div className=""><label>Transaction Type</label></div>
                                                                <select className="select single-select p-0"
                                                                        name={'transaction_type'}
                                                                        onChange={this.onChangeTransactionType}
                                                                        value={this.state.transaction_type}>
                                                                    <option value={''}>Select</option>
                                                                    <option value={'New Property'}>New Property</option>
                                                                    <option value={'Resell Property'}>Resell Property
                                                                    </option>

                                                                </select>
                                                            </div>
                                                            <div className="widget-sidebar-item-wrap rld-single-select">
                                                                <div className=""><label>Furnished Status</label></div>
                                                                <select className="select single-select p-0"
                                                                        name={'furnished_status'}
                                                                        onChange={this.onChangeFurnishedStatus}
                                                                        value={this.state.furnished_status}>
                                                                    <option value={''}>Select</option>
                                                                    <option value={'Yes'}>Yes</option>
                                                                    <option value={'No'}>No</option>

                                                                </select>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 text-left"><label>Budget</label>
                                                                </div>
                                                                <div
                                                                    className="widget-sidebar-item-wrap rld-single-select col-6">
                                                                    <div className=""><label>Min</label></div>
                                                                    <select className="select single-select p-0"
                                                                            name='selected_min_price'
                                                                            id='selected_min_price'

                                                                            onChange={this.onChangeBudget}
                                                                            value={this.state.selected_min_price}
                                                                    >

                                                                        <option value={''}>Min</option>
                                                                        {


                                                                            this.state.min_price && this.state.min_price.map(data =>
                                                                                <option key={data}
                                                                                        value={data}>{data} Lacs</option>) ? mn && mn.map(data =>
                                                                                <option key={data}
                                                                                        value={data}>{data} Lacs</option>) : ''
                                                                        }

                                                                    </select>
                                                                </div>
                                                                <div
                                                                    className="widget-sidebar-item-wrap rld-single-select col-6">
                                                                    <div className=""><label>Max</label></div>
                                                                    <select className="select single-select p-0"
                                                                            name={'selected_max_price'}
                                                                            id={'selected_max_price'}
                                                                            onChange={this.onChangeBudget}
                                                                            value={this.state.selected_max_price}>
                                                                        <option value={''}>Max</option>
                                                                        {mx && mx.map(data => <option key={data}
                                                                                                      value={data}>{data} Lacs</option>)

                                                                        }

                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div
                                                                    className="widget-sidebar-item-wrap rld-single-select col-6">
                                                                    <div className=""><label>Bedrooms</label></div>
                                                                    <select className="select single-select p-0"
                                                                            name={'bedrooms'} onChange={this.onChange}
                                                                            value={this.state.bedrooms}>
                                                                        <option value={''}>Select</option>
                                                                        <option value={1}>1</option>
                                                                        <option value={2}>2</option>
                                                                        <option value={3}>3</option>
                                                                        <option value={4}>4</option>
                                                                        <option value={5}>5</option>
                                                                        <option value={'5+'}>5+</option>
                                                                    </select>
                                                                </div>
                                                                <div
                                                                    className="widget-sidebar-item-wrap rld-single-select col-6">
                                                                    <div className=""><label>Bathrooms</label></div>
                                                                    <select className="select single-select p-0"
                                                                            name={'bathrooms'}
                                                                            onChange={this.onChangeBathrooms}
                                                                            value={this.state.bathrooms}>
                                                                        <option value={''}>Select</option>
                                                                        <option value={1}>1</option>
                                                                        <option value={2}>2</option>
                                                                        <option value={3}>3</option>
                                                                        <option value={4}>4</option>
                                                                        <option value={5}>5</option>
                                                                        <option value={6}>6</option>
                                                                        <option value={'6+'}>6+</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="widget-sidebar-item-wrap rld-single-select">
                                                                <div className=""><label>Is Featured</label></div>
                                                                <select className="select single-select p-0"
                                                                        name={'is_featured'}
                                                                        onChange={this.onChangeIsFeatured}
                                                                        value={this.state.is_featured}>
                                                                    <option value={''}>Select</option>
                                                                    <option value={1}>Yes</option>
                                                                    <option value={0}>No</option>

                                                                </select>
                                                            </div>
                                                            <div className="widget-sidebar-item-wrap rld-single-select">
                                                                <div className=""><label>Recently Aded</label></div>
                                                                <select className="select single-select p-0"
                                                                        name={'recently_add'} onChange={this.onChange}
                                                                        value={this.state.recently_add}>
                                                                    <option value={''}>Select</option>
                                                                    <option value={1}>Properties 1</option>
                                                                    <option value={2}>Properties 2</option>
                                                                    <option value={3}>Properties 3</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                        {/*<div className="modal-footer">*/}
                                                        {/*<button type="button" className="btn "*/}
                                                        {/*data-dismiss="modal">Close*/}
                                                        {/*</button>*/}
                                                        {/*<button type="submit" className="btn btn-yellow"*/}
                                                        {/*data-dismiss="modal">Save*/}
                                                        {/*changes*/}
                                                        {/*</button>*/}
                                                        {/*</div>*/}
                                                        <div className="btn-wrap text-center">
                                                            <button type="submit" className="btn btn-yellow mb-2"
                                                                    data-dismiss="modal">
                                                                <i className="fa fa-filter"/> Search
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="advacefilter collapse sty" id={'collapseExample'}>

                                                <div className="">
                                                    <div className="text-left"><label>Area</label></div>
                                                    <div className="widget-sidebar-item-wrap rld-single-select d-flex">

                                                        <select id="area_unit_id" name="area_unit_id"
                                                                className="select single-select col-4 p-0 mr-1"
                                                                onChange={this.onChange}
                                                                value={this.state.area_unit_id}
                                                        >
                                                            <option value="">Select</option>
                                                            {units && units.map(unit => <option
                                                                value={unit.name}
                                                                key={unit.units_of_measures_id}
                                                            >{unit.name}</option>)}
                                                        </select>


                                                        <input type="text" placeholder="Min" name={'min_area'}
                                                               onChange={this.onChange} value={this.state.min_area}
                                                               className=" rld-single-input col-4 mr-1"
                                                               style={{border: '1px solid #D4D4D4'}}/>
                                                        <input type="text" placeholder="Max" name={'max_area'}
                                                               onChange={this.onChange} value={this.state.max_area}
                                                               className="rld-single-input col-4"
                                                               style={{border: '1px solid #D4D4D4'}}/>
                                                    </div>
                                                </div>
                                                <div className="widget-sidebar-item-wrap rld-single-select">
                                                    <div className=""><label>Status</label></div>
                                                    <select className="select single-select p-0" name={'status'}
                                                            onChange={this.onChangeStatus} value={this.state.status}>
                                                        <option value={''}>Select</option>
                                                        <option value={'Ready To Move'}>Ready To Move</option>
                                                        <option value={'Work In Progress'}>Work In Progress</option>

                                                    </select>
                                                </div>
                                                <div className="widget-sidebar-item-wrap rld-single-select">
                                                    <div className=""><label>Transaction Type</label></div>
                                                    <select className="select single-select p-0"
                                                            name={'transaction_type'} onChange={this.onChange}
                                                            value={this.state.transaction_type}>
                                                        <option value={''}>Select</option>
                                                        <option value={'New Property'}>New Property</option>
                                                        <option value={'Resell Property'}>Resell Property</option>

                                                    </select>
                                                </div>
                                                <div className="widget-sidebar-item-wrap rld-single-select">
                                                    <div className=""><label>Furnished Status</label></div>
                                                    <select className="select single-select p-0"
                                                            name={'furnished_status'}
                                                            onChange={this.onChangeFurnishedStatus}
                                                            value={this.state.furnished_status}>
                                                        <option value={''}>Select</option>
                                                        <option value={'Yes'}>Yes</option>
                                                        <option value={'No'}>No</option>

                                                    </select>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 text-left"><label>Budget</label></div>
                                                    <div
                                                        className="widget-sidebar-item-wrap rld-single-select col-6">

                                                        <select className="select single-select p-0"
                                                                name='selected_min_price' id='selected_min_price'

                                                                onChange={this.onChangeBudget}
                                                                value={this.state.selected_min_price}
                                                        >

                                                            <option value={''}>Min</option>
                                                            {

                                                                this.state.min_price && this.state.min_price.map(data =>
                                                                    <option key={data}
                                                                            value={data}>{data} Lacs</option>) ? mn && mn.map(data =>
                                                                    <option key={data}
                                                                            value={data}>{data} Lacs</option>) : ''
                                                            }

                                                        </select>
                                                    </div>
                                                    <div
                                                        className="widget-sidebar-item-wrap rld-single-select col-6">

                                                        <select className="select single-select p-0"
                                                                name={'selected_max_price'} id={'selected_max_price'}
                                                                onChange={this.onChangeBudget}
                                                                value={this.state.selected_max_price}>
                                                            <option value={''}>Max</option>
                                                            {mx && mx.map(data => <option key={data}
                                                                                          value={data}>{data} Lacs</option>)

                                                            }


                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div
                                                        className="widget-sidebar-item-wrap rld-single-select col-6 pr-1">
                                                        <div className=""><label>Bedrooms</label></div>
                                                        <select className="select single-select p-0" name={'bedrooms'}
                                                                onChange={this.onChangeBedrooms}
                                                                value={this.state.bedrooms}>
                                                            <option value={''}>Select</option>
                                                            <option value={1}>1</option>
                                                            <option value={2}>2</option>
                                                            <option value={3}>3</option>
                                                            <option value={4}>4</option>
                                                            <option value={5}>5</option>
                                                            <option value={'5+'}>5+</option>
                                                        </select>
                                                    </div>
                                                    <div
                                                        className="widget-sidebar-item-wrap rld-single-select col-6 pl-1">
                                                        <div className=""><label>Bathrooms</label></div>
                                                        <select className="select single-select p-0" name={'bathrooms'}
                                                                onChange={this.onChange} value={this.state.bathrooms}>
                                                            <option value={''}>Select</option>
                                                            <option value={1}>1</option>
                                                            <option value={2}>2</option>
                                                            <option value={3}>3</option>
                                                            <option value={4}>4</option>
                                                            <option value={5}>5</option>
                                                            <option value={6}>6</option>
                                                            <option value={'6+'}>6+</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="widget-sidebar-item-wrap rld-single-select">
                                                    <div className=""><label>Is Featured</label></div>
                                                    <select className="select single-select p-0" name={'is_featured'}
                                                            onChange={this.onChange} value={this.state.is_featured}>
                                                        <option value={''}>Select</option>
                                                        <option value={1}>Yes</option>
                                                        <option value={0}>No</option>

                                                    </select>
                                                </div>
                                                {/*<div className="widget-sidebar-item-wrap rld-single-select">
                                                    <div className=""><label>Recently Aded</label></div>
                                                    <select className="select single-select p-0" name={'recently_add'}
                                                            onChange={this.onChange} value={this.state.recently_add}>
                                                        <option value={''}>Select</option>
                                                        <option value={1}>Properties 1</option>
                                                        <option value={0}>Properties 2</option>
                                                    </select>
                                                </div>*/}


                                            </div>
                                        </div>
                                        <div className="btn-wrap text-center">
                                            <button type="submit" className="btn btn-yellow">
                                                <i className="fa fa-filter"/> Search
                                            </button>
                                        </div>

                                    </form>
                                    </div>
                                </div>

                                <div className="col-xl-9 col-lg-8 bg-white p-3 border-radius-10">
                                    <div className="row mb-3">
                                        <div className="col-md-9 col-sm-8">
                                            <form autoComplete="off"
                                                  onSubmit={this.handleSearchSubmit}>
                                                <div
                                                    className="widget-sidebar-item-wrap rld-single-input left-icon">
                                                    <input type="text"
                                                           name={'query'}
                                                           placeholder="Search by Agent Name, Title Name"
                                                           id="rdl-mobb"
                                                           value={this.state.query}
                                                           onChange={this.onChangeSearch}
                                                           className={classnames('form-control br-tl-3 br-bl-3 ', {
                                                               'empty-input-error': errors.query
                                                           })}
                                                    />

                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-md-3 col-sm-4 text-right">
                                            <h6 className="filter-title mt-3 mb-lg-0">{propertyCount} Properties</h6>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center">


                                        {(propertyData && propertyData.length > 0) && propertyData.map((item, i) =>
                                            <PropertyListCard

                                                imagealt={item.title}
                                                image={item.image}
                                                agentId={item.agent_id}
                                                auth={this.props.isAuthenticated}
                                                sub_category_name={item.sub_category_name}
                                                logo={item.agent_profile_pic}
                                                agentName={`${item.agent_first_name} ${item.agent_last_name}`}
                                                title={item.title}
                                                carpet_area={item.carpet_area}
                                                super_area={item.super_area}
                                                address={item.address}
                                                url={`/property-detail/${item.property_id}/${item.title?`${item.title.replace(/\\|\//g, '-')}-`:''}${item.address.replace(/\s+/g, '-')}`}
                                                land_area={item.land_area}
                                                newerprice={item.price}
                                                beds={item.bedroom}
                                                bath={item.bathrooms}
                                                wash={item.washroom}
                                                area={item.super_area ? item.super_area : item.carpet_area}
                                                area_unit_name={item.area_unit_name ? item.area_unit_name : item.area_unit_name}
                                                key={i}
                                                index={i}
                                                currency={item.currency}
                                                img={publicUrl + item.features}
                                                propertyId={item.property_id && item.property_id}
                                                handleSelectPropertyCard={this.handleSelectPropertyCard}
                                                handleFavouriteSelect={this.handleFavouriteSelect}
                                                isWishlist={item.isFavorite}
                                                userAuth={localStorage.jwtToken ? jwt_decode(localStorage.jwtToken).uid : null}

                                            />)}
                                        {/*{getPropertyLoader && <div className="row">*/}
                                        {/*<div className="text-center col-md-12"><Spinner width={150}/></div>*/}
                                        {/*</div>}*/}
                                        {getPropertyLoader && <div className="row">
                                            <PropertyListLoaderCard/>
                                            <PropertyListLoaderCard/>
                                            <PropertyListLoaderCard/>
                                            <PropertyListLoaderCard/>
                                            <PropertyListLoaderCard/>
                                            <PropertyListLoaderCard/>
                                        </div>}
                                        {(!getPropertyLoader && propertyData.length < 1) &&
                                        <div className="text-center col-md-12"><DataNotFound
                                            message={`Sorry! No any Property found.`}/></div>}


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>


                <Fragment>
                    {/* Create Broadcast Modal */}

                    <div className="modal fade share-property-modal" id="createBroadcastModal" tabIndex={-1}
                         role="dialog"
                         aria-labelledby="createBroadcastModalLabel" aria-hidden="true" style={{marginTop: "2em"}}>
                        <div className="modal-dialog modal-dialog-centered" role="document">

                            <div className="modal-content" style={{zIndex: '99999999999'}}>

                                <div className="modal-header ">
                                    <h5 className="modal-title w-100 text-center" id="createBroadcastModalLabel ">Share
                                        this property..</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" id={'share-property-close-button'}>
                                        <span aria-hidden="true h3">×</span>
                                    </button>
                                </div>
                                <div className="modal-body pt-0">
                                    <Fragment>
                                        <div id="sidepanel" className="p-2">

                                            <div id="search" className="mb-2">
                                                <label htmlFor="" style={serachlStle}><i className="fa fa-search"
                                                                                         aria-hidden="true"/></label>
                                                <input type="text" style={serachiStle} name="broadcastSearchFilter"
                                                       onChange={this.onChangeBroadcastFilter}
                                                       value={this.state.broadcastSearchFilter}
                                                       placeholder="Search Friend"/>
                                            </div>


                                            {this.state.broadcast_members_index.length > 0 &&
                                            <div
                                                className="d-flex align-items-center justify-content-between pl-2 pr-2">
                                                {this.state.broadcast_members_index.length > 0 &&
                                                <div>{this.state.broadcast_members_index.length} Person
                                                    Selected.</div>}
                                                {createBroadcastLoader ?
                                                    <div className="broadcast-next-btn"><img src={btnLoader}
                                                                                             alt="contact-loader"
                                                                                             width={15}/>
                                                    </div>
                                                    :
                                                    <div className="btn btn-yellow h4" id="clg" data-dismiss="modal"
                                                         onClick={this.onShareProperty}><i
                                                        className="fa fa-share-alt mr-2"/>
                                                        Share
                                                    </div>
                                                    //
                                                    // <button className="btn btn-yellow" onClick={this.handlePropertyImgChange}>Save
                                                    // changes
                                                    // </button>
                                                }
                                            </div>}

                                            <hr/>
                                            <div id="contacts" className="broadcast-model-contact">
                                                <ul>


                                                    {(!filteredBroadcastPersonList.length) &&
                                                    <div className="text-center">
                                                        <DataNotFound
                                                            message={`Sorry! No any Data found.`}/>
                                                    </div>
                                                    }
                                                    {/*{(!filteredBroadcastPersonList.length) &&*/}
                                                    {/*<div className="">*/}
                                                    {/*<BroadcastUserListLoaderCard/>*/}
                                                    {/*<BroadcastUserListLoaderCard/>*/}
                                                    {/*<BroadcastUserListLoaderCard/>*/}
                                                    {/*<BroadcastUserListLoaderCard/>*/}
                                                    {/*<BroadcastUserListLoaderCard/>*/}

                                                    {/*</div>*/}
                                                    {/*}*/}
                                                    {(filteredBroadcastPersonList && filteredBroadcastPersonList.length > 0) && filteredBroadcastPersonList.map((person, index) =>
                                                        <BroadcastModelChatUser
                                                            key={index}
                                                            handleSelectChatPerson={this.handleSelectBroadcastPerson}
                                                            name={`${person.first_name ? person.first_name : ""} ${person.last_name ? person.last_name : ""}`}

                                                            profile_pic={person.chat_type === "Agent" ? person.profile_pic : broadcastIcon}
                                                            lastMessage={person.last_message}
                                                            chatPerson={chatPerson}
                                                            isActive={this.state.broadcast_members_index.find(m => parseInt(index + 1) === parseInt(m))}
                                                            user_id={parseInt(index + 1)}/>)}


                                                </ul>
                                            </div>
                                        </div>

                                    </Fragment>
                                </div>
                                <div className="modal-footer">

                                </div>

                            </div>
                        </div>
                    </div>
                </Fragment>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

        categories: state.property.categories,
        subCategories: state.property.subCategories,
        getPropertyLoader: state.property.getPropertyLoader,
        units: state.property.units,
        propertyData: state.property.propertyData,
        propertyCount: state.property.propertyCount,
        suggestedLocations: state.auth.suggestedLocations,
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        chatPersonList: state.chat.chatPersonList,
        createBroadcastLoader: state.chat.createBroadcastLoader,
        sharePropertyUrl: state.chat.sharePropertyUrl,
    };
};

export default connect(mapStateToProps, {
    actionGetCategories,
    actionGetSubCategories,
    actionGetUnits,
    actionGetPropertyByFilter,
    actionGetSuggestedLocations,
    actionGetAgent,
    actionGetChatList,
    actionAddFavourite,
    actionRemoveFavourite,
    actionSharePropertyUrl,
    actionLiveSharePropertyUrl

})
(PropertyList);