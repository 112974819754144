import React, {Component} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import AgentProfileDropdownMenu from "../global-components/Agent-Profile/agent-profile-dropdown-menu";
import Chat from "../section-components/chat";
import Footer from "../global-components/footer";
import {CHAT} from "../../actions/types";
import ReactGA from "react-ga";

class Messages extends Component {
    constructor(props) {
        super(props)
        window.scrollTo(0, 0);
        if (!localStorage.jwtToken) {
            window.location.href = '/register';
        }
    }
    componentDidMount(){
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <div>
                <Navbar scenario={CHAT}/>
                <PageHeader headertitle="Chat"/>
                <Chat match={this.props.match} showSidebar={true}/>
                <Footer/>
            </div>
        );
    }
}

export default Messages;