import React, {useEffect} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import Footer from "../global-components/footer";
import ForgotPasswordCard from "./forgot-password";
import ReactGA from "react-ga";

const ForgotPassword = () => {
    useEffect(()=>{
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "Forgot Password | Global Agent Network";


    },[]);
    return (
        <div>
            <Navbar/>
            <PageHeader headertitle={"Forgot password"}/>
            <ForgotPasswordCard />
            <Footer/>
        </div>
    );
};

export default ForgotPassword;