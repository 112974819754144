import React, {Component, Fragment} from 'react';
import Footer from '../global-components/footer';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import connect from "react-redux/es/connect/connect";
import {actionGetBlogID} from "../../actions/agentAction";
import ReactGA from "react-ga";

class BlogDetail extends Component {

    componentDidMount() {

        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);

        window.scrollTo(0, 0);
        document.title = "Blog | Global Agent Network";
        this.props.match.params.blogId && this.props.actionGetBlogID(this.props.match.params.blogId);
    }
    render() {
        console.log(this.props.match.params.blogId)
        console.log(this.props.singleBlogData,'single')
        return (
            <Fragment>
                <Navbar/>
                <PageHeader headertitle="Blog-Detail"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="blog-title">
                                <h2 className="iq-tw-6 mt-5">
                                    {this.props.singleBlogData.title && this.props.singleBlogData.title}
                                </h2>
                                <br/>
                            </div>
                            <div className="iq-blog-box">
                                <div className="iq-blog-image clearfix">
                                    <img
                                        className="img-responsive center-block"
                                        src= {this.props.singleBlogData.image && this.props.singleBlogData.image}
                                        alt="#"
                                        height="400px"
                                    />
                                </div>
                                <div className="date text-left">
                                    <i className='fa fa-calendar'/> Date:- {this.props.singleBlogData.date && this.props.singleBlogData.date}
                                </div>
                                <br/>
                                <div className="iq-blog-detail">
                                    <div className="blog-content">
                                        <p>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                    this.props.singleBlogData.description_short && this.props.singleBlogData.description_short
                                                }}
                                            />
                                        </p>
                                    </div>
                                    <hr/>
                                    <div className="blog-content">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        this.props.singleBlogData.description && this.props.singleBlogData.description
                                                }}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        singleBlogData: state.agent.singleBlogData,
    };
};
export default connect(mapStateToProps, {actionGetBlogID})(BlogDetail);
