import React, {useEffect} from 'react';
import Navbar from '../global-components/navbar';
import PageHeader from '../global-components/page-header';
import RegistrationSection from '../section-components/registration';
import Footer from '../global-components/footer';
import {useHistory} from "react-router-dom";
import {REGISTER} from "../../actions/types";
import ReactGA from "react-ga";

const Auth = () => {
    window.scrollTo(0, 0);
    useEffect(()=>{

        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);

        if (window.location.pathname === '/register') {
        window.scrollTo(0, 0);
        document.title = "Register | Global Agent Network";


    }else if (window.location.pathname === '/mobile-verification'){
            window.scrollTo(0, 0);
            document.title = "Mobile Verification | Global Agent Network";

        }else if (window.location.pathname === '/change-mobile-number'){
            window.scrollTo(0, 0);
            document.title = "Change Mobile | Global Agent Network";

        }
    },[]);
    const history = useHistory();
    let pageTitle = '';
    if (window.location.pathname === '/register') {
        pageTitle = 'Login / Register';

    } else if (window.location.pathname === '/mobile-verification') pageTitle = 'Mobile Verification';
    else if (window.location.pathname === '/change-mobile-number') pageTitle = 'Change Mobile Number';

    return <div>
        <Navbar scenario={REGISTER}/>
        <PageHeader headertitle={pageTitle}/>
        <RegistrationSection history={history}/>
        <Footer/>
    </div>
};

export default Auth

