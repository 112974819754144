import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';
import connect from "react-redux/es/connect/connect";
import {actionGetPartners} from "../../actions/authAction";
import Slider from "react-slick";
import PartnerCard from "../common/PartnerCard";
import Spinner from "./Spinner";
import PartnerLoaderCard from "../common/partner-loader-card";

class OurPartner extends Component {

    componentDidMount() {
        (this.props.partners && this.props.partners.length < 1) && this.props.actionGetPartners();

    }


    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'
         let data = sectiondata.partner;
        const {partners,partners_loader} = this.props;
        const settings = {
            loop: true,
            infinite: true,

            // autoPlayTimeout: 1000,
            dots: false,
            nav: false,
            smartSpeed: 1500,
            margin: 30,
            slidesToShow: 4,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 2000,

            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]

        };




        return <div className="client-area cli pd-top-60 bottom-100" style={{BackgroundColor: "#ebeef3"}}>
            <div className="container">
                <div className="section-title text-center">
                    <h2 className="title">{data.title}</h2>
                </div>

                {/*{partners_loader && <div className="text-center col-md-12"><Spinner width={120}/></div>}*/}

                {partners_loader && <Slider {...settings}>
                    <PartnerLoaderCard/>
                    <PartnerLoaderCard/>
                    <PartnerLoaderCard/>
                    <PartnerLoaderCard/>
                    <PartnerLoaderCard/>
                    <PartnerLoaderCard/>
                </Slider>}


                <Slider {...settings}>
                    {partners && partners.map((item, i) => <PartnerCard key={i} item={item}/>)}
                </Slider>


            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        partners: state.auth.partners,
        partners_loader: state.auth.partners_loader,
    };
};
export default connect(mapStateToProps, {
    actionGetPartners,
})(OurPartner)