import React, {Component} from 'react';
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import AgentPropertyList from "../section-components/agent-property";
import Footer from "../global-components/footer";
import {MY_PROPERTY} from "../../actions/types";
import ReactGA from "react-ga";

class MyProperty extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }
    componentDidMount() {
        ReactGA.initialize('G-2XQB9WGPED');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
        document.title = "My Properties | Global Agent Network";

    }
    render() {
        return (
            <div>
                <Navbar scenario={MY_PROPERTY}/>
                <PageHeader headertitle="My Properties"/>
                <AgentPropertyList showTabs={false} match={this.props.match}/>
                <Footer/>
            </div>
        );
    }
}

export default MyProperty;